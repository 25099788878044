import { MarketingItemSchema, MarketingPackageSchema } from '@fastre/core/src/schemas/marketingPackage'
import { UpdateSaleListingSchema } from '@fastre/core/src/schemas/saleListing'
import { Delete } from '@mui/icons-material'
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Modal,
    ModalClose,
    ModalDialog,
    Sheet,
    Stack,
    Tab,
    TabList,
    TabPanel,
    Tabs,
    Typography,
} from '@mui/joy'
import { useMarketingItemsApi, useMarketingPackagesApi } from 'apiProviders'
import ButtonSheet from 'components/buttonSheet'
import Input from 'components/input'
import { dontCloseOnBackgroundClick } from 'components/modal'
import { filter, map, pipe } from 'ramda'
import { useState } from 'react'
import { UseFormSetValue } from 'react-hook-form'

interface MarketingPackageModalProps {
    open: boolean
    close: () => void
    setValue: UseFormSetValue<UpdateSaleListingSchema>
    currentPackage?: MarketingPackageSchema
}

const MarketingPackageModal = ({ open, close, currentPackage, setValue }: MarketingPackageModalProps) => {
    const marketingPackagesApi = useMarketingPackagesApi()
    const marketingItemsApi = useMarketingItemsApi()

    const [packageName, setPackageName] = useState(currentPackage?.packageName ?? '')
    const [items, setItems] = useState<MarketingItemSchema[]>(currentPackage?.packageItems ?? [])

    return (
        <Modal
            open={open}
            onClose={dontCloseOnBackgroundClick(close)}
        >
            <ModalDialog
                sx={{
                    width: '100%',
                    minHeight: '600px',
                    maxWidth: '1000px',
                }}
            >
                <ModalClose />
                <DialogTitle>Marketing Package</DialogTitle>
                <DialogContent>
                    <Stack
                        gap={4}
                        direction="row"
                    >
                        <Box sx={{ width: '50%', maxWidth: '350px' }}>
                            <Tabs>
                                <TabList>
                                    <Tab>Package</Tab>
                                    <Tab>Items</Tab>
                                </TabList>
                                <TabPanel value={0}>
                                    <Stack direction="column">
                                        {marketingPackagesApi.maybeData.map(
                                            map(mpackage => (
                                                <ButtonSheet
                                                    key={mpackage.packageId}
                                                    onClick={() => {
                                                        setPackageName(mpackage.packageName)
                                                        setItems(mpackage.packageItems)
                                                    }}
                                                >
                                                    <Typography>{mpackage.packageName}</Typography>
                                                </ButtonSheet>
                                            )),
                                        )}
                                    </Stack>
                                </TabPanel>
                                <TabPanel value={1}>
                                    <Stack
                                        direction="column"
                                        gap={2}
                                    >
                                        {marketingItemsApi.maybeData.map(
                                            pipe(
                                                filter(
                                                    (item: MarketingItemSchema) =>
                                                        !items.some(x => x.itemId == item.itemId),
                                                ),
                                                map(item => (
                                                    <ButtonSheet
                                                        key={item.itemId!}
                                                        onClick={() => setItems(x => [...x, item])}
                                                    >
                                                        <Stack direction="row">
                                                            <Typography>{item.itemName}</Typography>
                                                            <Typography>{item.itemPrice}</Typography>
                                                        </Stack>
                                                    </ButtonSheet>
                                                )),
                                            ),
                                        )}
                                    </Stack>
                                </TabPanel>
                            </Tabs>
                        </Box>
                        <Box sx={{ width: '50%', flexGrow: 1 }}>
                            <Stack direction="column">
                                <Input
                                    label="Package Name"
                                    value={packageName}
                                    onChange={setPackageName}
                                    sx={{ mb: 2 }}
                                />
                                {items.map((item, i) => (
                                    <Sheet key={item.itemId ?? i}>
                                        <Stack
                                            direction="row"
                                            gap={2}
                                        >
                                            <Input
                                                label="Item Name"
                                                value={item.itemName}
                                                onChange={e =>
                                                    setItems(items.map((x, j) => (i == j ? { ...x, itemName: e } : x)))
                                                }
                                                formControlProps={{ sx: { flex: 1 } }}
                                            />
                                            <Input
                                                label="Item Price"
                                                value={item.itemPrice}
                                                onChange={e =>
                                                    setItems(
                                                        items.map((x, j) =>
                                                            i == j ? { ...x, itemPrice: parseFloat(e) } : x,
                                                        ),
                                                    )
                                                }
                                                type="dollar"
                                                formControlProps={{ sx: { maxWidth: '160px' } }}
                                            />
                                            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                                <IconButton
                                                    variant="outlined"
                                                    onClick={() => setItems(items.filter((_, j) => i !== j))}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </Box>
                                        </Stack>
                                    </Sheet>
                                ))}
                                <Box>
                                    <Button
                                        variant="outlined"
                                        onClick={() =>
                                            setItems(x => [
                                                ...x,
                                                { itemCategory: 'Advertising', itemName: '', itemPrice: 0 },
                                            ])
                                        }
                                        sx={{ mt: 2 }}
                                    >
                                        Add Item
                                    </Button>
                                </Box>
                            </Stack>
                        </Box>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={close}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            setValue(
                                'marketingPackage',
                                {
                                    packageName,
                                    packageItems: items,
                                },
                                { shouldDirty: true },
                            )
                            close()
                        }}
                    >
                        Save
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    )
}

export default MarketingPackageModal
