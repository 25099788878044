import { ChatGroupSchema } from '@fastre/core/src/schemas/chat'
import {
    KeyboardArrowDownRounded,
    MoreVertRounded,
    PlaylistAdd,
    ReplyRounded,
    SendRounded,
} from '@mui/icons-material'
import {
    Avatar,
    Badge,
    BadgeProps,
    Box,
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    Dropdown,
    IconButton,
    Link,
    Menu,
    MenuButton,
    MenuItem,
    Modal,
    ModalClose,
    ModalDialog,
    Sheet,
    Stack,
    Textarea,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/joy'
import { useApi } from 'api'
import { useChatApi, useChatConfigApi, useFindUserFromId, useTodoApi } from 'apiProviders'
import { useUserData } from 'auth'
import ChatInput, { renderChat, serializeChat, withMentions } from 'chat/chatInput'
import { MultipleAgentInput } from 'components/agentInput'
import { dontCloseOnBackgroundClick } from 'components/modal'
import SectionHead from 'components/sectionHead'
import { useShowSnack } from 'components/snackbar'
import { addDays, differenceInDays, format, isSameDay } from 'date-fns'
import { useOrgNavigate } from 'helperFunctions/react'
import { useOrgId } from 'helpers'
import { Maybe } from 'monet'
import { length, prop } from 'ramda'
import { Fragment, forwardRef, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { Virtuoso } from 'react-virtuoso'
import 'simplebar-react/dist/simplebar.min.css'
import { Editor, Transforms, createEditor } from 'slate'
import { withHistory } from 'slate-history'
import { withReact } from 'slate-react'
import { v4 as uuid } from 'uuid'
import ReplyChat from './replyChat'

const AddUsersModal = forwardRef(
    ({ group, close }: { group: ChatGroupSchema; close: () => void }, ref: any) => {
        const api = useApi()

        const [loading, setLoading] = useState(false)
        const [groupMembers, setGroupMembers] = useState(group.groupMembers)

        return (
            <ModalDialog ref={ref}>
                <ModalClose />
                <DialogTitle>Update Channel Members</DialogTitle>
                <DialogContent>
                    <MultipleAgentInput
                        value={groupMembers}
                        onChange={setGroupMembers}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        loading={loading}
                        onClick={async () => {
                            setLoading(true)
                            await api.post(`/chatgroups/${group.id}/updatemembers`, { groupMembers })
                            setLoading(false)
                            close()
                        }}
                    >
                        Update
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={close}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </ModalDialog>
        )
    },
)

const MyBadge = ({ emojis, ...props }: { emojis: string } & Omit<BadgeProps, 'variant' | 'anchorOrigin'>) => (
    <Badge
        {...props}
        badgeContent={emojis == '' ? 0 : emojis}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        variant="plain"
    />
)

const formatDate = (date: string) => {
    if (isSameDay(Date.now(), date)) {
        return 'Today'
    } else if (isSameDay(Date.now(), addDays(date, -1))) {
        return 'Yesterday'
    } else if (differenceInDays(Date.now(), date) < 7) {
        return format(date, 'EEE')
    } else {
        return format(date, 'do MMM')
    }
}

const DisplayedChats = ({ setReplyChat }) => {
    const api = useApi()
    const orgId = useOrgId()
    const chatGroupId = useParams<{ chatGroupId: string }>().chatGroupId!
    const theme = useTheme()
    const { user } = useUserData()
    const chatApi = useChatApi(chatGroupId)
    const virtuosoRef = useRef<any>()
    const findUserFromId = useFindUserFromId()
    const showSnack = useShowSnack()
    const todoApi = useTodoApi(false)

    const [menuChatId, setMenuChatId] = useState<string>()
    const anchorEl = menuChatId ? document.getElementById(menuChatId) : undefined
    const [scrolledToBottom, setScrolledToBottom] = useState(true)

    useEffect(() => {
        if (chatApi.lastMessage && ((chatApi.lastMessage as any).userId == user.userId || scrolledToBottom)) {
            // wait 500ms then scroll to bottom
            setTimeout(() => {
                virtuosoRef.current?.scrollToIndex({
                    index: chatApi.data?.length ?? 0,
                    behavior: 'smooth',
                })
            }, 200)
        }
    }, [chatApi.lastMessage])

    const LoadMore = forwardRef((props, ref: any) => {
        if (chatApi.lastPage && !chatApi.loading) {
            return <></>
        }

        return (
            <Box
                ref={ref}
                sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}
            >
                <Link
                    onClick={async () => {
                        const existingRows = chatApi.data?.length ?? 0
                        const newRows = await chatApi.nextPage().then(length)
                        console.log('newRows', newRows)
                        virtuosoRef.current?.scrollToIndex({
                            index: newRows - existingRows,
                        })
                    }}
                    sx={{ mr: 1 }}
                >
                    Load older
                </Link>
                {chatApi.loading && <CircularProgress size="sm" />}
            </Box>
        )
    })

    return (
        <>
            <Box
                onScroll={(e: any) => {
                    setScrolledToBottom(e.target.scrollHeight - e.target.scrollTop == e.target.clientHeight)
                }}
                sx={{
                    flex: 1,
                    flexShrink: 1,
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column', //-reverse',
                    gap: 2,
                    position: 'relative',
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        right: theme.spacing(8),
                        bottom: 0,
                        zIndex: 1,
                        //background: theme.palette.background.body,
                        visibility: scrolledToBottom ? 'hidden' : 'visible',
                        opacity: scrolledToBottom ? 0 : 1,
                        transition: 'visibility 0.3s, opacity 0.3s linear',
                    }}
                >
                    <Link
                        onClick={() =>
                            virtuosoRef.current?.scrollToIndex({
                                index: chatApi.data?.length ?? 0,
                                behavior: 'smooth',
                            })
                        }
                    >
                        View latest <KeyboardArrowDownRounded />
                    </Link>
                </Box>

                <>
                    <Menu
                        anchorEl={anchorEl}
                        open={anchorEl != undefined}
                        onClose={() => {
                            //setAnchorEl(null)
                            setMenuChatId(undefined)
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 1,
                                px: 1,
                            }}
                        >
                            {['👍', '😅', '🤣', '❤️'].map(emoji => (
                                <Button
                                    key={emoji}
                                    variant="outlined"
                                    sx={{
                                        borderRadius: '50%',
                                        p: 1,
                                    }}
                                    onClick={() => {
                                        setMenuChatId(undefined)
                                        api.post(`/chat/${menuChatId}/react`, { emoji })
                                    }}
                                >
                                    {emoji}
                                </Button>
                            ))}
                            <Tooltip title="Create todo">
                                <IconButton
                                    variant="outlined"
                                    sx={{
                                        borderRadius: '50%',
                                        p: 1,
                                        ml: 1,
                                    }}
                                    onClick={() => {
                                        setMenuChatId(undefined)

                                        console.log('menuChatId', menuChatId)

                                        console.log(
                                            'find',
                                            chatApi.data
                                                ?.find(x => x.id == menuChatId)
                                                ?.message.map(serializeChat),
                                        )

                                        api.post(`/todo/create`, {
                                            id: uuid(),
                                            createdAt: Date.now(),
                                            todoDescription:
                                                chatApi.data
                                                    ?.find(x => x.id == menuChatId)
                                                    ?.message.map(serializeChat)
                                                    .join('\n') ?? '',
                                        }).then(() => {
                                            showSnack('Todo created', 'success')
                                            todoApi.refresh()
                                        })
                                    }}
                                >
                                    <PlaylistAdd />
                                </IconButton>
                            </Tooltip>
                            {import.meta.env.DEV && (
                                <Tooltip title="Reply">
                                    <IconButton
                                        onClick={() => {
                                            const chat = chatApi.data!.find(x => x.id == menuChatId)!
                                            setReplyChat({
                                                id: menuChatId!,
                                                userId: chat.userId,
                                                userName: chat.userName,
                                                message: chat.message.map(serializeChat).join('\n') ?? '',
                                            })
                                        }}
                                    >
                                        <ReplyRounded />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Box>
                    </Menu>
                </>
                {chatApi.maybeData
                    .map(data => (
                        <Virtuoso
                            ref={virtuosoRef}
                            components={{
                                //Scroller: Scroller3,
                                Header: LoadMore as any,
                            }}
                            initialTopMostItemIndex={data.length - 1}
                            onScroll={(e: any) => {
                                setScrolledToBottom(
                                    e.target.scrollHeight - e.target.scrollTop == e.target.clientHeight,
                                )
                            }}
                            totalCount={data.length}
                            itemContent={index => {
                                const chat = data[index]
                                const myMessage = chat.userId == user.userId

                                return (
                                    <Fragment key={chat.id}>
                                        <Box sx={{ height: theme.spacing(2) }} />
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                //flexDirection: myMessage ? 'row-reverse' : 'row',
                                                flexDirection: 'row',
                                                gap: 2,
                                                mx: {
                                                    xs: 2,
                                                    sm: 4,
                                                },
                                            }}
                                        >
                                            <Avatar size="sm">
                                                {findUserFromId(chat.userId)
                                                    .bind(user => Maybe.fromUndefined(user.profileImage))
                                                    .map(profileImage => (
                                                        <img
                                                            src={profileImage}
                                                            style={{
                                                                width: '100%',
                                                                height: '100%',
                                                                objectFit: 'cover',
                                                            }}
                                                        />
                                                    ))
                                                    .orSome(
                                                        <>
                                                            {chat.userName
                                                                .split(' ')
                                                                .map(word => word[0])
                                                                .slice(0, 2)
                                                                .join('')}
                                                        </>,
                                                    )}
                                            </Avatar>
                                            <MyBadge
                                                emojis={Object.values(chat.reactions).flat().join('')}
                                                sx={{
                                                    maxWidth: '100%',
                                                    display: 'flex',
                                                    flexShrink: 1,
                                                }}
                                            >
                                                <Sheet
                                                    id={chat.id}
                                                    sx={{
                                                        borderRadius: 'sm',
                                                        py: 1,
                                                        px: 2,
                                                        backgroundColor: myMessage
                                                            ? theme.palette.primary.plainActiveBg
                                                            : theme.palette.background.level1,
                                                        maxWidth: '100%',
                                                        flexShrink: 1,
                                                        overflowWrap: 'anywhere',
                                                    }}
                                                    onContextMenu={e => {
                                                        e.preventDefault()
                                                        setMenuChatId(chat.id)
                                                        //setAnchorEl(e.currentTarget)
                                                    }}
                                                >
                                                    {chat.userId != user.userId && (
                                                        <Typography
                                                            color="primary"
                                                            sx={{ mb: 0.5 /*fontWeight: 'bold'*/ }}
                                                        >
                                                            {chat.userName}
                                                        </Typography>
                                                    )}
                                                    <Typography>
                                                        {chat.message.map(renderChat(orgId!))}
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: '0.7rem',
                                                            color: theme.palette.text.secondary,
                                                            textAlign: 'right',
                                                            mt: 0.5,
                                                        }}
                                                    >
                                                        {formatDate(chat.messageTime)}
                                                        {' - '}
                                                        {format(chat.messageTime, 'hh:mm aa')}
                                                    </Typography>
                                                </Sheet>
                                            </MyBadge>
                                        </Box>
                                    </Fragment>
                                )
                            }}
                        />
                    ))
                    .orSome(<></>)}
            </Box>
            {anchorEl && (
                <Box
                    onClick={() => setMenuChatId(undefined)}
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: 'transparent',
                    }}
                ></Box>
            )}
        </>
    )
}

const TE2 = forwardRef((props: any, ref: any) => {
    return (
        <Textarea
            slots={{
                textarea: ChatInput,
            }}
            slotProps={{
                textarea: {
                    ...props,
                    ref,
                },
            }}
            sx={{
                '& p': {
                    margin: 0,
                },
                flexGrow: 1,
            }}
        />
    )
})

const Chat = () => {
    const theme = useTheme()
    const chatGroupId = useParams<{ chatGroupId: string }>().chatGroupId!
    const { user } = useUserData()
    const api = useApi()
    const editorRef = useRef<any>()
    const findUserFromId = useFindUserFromId()
    const chatConfigApi = useChatConfigApi()
    const navigate = useOrgNavigate()

    const [editor] = useState(() => withMentions(withReact(withHistory(createEditor()))))

    const [showAddUsers, setShowAddUsers] = useState(false)
    const [group, setGroup] = useState<ChatGroupSchema | undefined>()
    const [replyChat, setReplyChat] = useState<{
        id: string
        userId: string
        userName: string
        message: string
    }>()

    useEffect(() => {
        api.get(`/chatgroups/${chatGroupId}`).then(prop('data')).then(setGroup)
        if ((chatConfigApi.data?.newMessages[chatGroupId] ?? 0) > 0) {
            api.post(`/chatgroups/${chatGroupId}/seen`)
        }
    }, [chatGroupId])

    const [loading, setLoading] = useState(false)

    const postMessage = async () => {
        setLoading(true)

        const message = editorRef.current?.getContents()

        if (message.map(serializeChat).join('') == '') {
            setLoading(false)
            return
        }

        Transforms.delete(editor, {
            at: {
                anchor: Editor.start(editor, []),
                focus: Editor.end(editor, []),
            },
        })

        const replyChatId = replyChat?.id
        setReplyChat(undefined)

        await api.post('/chat/post', {
            chatGroupId,
            message,
            replyChatId,
        })
        setLoading(false)
    }

    return (
        <>
            <Box
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
                onFocus={() => {
                    if ((chatConfigApi.data?.newMessages[chatGroupId] ?? 0) > 0) {
                        api.post(`/chatgroups/${chatGroupId}/seen`)
                    }
                }}
            >
                <Box
                    sx={{
                        mx: {
                            xs: 2,
                            sm: 4,
                        },
                    }}
                >
                    <SectionHead
                        title={
                            group == undefined
                                ? 'Loading...'
                                : group.groupType == 'dm'
                                  ? group.groupMembers
                                        .filter(id => id != user.userId)
                                        .map(findUserFromId)
                                        .map(maybeUser =>
                                            maybeUser
                                                .map(user => `${user.firstName} ${user.lastName}`)
                                                .orSome(''),
                                        )
                                        .join(', ')
                                  : `# ${group.groupName}`
                        }
                        buttons={
                            group?.groupType == 'channel' && (
                                <Dropdown>
                                    <MenuButton
                                        slots={{ root: IconButton }}
                                        loading={loading}
                                    >
                                        <MoreVertRounded />
                                    </MenuButton>
                                    <Menu>
                                        <MenuItem
                                            //disabled={user.permissions.includes('chat.manage')}
                                            onClick={async () => {
                                                await api.post(`/chatgroups/${chatGroupId}/leave`)
                                                chatConfigApi.refresh()
                                                navigate('/dashboard')
                                            }}
                                        >
                                            Leave group
                                        </MenuItem>
                                        {user.permissions.includes('chat.manage') && (
                                            <MenuItem onClick={() => setShowAddUsers(true)}>
                                                Manage members
                                            </MenuItem>
                                        )}
                                    </Menu>
                                </Dropdown>
                            )
                        }
                    />
                </Box>
                <DisplayedChats setReplyChat={setReplyChat} />
                <Stack
                    sx={{
                        mt: 4,
                        mx: {
                            xs: 2,
                            sm: 4,
                        },
                    }}
                >
                    {replyChat && (
                        <ReplyChat
                            replyChat={replyChat}
                            onClose={() => setReplyChat(undefined)}
                        />
                    )}
                    <Stack
                        direction="row"
                        gap={2}
                    >
                        <TE2
                            ref={editorRef}
                            editor={editor}
                            postMessage={postMessage}
                        />
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            <IconButton
                                variant="solid"
                                loading={loading}
                                onClick={postMessage}
                                color="primary"
                                //disabled={message.length == 0}
                            >
                                <SendRounded />
                            </IconButton>
                        </Box>
                    </Stack>
                </Stack>
            </Box>
            {group != undefined && (
                <Modal
                    open={showAddUsers}
                    onClose={dontCloseOnBackgroundClick(() => setShowAddUsers(false))}
                >
                    <AddUsersModal
                        group={group}
                        close={() => setShowAddUsers(false)}
                    />
                </Modal>
            )}
        </>
    )
}

export default Chat
