import { ListingFilesApiData } from 'apiProviders'
import FileRender from './fileRender'
import FolderRender from './folderRender'

interface Props {
    file: ListingFilesApiData
    setFile: any
    index: number
    parentDragging: boolean
    formDisabled: boolean
}

export default ({ file, setFile, index, parentDragging, formDisabled }: Props) => {
    const Render = file.isFolder ? FolderRender : FileRender

    return (
        <Render
            file={file}
            setFile={setFile}
            index={index}
            parentDragging={parentDragging}
            formDisabled={formDisabled}
        />
    )
}
