import { useUserLedgerApi } from 'apiProviders'
import LedgerMonthSelector from 'components/ledgerMonthSelector'
import Ledger from 'ledgerManager/userLedger'
import { useParams } from 'react-router'

const UserLedger = () => {
    const { userId } = useParams<{ userId: string }>()
    const userLedgerApi = useUserLedgerApi(userId!)

    return (
        <>
            <LedgerMonthSelector />
            <Ledger
                ledgerApi={userLedgerApi}
                userId={userId!}
            />
        </>
    )
}

export default UserLedger
