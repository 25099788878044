import { FormType } from '@fastre/core/src/schemas/docusign'
import { getFormName } from '@fastre/core/src/schemas/rei'
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Modal,
    ModalClose,
    ModalDialog,
    Typography,
} from '@mui/joy'
import { useApi } from 'api'
import { useListingType } from 'apiProviders'
import Input from 'components/input'
import { dontCloseOnBackgroundClick } from 'components/modal'
import { useShowSnack } from 'components/snackbar'
import { useListingContext } from 'listings/listingProvider'
import { useState } from 'react'

interface AbandonFormModalProps {
    formType: FormType | undefined
    open: boolean
    onClose: () => void
}

export const AbandonFormModal = ({ formType, open, onClose }: AbandonFormModalProps) => {
    const api = useApi()
    const listingType = useListingType()
    const { listing, setListing } = useListingContext()
    const showSnack = useShowSnack()

    const [loading, setLoading] = useState(false)
    const [voidedReason, setVoidedReason] = useState('')

    return (
        <Modal
            open={open}
            onClose={dontCloseOnBackgroundClick(() => onClose())}
        >
            <ModalDialog sx={{}}>
                <ModalClose />
                <DialogTitle>Abandon Form</DialogTitle>
                <DialogContent>
                    <Typography>Please provide a reason for abandoning {getFormName(formType!)}</Typography>
                    <Input
                        label="Reason"
                        value={voidedReason}
                        onChange={setVoidedReason}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        color="danger"
                        loading={loading}
                        disabled={voidedReason.length == 0}
                        onClick={async () => {
                            try {
                                setLoading(true)
                                await api.post(
                                    `/listing/${listingType}/${listing.listingId}/docusign/${formType}/delete`,
                                    {
                                        voidedReason,
                                    },
                                )
                                setListing({ ...listing, [formType!]: undefined })
                                showSnack('Form abandoned', 'success')
                                setVoidedReason('')
                                onClose()
                            } catch (error) {
                                showSnack('Error abandoning form', 'danger')
                                console.error(error)
                            } finally {
                                setLoading(false)
                            }
                        }}
                    >
                        Abandon
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    )
}
