import { ListingStatus } from '@fastre/core/src/schemas/listing'
import { InternalSaleListingSchema } from '@fastre/core/src/schemas/saleListing'
import { TypesenseListingSchema } from '@fastre/core/src/schemas/typesense'
import { ColorPaletteProp } from '@mui/joy'
import { useUserData } from 'auth'

export const listingStatusToColor = (status: ListingStatus): ColorPaletteProp => {
    switch (status) {
        case 'available':
            return 'success'

        case 'offline':
        case 'appraisal':
            return 'primary'

        case 'under contract':
            return 'warning'

        case 'sold':
        case 'unconditional':
            return 'danger'

        default:
            return 'neutral'
    }
}

export const useListingStatusOptions = (
    listing: InternalSaleListingSchema | TypesenseListingSchema,
): { value: ListingStatus; disabled?: boolean }[] => {
    const { user } = useUserData()

    return [
        {
            disabled:
                listing.listingStatus !== 'offline' && !user.permissions.includes('listings.fullControl'),
            value: 'offline',
        },
        {
            value: 'appraisal',
        },
        {
            value: 'not gained',
        },
        {
            disabled:
                listing.listingStatus !== 'offline' && !user.permissions.includes('listings.fullControl'),
            value: 'available',
        },
        {
            disabled: !user.permissions.includes('listings.fullControl'),
            value: 'under contract',
        },
        {
            disabled:
                !user.permissions.includes('listings.fullControl') ||
                listing.contract?.contractDate == undefined,
            value: 'unconditional',
        },
        {
            disabled:
                !user.permissions.includes('listings.fullControl') ||
                listing.contract?.contractDate == undefined,
            value: 'sold',
        },
        {
            disabled: !user.permissions.includes('listings.fullControl'),
            value: 'withdrawn',
        },
    ]
}
