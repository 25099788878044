import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import Avatar from '@mui/joy/Avatar'
import Box from '@mui/joy/Box'
import IconButton from '@mui/joy/IconButton'
import Typography from '@mui/joy/Typography'
import { logout, useUserData } from 'auth'

export default () => {
    const { user } = useUserData()

    return (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <Avatar
                variant="outlined"
                size="sm"
                src={user.profileImage}
            >
                {user.firstName[0]}
                {user.lastName[0]}
            </Avatar>
            <Box sx={{ minWidth: 0, flex: 1 }}>
                <Typography level="title-sm">{user.firstName}</Typography>
                <Typography level="body-xs">{user.email}</Typography>
            </Box>
            <IconButton
                size="sm"
                variant="plain"
                color="neutral"
                onClick={logout}
            >
                <LogoutRoundedIcon />
            </IconButton>
        </Box>
    )
}
