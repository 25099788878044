import { ArrowDropDownRounded } from '@mui/icons-material'
import { Link } from '@mui/joy'

export interface Sort {
    sort: string
    sortDirection: 'asc' | 'desc'
}

interface SortableHeaderProps {
    sort: Sort | undefined
    setSort: (sort: Sort) => void
    sortString: string
    children: React.ReactNode
}

export default function SortableHeader({ sort, setSort, sortString, children }: SortableHeaderProps) {
    return (
        <Link
            underline="none"
            color="primary"
            component="button"
            onClick={() =>
                setSort({
                    sort: sortString,
                    sortDirection:
                        sort?.sort === sortString && sort?.sortDirection === 'asc' ? 'desc' : 'asc',
                })
            }
            fontWeight="lg"
            {...(sort?.sort === sortString && {
                endDecorator: <ArrowDropDownRounded />,
                sx: {
                    '& svg': {
                        transition: '0.2s',
                        transform: sort?.sortDirection === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
                    },
                },
            })}
        >
            {children}
        </Link>
    )
}
