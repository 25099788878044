import { Button, DialogActions, DialogTitle, Modal, ModalDialog, Typography } from '@mui/joy'
import { useState } from 'react'
import { Blocker } from 'react-router'

interface UnsavedModalProps {
    blocker: Blocker
    save: (onSuccess, onFail) => Promise<void>
}

export default ({ blocker, save }: UnsavedModalProps) => {
    const [loading, setLoading] = useState(false)

    return (
        <Modal open={blocker.state == 'blocked'}>
            <ModalDialog>
                <DialogTitle>Unsaved Changes</DialogTitle>
                <Typography>
                    You have unsaved changes. Do you want to save the changes before you leave?
                </Typography>
                <DialogActions>
                    <Button
                        loading={loading}
                        onClick={async () => {
                            setLoading(true)
                            await save(
                                () => {
                                    setLoading(false)
                                    blocker.proceed!()
                                },
                                () => {
                                    blocker.reset!()
                                },
                            )
                            setLoading(false)
                        }}
                    >
                        Save
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={async () => blocker.reset!()}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="outlined"
                        color="danger"
                        onClick={() => blocker.proceed!()}
                        sx={{ mr: 'auto' }}
                    >
                        Don't Save
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    )
}
