import { toCurrency } from '@fastre/core/src/helperFunctions/string'
import { InternalAgentStatement } from '@fastre/core/src/schemas/agentStatement'
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Modal,
    ModalClose,
    ModalDialog,
    Table,
} from '@mui/joy'
import { useApi } from 'api'
import { usePayrollApi } from 'apiProviders'
import Loading from 'components/Loading'
import { dontCloseOnBackgroundClick } from 'components/modal'
import { format } from 'date-fns'
import { prop } from 'ramda'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import AgentStatementTable from './agentStatement/agentStatementTable'

const PayrollModal = ({ payrollId, onClose }: { payrollId?: string; onClose: () => void }) => {
    const { userId } = useParams<{ userId: string }>()
    const api = useApi()

    const [payrollItems, setPayrollItems] = useState<InternalAgentStatement[]>()

    useEffect(() => {
        if (payrollId) {
            api.post('/getpayroll', {
                userId,
                payrollId,
            })
                .then(prop('data'))
                .then(setPayrollItems)
        } else {
            setPayrollItems(undefined)
        }
    }, [payrollId])

    return (
        <Modal
            open={payrollId != undefined}
            onClose={dontCloseOnBackgroundClick(onClose)}
        >
            <ModalDialog sx={{ width: '100%', maxWidth: '1200px' }}>
                <ModalClose />
                <DialogTitle>Payroll Details</DialogTitle>
                <DialogContent>
                    {payrollItems ? <AgentStatementTable items={payrollItems} /> : <Loading />}
                </DialogContent>
                <DialogActions>
                    <Box>
                        <Button onClick={onClose}>Close</Button>
                    </Box>
                </DialogActions>
            </ModalDialog>
        </Modal>
    )
}

export default function PayrollTab() {
    const { userId } = useParams<{ userId: string }>()
    const payrollApi = usePayrollApi(userId!)

    const [viewPayrollId, setViewPayrollId] = useState<string>()

    return (
        <>
            <Table hoverRow>
                <thead>
                    <tr>
                        <th>Payroll Date</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {payrollApi.data?.map(payroll => (
                        <tr
                            key={payroll.id}
                            onClick={() => setViewPayrollId(payroll.id)}
                        >
                            <td>{format(payroll.paySlipDate, 'dd/MM/yy')}</td>
                            <td>{toCurrency(payroll.totalAmount)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <PayrollModal
                payrollId={viewPayrollId}
                onClose={() => setViewPayrollId(undefined)}
            />
        </>
    )
}
