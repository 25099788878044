import { differenceInDays, differenceInHours, differenceInMinutes, differenceInWeeks, format } from 'date-fns'
// @ts-ignore
import { useEffect, useState } from 'react'

function useNow(refreshFrequencyMs: number): Date {
    const [now, setNow] = useState(new Date())
    useEffect(() => {
        const interval = setInterval(() => setNow(new Date()), refreshFrequencyMs)
        return () => clearInterval(interval)
    })
    return now
}

export const useFormatNotificationTime = () => {
    const now = useNow(1000 * 60)

    return (notificationTime: number) => {
        const time = new Date(notificationTime)

        const minutes = differenceInMinutes(now, time)
        if (minutes < 1) {
            return 'Just now'
        }
        if (minutes < 60) {
            return `${minutes} minutes ago`
        }

        const hours = differenceInHours(now, time)
        if (hours < 24) {
            return `${hours} hour${hours > 1 ? 's' : ''} ago`
        }

        const days = differenceInDays(now, time)
        if (days < 7) {
            return `${days} day${days > 1 ? 's' : ''} ago`
        }

        const weeks = differenceInWeeks(now, time)
        if (weeks < 4) {
            return `${weeks} week${weeks > 1 ? 's' : ''} ago`
        }

        return format(time, 'dd/MM/yyyy')
    }
}
