import { ListingFilesApiData } from 'apiProviders'
import { createContext, useContext } from 'react'

export const UploadFilesContext = createContext<(files: File[], folder: string[]) => Promise<void>>(
    null as any,
)
export const useUploadFiles = () => useContext(UploadFilesContext)

export const MoveFilesContext = createContext<(file: ListingFilesApiData, folder: string) => Promise<any>>(
    null as any,
)
export const useMoveFiles = () => useContext(MoveFilesContext)
