import { Grid } from '@mui/joy'

export const LinkGridContainer = ({ children }) => (
    <Grid
        container
        spacing={2}
        columns={{
            xs: 2,
            sm: 3,
            md: 4,
            lg: 6,
            xl: 8,
        }}
    >
        {children}
    </Grid>
)
