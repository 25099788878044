import { Notifications } from '@mui/icons-material'
import {
    Badge,
    Box,
    Dropdown,
    IconButton,
    IconButtonProps,
    Menu,
    MenuButton,
    MenuItem,
    useTheme,
} from '@mui/joy'
import { useNotificationsApi } from 'apiProviders'
// @ts-ignore
import Favico from 'favico.js'
import { useFormatNotificationTime } from 'notificationTime'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'

var favicon = new Favico({
    animation: 'fade',
})

export default ({
    notificationsApi,
    ...props
}: IconButtonProps & { notificationsApi: ReturnType<typeof useNotificationsApi> }) => {
    const theme = useTheme()
    const navigate = useNavigate()
    const formatNotificationTime = useFormatNotificationTime()

    const notifications = notificationsApi.maybeData.orSome([])

    const unseenNotifications = notifications.filter(x => !x.seen).length

    useEffect(() => {
        favicon.badge(unseenNotifications)
    }, [unseenNotifications])

    return (
        <Dropdown>
            <Badge
                badgeContent={unseenNotifications}
                color="danger"
            >
                <MenuButton
                    slots={{ root: IconButton }}
                    slotProps={{
                        root: {
                            variant: 'outlined',
                            ...props,
                            sx: {
                                background: theme.palette.background.level1,
                                ...props.sx,
                            },
                        },
                    }}
                    onClick={notificationsApi.setAllSeen}
                >
                    <Notifications />
                </MenuButton>
            </Badge>
            <Menu
                placement="bottom-end"
                sx={{ zIndex: 1207 }}
            >
                {notifications.map(notification => (
                    <MenuItem
                        key={notification.notificationId}
                        onClick={() => {
                            if (notification.link) {
                                navigate(notification.link)
                            }
                        }}
                        sx={{
                            zIndex: 1207,
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: 4,
                        }}
                    >
                        <Box>{notification.message}</Box>
                        <Box>{formatNotificationTime(notification.notificationTime)}</Box>
                    </MenuItem>
                ))}
            </Menu>
        </Dropdown>
    )
}
