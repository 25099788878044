import z from 'zod'
import { InternalTransactionSchema, TransactionSchema } from './contract'
import { AddressSchema, mobileOrPhoneType } from './generic'
import { MarketingPackageSchema } from './marketingPackage'
import { ReiForm } from './rei'
import { VendorSchema } from './vendor'

export type RelloStatus = 'pending' | 'submitted' | 'active' | 'declined' | 'cancelled' | 'completed'

export type ListingType = 'sale' | 'bdm'

export const ListingStatuses = [
    'appraisal',
    'offline',
    'available',
    'under contract',
    'unconditional',
    'sold',
    'withdrawn',
    'not gained',
] as const
export type ListingStatus = (typeof ListingStatuses)[number]

export const GenericPersonSchema = z.object({
    name: z.string().min(1),
    email: z.string().email(),
    mobileNumber: mobileOrPhoneType,
})

const hiddenPrice = z.union([
    z.object({
        type: z.literal('fixed'),
        amount: z.number(),
    }),
    z.object({
        type: z.literal('range'),
        min: z.number(),
        max: z.number(),
    }),
])

export const AdditionalChargeItem = z
    .object({
        description: z.string(),
        amount: z.number(),
    })
    .and(
        z.union([
            z.object({
                payType: z.literal('settlement'),
            }),
            z.object({
                payType: z.literal('now'),
                payable: z.string().date(),
            }),
        ]),
    )

export const UpdateMarketingSchema = z.object({
    marketingPackage: MarketingPackageSchema.optional(),
    marketingDescription: z.string().optional(),
    hiddenPrice: hiddenPrice.optional(),
    advertisedPrice: z.string().optional(),
    propertyInfo: z
        .object({
            beds: z.number().optional(),
            baths: z.number().optional(),
            cars: z.number().optional(),
            landSize: z.number().optional(),
            landSizeUnits: z.enum(['m2', 'ha']).optional(),
            floorSize: z.number().optional(),
        })
        .optional(),
    listingType: z.enum(['sale', 'auction']).optional(),
    auctionDate: z.string().optional(),
    publishTo: z.array(z.enum(['realestate', 'domain'])).default(['realestate', 'domain']),
    links: z
        .array(
            z.object({
                url: z.string().url(),
            }),
        )
        .optional(),
    additionalDetails: z.record(z.string(), z.string().optional()).optional(),
})

export const UpdateAdditionalDetailsSchema = z.object({
    additionalDetails: z.record(z.string(), z.string().optional()),
})
export type UpdateAdditionalDetailsSchema = z.infer<typeof UpdateAdditionalDetailsSchema>

export const UpdateListingSchema = z.object({
    listingStatus: z.enum(ListingStatuses).optional(),
    listingAddress: AddressSchema.optional(),
    agentUserId: z.string(),
    vendors: z.array(VendorSchema).optional(),
    authorityStartDate: z.string().date().optional(),
    authorityPeriod: z.number().optional(),
    hiddenPrice: hiddenPrice.optional(),
    advertisedPrice: z.string().optional(),
    propertyType: z.enum(['residential', 'cts']).default('residential'),
    marketingDescription: z.string().optional(),
    lotNumber: z.string().optional(),
    planNumber: z.string().optional(),
    titleReference: z.string().optional(),
    marketingPaymentMethod: z.enum(['rello', 'settlement', 'manual']).optional(),
    marketingPackage: MarketingPackageSchema.optional(),
    /*marketingPackagePayable: z
        .object({
            type: z.enum(['deposit', 'settlement', 'rello now', 'rello later']).optional(),
            date: z.string().date().optional(),
        })
        .optional(),*/
    geoLocation: z.array(z.number()).length(2).optional(),
    additionalCharges: z.array(AdditionalChargeItem).default([]),
    additionalDetails: z.record(z.string(), z.string().optional()).optional(),
})

export const NewListingSchema = UpdateListingSchema.extend({
    listingStatus: z.enum(ListingStatuses).default('offline'),
    listingAddress: AddressSchema,
})

export const BalanceSheetSchema = z.object({
    balanceSheet: z.array(TransactionSchema).default([]),
})

export type UpdateMarketingSchema = z.infer<typeof UpdateMarketingSchema>
export type UpdateListingSchema = z.infer<typeof UpdateListingSchema>
export type NewListingSchema = z.infer<typeof NewListingSchema> & {
    statusLog: Record<ListingStatus, number>
}
export type BalanceSheetSchema = z.infer<typeof BalanceSheetSchema>

export type InternalListingSchema = Omit<NewListingSchema, 'vendors'> &
    UpdateMarketingSchema & {
        orgId: string
        listingId: string
        lastUpdated: number
        balanceSheet: InternalTransactionSchema[]
        agentAppointmentForm?: ReiForm
        additionalDetails: {
            [key: string]: string | null
        }
        vendors: VendorSchema[]
        storageName: string
        marketingPaymentStatus?: {
            prId: string
            paymentStatus: RelloStatus
        }
    }

export const getCommissionCalculatedPrice = ({
    advertisedPrice,
    hiddenPrice,
}: Pick<UpdateListingSchema, 'advertisedPrice' | 'hiddenPrice'>) => {
    if (typeof advertisedPrice == 'number') {
        return advertisedPrice
    }

    const trimmedAdvertisedPrice = advertisedPrice
        ?.replaceAll('$', '')
        ?.replaceAll(',', '')
        ?.replaceAll(' ', '')

    return trimmedAdvertisedPrice != undefined && !isNaN(trimmedAdvertisedPrice as any)
        ? parseInt(trimmedAdvertisedPrice)
        : hiddenPrice != undefined
          ? hiddenPrice?.type == 'fixed'
              ? hiddenPrice.amount
              : (hiddenPrice.max + hiddenPrice.min) / 2
          : undefined
}
