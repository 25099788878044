import { injectIf } from '@fastre/core/src/helperFunctions/object'
import List from '@mui/joy/List'
import ListItem from '@mui/joy/ListItem'
import ListItemButton from '@mui/joy/ListItemButton'
import { closeSidebar } from 'components/utils'
import { useMatch, useNavigate } from 'react-router'

interface SidebarListProps {
    items: {
        label: string
        pageUrl: string
    }[]
    baseUrl: string
    children?: React.ReactNode
}

const SidebarList = ({ items, baseUrl, children }: SidebarListProps) => {
    const navigate = useNavigate()
    const { currentPageUrl } = useMatch(`${baseUrl}/:currentPageUrl/*`)?.params ?? { currentPageUrl: '' }

    return (
        <List sx={{ gap: 0.5 }}>
            {items.map((item, i) => (
                <ListItem
                    key={item.pageUrl}
                    {...injectIf(i == 0, {
                        sx: { mt: 0.5 },
                    })}
                    //href={item.href}
                    onClick={() => {
                        navigate(`${baseUrl}/${item.pageUrl}`)
                        closeSidebar()
                    }}
                >
                    <ListItemButton selected={currentPageUrl == item.pageUrl}>{item.label}</ListItemButton>
                </ListItem>
            ))}
            {children}
        </List>
    )
}

export default SidebarList
