import { TransactionSchema } from '@fastre/core/src/schemas/contract'
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    ModalClose,
    ModalDialog,
    Stack,
    Typography,
} from '@mui/joy'
import Input from 'components/input'
import { format } from 'date-fns'
import { forwardRef, useState } from 'react'

const ConfirmPaidModal = forwardRef(
    (
        {
            transaction,
            close,
        }: {
            transaction: TransactionSchema
            close: (confirm: boolean, date: string) => void
        },
        ref: any,
    ) => {
        const [date, setDate] = useState(format(new Date(), 'yyyy-MM-dd'))

        return (
            <ModalDialog ref={ref}>
                <ModalClose />
                <DialogTitle>Confirm Paid/Receipted</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <Typography>
                            Are you sure you want to mark this {transaction.description} as paid/receipted?
                        </Typography>
                        <Input
                            type="date"
                            label="Date"
                            value={date}
                            onChange={setDate}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={() => close(false, date)}
                    >
                        Cancel
                    </Button>
                    <Button onClick={() => close(true, date)}>Confirm</Button>
                </DialogActions>
            </ModalDialog>
        )
    },
)

export default ConfirmPaidModal
