import { useTheme } from '@mui/joy'
import IconButton, { IconButtonProps } from '@mui/joy/IconButton'
import { useColorScheme } from '@mui/joy/styles'
import * as React from 'react'

import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded'
import LightModeIcon from '@mui/icons-material/LightMode'

export default function ColorSchemeToggle({ onClick, sx, ...props }: IconButtonProps) {
    const { mode, setMode } = useColorScheme()
    const theme = useTheme()
    const [mounted, setMounted] = React.useState(false)
    React.useEffect(() => {
        setMounted(true)
    }, [])
    if (!mounted) {
        return (
            <IconButton
                variant="outlined"
                color="neutral"
                {...props}
                sx={{
                    ...sx,
                    background: theme.palette.background.level1,
                }}
                disabled
            />
        )
    }
    return (
        <IconButton
            id="toggle-mode"
            variant="outlined"
            color="neutral"
            {...props}
            onClick={event => {
                if (mode === 'light') {
                    setMode('dark')
                } else {
                    setMode('light')
                }
                onClick?.(event)
            }}
            sx={[
                {
                    background: theme.palette.background.level1,
                    '& > *:first-of-type': {
                        display: mode === 'dark' ? 'none' : 'initial',
                    },
                    '& > *:last-of-type': {
                        display: mode === 'light' ? 'none' : 'initial',
                    },
                },
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
        >
            <DarkModeRoundedIcon />
            <LightModeIcon />
        </IconButton>
    )
}
