import { Stack, StackProps, useTheme } from '@mui/joy'
import { Breakpoint } from '@mui/material'

interface MobileFriendlyStackProps extends StackProps {
    breakpoint?: Breakpoint
}

export const MobileFriendlyStack = ({ children, breakpoint, ...props }: MobileFriendlyStackProps) => {
    const theme = useTheme()

    return (
        <Stack
            gap={theme.breakpoints.up(breakpoint ?? 'sm') ? 2 : 1}
            {...props}
            sx={{
                ...props.sx,
                [theme.breakpoints.up(breakpoint ?? 'sm')]: {
                    flexDirection: 'row',
                    //alignItems: 'flex-end',
                },
            }}
        >
            {children}
        </Stack>
    )
}
