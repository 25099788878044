import { Button, ButtonProps } from '@mui/joy'
import { useRef } from 'react'

interface FileUploadButtonProps extends Omit<ButtonProps, 'onChange'> {
    onChange: (files: File[]) => any
    accept?: string
}

export default ({ onChange, accept, ...props }: FileUploadButtonProps) => {
    const inputRef = useRef<HTMLInputElement>(null)

    const handleFileUpload = event => {
        //console.log(event.target.files[0].name);
        onChange(Array.from(event.target.files) as any)
    }

    return (
        <>
            <input
                ref={inputRef}
                onChange={handleFileUpload}
                type="file"
                style={{ display: 'none', visibility: 'hidden' }}
                multiple={true}
                accept={accept}
            />
            <Button
                onClick={() => inputRef.current!.click()}
                {...props}
            />
        </>
    )
}
