import { condSwitch } from '@fastre/core/src/helperFunctions/ramda'
import { AutomationActionSchema } from '@fastre/core/src/schemas/automation/action'
import { zodResolver } from '@hookform/resolvers/zod'
import { DeleteRounded } from '@mui/icons-material'
import {
    Autocomplete,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormLabel,
    Modal,
    ModalClose,
    ModalDialog,
    Option,
    Select,
    Stack,
    Textarea,
} from '@mui/joy'
import { useListingConfigApi, useSharedTodosApi } from 'apiProviders'
import { SlotInput, SlotWrapper } from 'components/input'
import { dontCloseOnBackgroundClick } from 'components/modal'
import MultiEmailInput from 'components/multiEmailInput'
import { prop } from 'ramda'
import { useEffect, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import ChatInput from '../../chat/chatInput'
import NotificationInput from './notificationInput'

interface AddConditionModalProps {
    editAction: AutomationActionSchema | undefined | null
    close: () => void
    onSet: (action: AutomationActionSchema) => void
    onDelete: () => void
}

export default ({ editAction, close, onSet, onDelete }: AddConditionModalProps) => {
    const listingConfigApi = useListingConfigApi()
    const sharedTodosApi = useSharedTodosApi(true)

    console.log('sharedTodosApi', sharedTodosApi)

    const editorRef = useRef<any>()

    const hookForm = useForm<AutomationActionSchema>({
        defaultValues: {},
        resolver: zodResolver(AutomationActionSchema),
    })

    useEffect(() => {
        hookForm.reset(editAction ?? {}, {
            keepValues: false,
            keepDefaultValues: false,
        })
    }, [JSON.stringify(editAction ?? {})])

    const { control, setValue, handleSubmit, watch, formState, getValues } = hookForm

    if (Object.keys(formState.errors).length > 0) {
        console.log('errors', formState.errors)
    }

    const actionType = watch('actionType')
    const updateFieldName = watch('updateFieldName')

    const onSubmit = (data: AutomationActionSchema) => {
        console.log('editorRef', editorRef, editorRef.current.getContents())

        if (data.actionType == 'notification') {
            data = {
                ...data,
                notification: editorRef.current.getContents(),
            }
        } else if (data.actionType == 'sharedTodo') {
            data = {
                ...data,
                todoDescription: editorRef.current.getContents(),
            }
        }

        console.log('onSubmit', data)
        onSet(data)
        close()
    }

    return (
        <Modal
            open={editAction !== null}
            onClose={dontCloseOnBackgroundClick(close)}
        >
            <form
                noValidate
                onSubmit={e => {
                    console.log('submitting', getValues())
                    handleSubmit(onSubmit)(e)
                }}
            >
                <ModalDialog>
                    <ModalClose />
                    <DialogTitle>Add Action</DialogTitle>
                    <DialogContent>
                        <Stack spacing={2}>
                            <Controller
                                name="actionType"
                                control={control}
                                render={field => (
                                    <SlotWrapper
                                        {...field}
                                        label="Action Type"
                                    >
                                        <Select
                                            {...field.field}
                                            onChange={(e, value) => field.field.onChange(value)}
                                        >
                                            {['email', 'notification', 'update', 'sharedTodo'].map(type => (
                                                <Option
                                                    key={type}
                                                    value={type}
                                                >
                                                    {condSwitch(
                                                        [
                                                            ['notification', 'Send Notification'],
                                                            ['email', 'Send Email'],
                                                            ['update', 'Update Field'],
                                                            ['sharedTodo', 'Create Shared Todo Item'],
                                                        ],
                                                        '',
                                                    )(type)}
                                                </Option>
                                            ))}
                                        </Select>
                                    </SlotWrapper>
                                )}
                            />
                            {actionType == 'notification' && (
                                <>
                                    <Controller
                                        name="toUsers"
                                        control={control}
                                        render={field => (
                                            <SlotWrapper
                                                {...field}
                                                label="Notification Users"
                                            >
                                                <MultiEmailInput allowedTypes={['user', 'special']} />
                                            </SlotWrapper>
                                        )}
                                    />
                                    <Controller
                                        name="notification"
                                        control={control}
                                        render={field => (
                                            <SlotWrapper
                                                {...field}
                                                label="Notification Message"
                                            >
                                                <Textarea
                                                    slots={{
                                                        textarea: props => {
                                                            console.log(
                                                                'field.field.value',
                                                                field.field.value,
                                                            )
                                                            return (
                                                                <NotificationInput
                                                                    ref={editorRef}
                                                                    //onChange={setMessage}
                                                                    initialContents={field.field.value}
                                                                    {...props}
                                                                />
                                                            )
                                                        },
                                                    }}
                                                    sx={{
                                                        '& p': {
                                                            margin: 0,
                                                        },
                                                        flexGrow: 1,
                                                    }}
                                                />
                                            </SlotWrapper>
                                        )}
                                    />
                                </>
                            )}
                            {actionType == 'email' && (
                                <>
                                    <Controller
                                        name="toUsers"
                                        control={control}
                                        render={field => (
                                            <SlotWrapper
                                                {...field}
                                                label="Notification Users"
                                            >
                                                <MultiEmailInput allowedTypes={['user', 'special']} />
                                            </SlotWrapper>
                                        )}
                                    />
                                    <Controller
                                        name="subject"
                                        control={control}
                                        render={field => (
                                            <SlotInput
                                                {...field}
                                                label="Title"
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="body"
                                        control={control}
                                        render={field => (
                                            <SlotWrapper
                                                {...field}
                                                label="Body"
                                            >
                                                <Textarea
                                                    slots={{
                                                        textarea: props => {
                                                            return (
                                                                <ChatInput
                                                                    ref={editorRef}
                                                                    //onChange={setMessage}
                                                                    {...props}
                                                                />
                                                            )
                                                        },
                                                    }}
                                                    sx={{
                                                        '& p': {
                                                            margin: 0,
                                                        },
                                                        flexGrow: 1,
                                                    }}
                                                />
                                            </SlotWrapper>
                                        )}
                                    />
                                </>
                            )}
                            {actionType == 'update' && (
                                <>
                                    <Controller
                                        name="updateFieldName"
                                        control={control}
                                        render={field => (
                                            <SlotWrapper
                                                {...field}
                                                label="Field Name"
                                            >
                                                <Autocomplete
                                                    {...field.field}
                                                    options={listingConfigApi.maybeData
                                                        .orSome([])
                                                        .map(prop('id'))}
                                                    getOptionLabel={optionId =>
                                                        listingConfigApi.maybeData
                                                            .orSome([])
                                                            .find(x => x.id == optionId)?.configName ?? ''
                                                    }
                                                    onChange={(e, value) => {
                                                        field.field.onChange(value)
                                                        setValue('updateFieldValue', '')
                                                        setValue('updateType', 'additionalDetails')
                                                    }}
                                                />
                                            </SlotWrapper>
                                        )}
                                    />
                                    <Controller
                                        name="updateFieldValue"
                                        control={control}
                                        render={field => (
                                            <SlotWrapper
                                                {...field}
                                                label="Value"
                                            >
                                                <Autocomplete
                                                    {...field.field}
                                                    options={
                                                        listingConfigApi.maybeData
                                                            .orSome([])
                                                            .find(x => x.id == updateFieldName)
                                                            ?.configValues?.map(x => x.value) ?? []
                                                    }
                                                    onChange={(e, value) => field.field.onChange(value)}
                                                    value={field.field.value as any}
                                                />
                                            </SlotWrapper>
                                        )}
                                    />
                                </>
                            )}
                            {actionType == 'sharedTodo' && (
                                <>
                                    <Controller
                                        name="todoId"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControl>
                                                <FormLabel>Shared Todo</FormLabel>
                                                <Select
                                                    {...field}
                                                    onChange={(e, value) => field.onChange(value)}
                                                >
                                                    {sharedTodosApi.data?.map(todo => (
                                                        <Option
                                                            key={todo.id}
                                                            value={todo.id}
                                                        >
                                                            {todo.name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        )}
                                    />
                                    <Controller
                                        name="todoDescription"
                                        control={control}
                                        render={field => (
                                            <SlotWrapper
                                                {...field}
                                                label="Todo Item Title"
                                            >
                                                <Textarea
                                                    slots={{
                                                        textarea: props => {
                                                            console.log(
                                                                'field.field.value',
                                                                field.field.value,
                                                            )
                                                            return (
                                                                <NotificationInput
                                                                    ref={editorRef}
                                                                    //onChange={setMessage}
                                                                    initialContents={field.field.value}
                                                                    {...props}
                                                                />
                                                            )
                                                        },
                                                    }}
                                                    sx={{
                                                        '& p': {
                                                            margin: 0,
                                                        },
                                                        flexGrow: 1,
                                                    }}
                                                />
                                            </SlotWrapper>
                                        )}
                                    />
                                </>
                            )}
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit">Add</Button>
                        <Button
                            variant="outlined"
                            onClick={close}
                        >
                            Cancel
                        </Button>
                        {editAction && (
                            <Button
                                variant="outlined"
                                onClick={onDelete}
                                color="danger"
                                startDecorator={<DeleteRounded />}
                                sx={{ mr: 'auto' }}
                            >
                                Delete
                            </Button>
                        )}
                    </DialogActions>
                </ModalDialog>
            </form>
        </Modal>
    )
}
