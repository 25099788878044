import { formatAddress } from '@fastre/core/src/schemas/generic'
import { Button, DialogActions, DialogTitle, Modal, ModalClose, ModalDialog, Textarea, Typography } from '@mui/joy'
import { useApi } from 'api'
import { dontCloseOnBackgroundClick } from 'components/modal'
import { prop } from 'ramda'
import { useState } from 'react'
import { useListingContext } from './listingProvider'

const GptModal = ({ open, close, setValue, listingAddress, propertyInfo }) => {
    const api = useApi()
    const { listing } = useListingContext()

    const [prompts, setPrompts] = useState('')
    const [loading, setLoading] = useState(false)

    return (
        <Modal
            open={open}
            onClose={dontCloseOnBackgroundClick(() => close())}
        >
            <ModalDialog>
                <ModalClose />
                <DialogTitle>Generate Marketing Description</DialogTitle>
                <Typography>Please provide a list of features you would like to include in the description</Typography>
                <Textarea
                    value={prompts}
                    onChange={e => setPrompts(e.target.value)}
                />
                <DialogActions>
                    <Button
                        loading={loading}
                        onClick={async () => {
                            setLoading(true)
                            console.log('posting')
                            try {
                                const { choices } = await api
                                    .post(`/openai/${listing.listingId}/listingmarketing`, {
                                        address: formatAddress(listingAddress),
                                        propertyInfo: propertyInfo ?? {},
                                        prompts,
                                    })
                                    .then(prop('data'))
                                setValue(choices[0].message.content)
                                close()
                            } catch (e) {
                                console.error(e)
                            } finally {
                                setLoading(false)
                            }
                        }}
                    >
                        Generate
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={close}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    )
}

export default GptModal
