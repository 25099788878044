import { useDraggable } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import { DeleteRounded, DownloadRounded, EmailRounded, InsertDriveFileRounded } from '@mui/icons-material'
import { IconButton, ListItem, ListItemButton, ListItemContent, Typography, useTheme } from '@mui/joy'
import { useApi } from 'api'
import { useListingFilesApi, useListingType } from 'apiProviders'
import { useUserData } from 'auth'
import { useShowSnack } from 'components/snackbar'
import { prop } from 'ramda'
import { useState } from 'react'
import { useListingContext } from '../listingProvider'
import LoadingIndicator from './loadingIndicator'
import RenderIndexLines from './renderIndexLines'

const FileRender = ({ file, setFile, index }) => {
    const theme = useTheme()
    const api = useApi()
    const { user } = useUserData()
    const listingType = useListingType()
    const { listing } = useListingContext()
    const filesApi = useListingFilesApi()
    const showSnack = useShowSnack()

    const {
        attributes,
        listeners,
        setNodeRef: draggableRef,
        transform,
        isDragging,
    } = useDraggable({
        id: file.Key,
    })

    const style = {
        transform: CSS.Transform.toString(transform),
    }

    const Icon = file.loading ? LoadingIndicator : file.isEmail ? EmailRounded : InsertDriveFileRounded
    const name = file.Key.split('/').pop().replaceAll('%2F', '/')

    const [loading, setLoading] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)

    return (
        <ListItem
            sx={{
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                ':hover': {
                    '& #deleteButton': {
                        opacity: 1,
                    },
                    '& #downloadButton': {
                        opacity: 1,
                    },
                },
                ...(isDragging && {
                    opacity: 0.7,
                }),
            }}
        >
            <ListItemButton
                ref={draggableRef}
                {...attributes}
                {...listeners}
                //sx={style}
            >
                <RenderIndexLines index={index} />

                <Icon />
                <ListItemContent sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography level="title-sm">{name}</Typography>
                    <IconButton
                        id="downloadButton"
                        loading={loading}
                        size="sm"
                        sx={{
                            opacity: loading ? 1 : 0,
                            transition: 'opacity 0.2s',
                        }}
                        onClick={async () => {
                            setLoading(true)
                            const url = await api
                                .post(`/listing/${listingType}/${listing.listingId}/getfile`, {
                                    filename: file.Key,
                                })
                                .then(prop('data'))
                            setFile({
                                name: file.Key.split('/').pop()!,
                                url,
                            })
                            setLoading(false)

                            //if (file.isEmail) {
                            //setFileKey(file.Key)
                            /*} else {
                                setLoading(true)
                                const url = await api
                                    .post(`/listing/${listingType}/${listing.listingId}/getfile`, {
                                        filename: file.Key,
                                    })
                                    .then(prop('data'))
                                window.open(url, '_blank')
                                setLoading(false)
                            }*/
                        }}
                    >
                        <DownloadRounded />
                    </IconButton>
                </ListItemContent>

                {user.permissions.includes('listings.fullControl') && (
                    <IconButton
                        id="deleteButton"
                        loading={deleteLoading}
                        size="sm"
                        sx={{
                            opacity: 0,
                            transition: 'opacity 0.2s',
                        }}
                        onClick={async e => {
                            e.stopPropagation()
                            setDeleteLoading(true)
                            await api.post(`/listing/${listingType}/${listing.listingId}/file/delete`, {
                                filename: file.Key,
                            })
                            setDeleteLoading(false)
                            filesApi.setVals(files => {
                                return files.filter(f => f.Key !== file.Key)
                            })
                            //await filesApi.refresh()
                        }}
                    >
                        <DeleteRounded />
                    </IconButton>
                )}
            </ListItemButton>
        </ListItem>
    )
}

export default FileRender
