import { useApi } from 'api'
import { useUserData } from 'auth'
import Loading from 'components/Loading'
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { UserData } from 'auth'

const prod = import.meta.env.VITE_APP_STAGE == 'prod'

const docusignBaseUrl = `https://account${prod ? '' : '-d'}.docusign.com`

export const docusignConnectionUrl = (user: UserData) => {
    console.log('user', user)

    const params = new URLSearchParams({
        response_type: 'code',
        scope: 'impersonation extended signature webforms_read webforms_instance_read webforms_instance_write',
        state: user.orgId,
        client_id: import.meta.env.VITE_DOCUSIGN_INTEGRATION_ID,
        redirect_uri: import.meta.env.DEV
            ? 'http://localhost:3005/docusign/auth'
            : `https://app${prod ? '' : `.${import.meta.env.VITE_APP_STAGE}`}.fastre.com.au/docusign/auth`,
        login_hint: user.email,
    }).toString()

    console.log('docusignConnectionUrl', `${docusignBaseUrl}/oauth/auth?${params.toString()}`)

    return `${docusignBaseUrl}/oauth/auth?${params.toString()}`
}

export const DocusignConnectionResponse = () => {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()

    const state = searchParams.get('state')

    useEffect(() => {
        navigate(`/${state}/docusign/auth?${searchParams.toString()}`)
    }, [])

    return <Loading />
}

export const LoggedInDocusignConnectionResponse = () => {
    const navigate = useNavigate()
    const api = useApi()
    const { user, refreshUser } = useUserData()
    const orgId = user.orgId
    const [searchParams] = useSearchParams()

    const code = searchParams.get('code')

    const setToken = async () => {
        await new Promise(r => setTimeout(r, 100))
        /*const baseUri = await axios
            .get(`${docusignBaseUrl}/oauth/userinfo`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
            .then(prop('data'))*/

        await api.post('/docusign/settoken', {
            code,
            //baseUri,
        })
        await refreshUser()
        navigate(`../../users/${user.userId}`, {
            replace: true,
            relative: 'path',
        })
    }

    useEffect(() => {
        setToken()
    }, [])

    return <Loading />
}
