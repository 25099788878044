import { useOrgId } from 'helpers'
import { Maybe } from 'monet'
import { prop } from 'ramda'
import { DependencyList, EffectCallback, useEffect, useRef, useState } from 'react'
import { NavigateOptions, useNavigate, useSearchParams } from 'react-router-dom'
import { URLSearchParams } from 'url'

export const useEffectNoInitial = (effect: EffectCallback, deps?: DependencyList) => {
    const initialRender = useRef(true)

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false
            return
        }

        effect()
    }, deps)
}

export function useMaybeState<S extends {}>(
    initialState?: S,
): [Maybe<S>, /*Dispatch<SetStateAction<S>>*/ (value: S | undefined | null) => void] {
    const [val, setVal] = useState<Maybe<S>>(Maybe.fromNull(initialState))
    const setter = (value: S | null | undefined) => setVal(Maybe.fromNull(value))
    return [val, setter]
}

export const getAllSearchParams = (searchParams: URLSearchParams) => {
    var params = {} as any
    // @ts-ignore
    for (const entry of searchParams.entries()) {
        //console.log(entry[0], entry[1])
        params[entry[0]] = entry[1]
    }

    return params
}

export const useSearchParamState = <T extends string | number | undefined>(
    key: string,
    defaultValue: T,
): [T, (val: T) => void] => {
    const [searchParams, setSearchParams] = useSearchParams()

    const searchParam = searchParams.get(key) ?? defaultValue
    const value = typeof defaultValue == 'number' ? (parseInt(searchParam as any) as T) : (searchParam as T)

    const setValue = (newValue: T) => {
        if (newValue == undefined) {
            searchParams.delete(key)
        } else {
            searchParams.set(key, '' + newValue)
        }
        setSearchParams(searchParams, { replace: true })
    }

    return [value, setValue]
}

export function useMaybeApiData<T extends {}>(
    apiCall: () => Promise<{ data: T }>,
): [Maybe<T>, () => Promise<void>] {
    const [data, setData] = useMaybeState<T>()

    const refresh = async () => {
        const newData = await apiCall().then(prop('data'))
        setData(newData)
    }

    useEffect(() => {
        refresh()
    }, [])

    return [data, refresh]
}

export function useOrgNavigate() {
    const orgId = useOrgId()
    const navigate = useNavigate()

    return (path: string, options?: NavigateOptions) => navigate(`/${orgId}${path}`, options)
}
