import { listingEnabledForUser } from '@fastre/core/src/schemas/saleListing'
import { useUsersApi } from 'apiProviders'
import { useUserData } from 'auth'
import DocusignFormStepper from './docusignFormStepper.tsx'
import { useListingContext } from './listingProvider'

export default function DisclosureTab() {
    const { user } = useUserData()
    const { listing } = useListingContext()
    const usersApi = useUsersApi()

    const enabledForUser = listingEnabledForUser(listing, usersApi.data, user)

    return (
        <>
            <DocusignFormStepper
                title="Disclosure Statement"
                formType="disclosureStatementForm"
                createDisabled={!enabledForUser}
                validate={() => true}
                preCreate={() => true}
                roleNames={listing.vendors.map((vendor, i) => `Vendor ${i + 1}`)}
            />
        </>
    )
}
