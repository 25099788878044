import { FormType } from '@fastre/core/src/schemas/docusign'
import { getFormName } from '@fastre/core/src/schemas/rei'
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Modal,
    ModalClose,
    ModalDialog,
    Typography,
} from '@mui/joy'
import { useApi } from 'api'
import axios from 'axios'
import DropZoneInner from 'components/dropzone'
import { useShowSnack } from 'components/snackbar'
import { useListingContext } from 'listings/listingProvider'
import { prop } from 'ramda'
import { useState } from 'react'

interface Props {
    open: boolean
    onClose: (docusignUrl?: string) => void
    formType: FormType
}

export default function UploadFormModal({ open, onClose, formType }: Props) {
    const api = useApi()
    const showSnack = useShowSnack()
    const { listing, setListing } = useListingContext()

    const [file, setFile] = useState<File>()
    const [loading, setLoading] = useState(false)

    return (
        <Modal
            open={open}
            onClose={() => onClose()}
        >
            <ModalDialog>
                <ModalClose />
                <DialogTitle>Upload Form</DialogTitle>
                <DialogContent>
                    <DropZoneInner
                        options={{
                            accept: {
                                'application/pdf': ['.pdf'],
                            },
                        }}
                        onUpload={files => setFile(files[0])}
                    />
                    {file && <Typography>{file.name}</Typography>}
                </DialogContent>
                <DialogActions>
                    <Button
                        loading={loading}
                        disabled={!file}
                        onClick={async () => {
                            try {
                                setLoading(true)
                                const { presignedUrl } = await api
                                    .post(
                                        `/listing/sale/${listing.listingId}/docusign/${formType.toLocaleLowerCase()}/uploadfile`,
                                    )
                                    .then(prop('data'))
                                await axios.put(presignedUrl, file)
                                const { updatedListing, docusignUrl } = await api
                                    .post(
                                        `/listing/sale/${listing.listingId}/docusign/${formType.toLocaleLowerCase()}/finishupload`,
                                        {
                                            emailSubject: `Please sign ${getFormName(formType)}`,
                                            emailBlurb: `Please sign ${getFormName(formType)}`,
                                            returnUrl: `${window.location.origin}/docusign/close`,
                                        },
                                    )
                                    .then(prop('data'))
                                setListing(updatedListing)
                                showSnack('Form uploaded', 'success')
                                onClose(docusignUrl)
                            } catch (e) {
                                console.error(e)
                                showSnack('Error uploading form', 'danger')
                            } finally {
                                setLoading(false)
                            }
                        }}
                    >
                        Upload
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() => onClose()}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    )
}
