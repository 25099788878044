import { SaleListingSearchSchema } from '@fastre/core/src/schemas/typesense'
import {
    AddRounded,
    ClearRounded,
    CloseRounded,
    FilterAltRounded,
    SaveRounded,
    Search,
    SettingsRounded,
} from '@mui/icons-material'
import {
    Badge,
    Box,
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormLabel,
    IconButton,
    Input,
    Modal,
    ModalClose,
    ModalDialog,
    Option,
    Select,
    Stack,
    Tab,
    TabList,
    Tabs,
    useTheme,
} from '@mui/joy'
import { useApi } from 'api'
import {
    useListingConfigApi,
    useListingsApi,
    useListingType,
    useSavedFiltersApi,
    useUsersApi,
} from 'apiProviders'
import { useUserData } from 'auth'
import { MultipleAgentInput } from 'components/agentInput'
import Loading from 'components/Loading'
import { dontCloseOnBackgroundClick } from 'components/modal'
import SectionHead from 'components/sectionHead'
import { MobileFriendlyStack } from 'components/stack'
import { useSearchParamState } from 'helperFunctions/react'
import { equals, omit, prop } from 'ramda'
import React, { forwardRef, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { useDebounce } from 'use-debounce'
import { CoreMapView } from '../map'
import ColumnConfigModal from './columnConfigModal'
import ListingsTableDesktop from './listingsTableDesktop'
import ListingsTableMobile from './listingsTableMobile'

interface ActionFilterModalProps {
    close: () => void
    actionFilters: Record<string, string[]>
    setActionFilters: (filter: Record<string, string[]>) => void
}

const ActionFilterModal = forwardRef(({ close, ...props }: ActionFilterModalProps, ref: any) => {
    const listingConfigApi = useListingConfigApi()

    const [actionFilter, setActionFilter] = useState(props.actionFilters)

    return (
        <ModalDialog ref={ref}>
            <ModalClose />
            <DialogTitle>Action Filters</DialogTitle>
            <DialogContent>
                {listingConfigApi.maybeData
                    .map(configs => (
                        <>
                            {configs.map(config => (
                                <FormControl key={config.id}>
                                    <FormLabel>{config.configName}</FormLabel>
                                    <Select
                                        multiple
                                        value={actionFilter[config.id] ?? []}
                                        onChange={(e, value) => {
                                            if (value && value.length > 0) {
                                                setActionFilter({
                                                    ...actionFilter,
                                                    [config.id]: value,
                                                })
                                            } else {
                                                setActionFilter(omit([config.id], actionFilter))
                                            }
                                        }}
                                    >
                                        {config.configValues.map(option => (
                                            <Option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.value}
                                            </Option>
                                        ))}
                                    </Select>
                                </FormControl>
                            ))}
                        </>
                    ))
                    .orSome(<Loading />)}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        props.setActionFilters(actionFilter)
                        close()
                    }}
                >
                    Save
                </Button>
                <Button
                    variant="outlined"
                    onClick={close}
                >
                    Cancel
                </Button>
                <Button
                    color="danger"
                    variant="outlined"
                    startDecorator={<ClearRounded />}
                    onClick={() => {
                        props.setActionFilters({})
                        close()
                    }}
                >
                    Clear
                </Button>
            </DialogActions>
        </ModalDialog>
    )
})

const StatusFilter = ({
    statusFilter,
    setStatusFilter,
}: {
    statusFilter: string[]
    setStatusFilter: React.Dispatch<React.SetStateAction<string[]>>
}) => {
    return (
        <FormControl
            sx={{
                flex: 1,
            }}
        >
            <FormLabel>Status</FormLabel>
            <Select
                multiple
                value={statusFilter}
                onChange={(e, value) => setStatusFilter(value)}
                {...(statusFilter.length > 0 && {
                    endDecorator: (
                        <IconButton
                            size="sm"
                            variant="plain"
                            color="neutral"
                            onMouseDown={event => {
                                // don't open the popup when clicking on this button
                                event.stopPropagation()
                            }}
                            onClick={() => {
                                setStatusFilter([])
                                //action.current?.focusVisible();
                            }}
                        >
                            <CloseRounded />
                        </IconButton>
                    ),
                    indicator: null,
                })}
            >
                <Option value="offline">Offline</Option>
                <Option value="appraisal">Appraisal</Option>
                <Option value="available">Available</Option>
                <Option value="under contract">Under Contract</Option>
                <Option value="unconditional">Unconditional</Option>
                <Option value="withdrawn">Withdrawn</Option>
                <Option value="not gained">Not Gained</Option>
                <Option value="sold">Sold</Option>
            </Select>
        </FormControl>
    )
}

const AllFilersModal = forwardRef(
    (
        {
            statusFilter,
            setStatusFilter,
            agentsFilter,
            setAgentsFilter,
            actionFilters,
            setActionFilters,
            onClose,
        }: any,
        ref: any,
    ) => {
        const listingConfigApi = useListingConfigApi()

        return (
            <ModalDialog ref={ref}>
                <ModalClose />
                <DialogTitle>All Filters</DialogTitle>
                <DialogContent>
                    <Stack gap={2}>
                        <StatusFilter {...{ statusFilter, setStatusFilter }} />
                        <FormControl
                            sx={{
                                flex: 1,
                            }}
                        >
                            <FormLabel>Agents</FormLabel>
                            <MultipleAgentInput
                                value={agentsFilter}
                                onChange={setAgentsFilter}
                                filter={x => x.salesAgent}
                            />
                        </FormControl>

                        {listingConfigApi.maybeData
                            .map(configs => (
                                <>
                                    {configs.map(config => (
                                        <FormControl key={config.id}>
                                            <FormLabel>{config.configName}</FormLabel>
                                            <Select
                                                multiple
                                                value={actionFilters[config.id] ?? []}
                                                onChange={(e, value) => {
                                                    if (value && value.length > 0) {
                                                        setActionFilters({
                                                            ...actionFilters,
                                                            [config.id]: value,
                                                        })
                                                    } else {
                                                        setActionFilters(omit([config.id], actionFilters))
                                                    }
                                                }}
                                            >
                                                {config.configValues.map(option => (
                                                    <Option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.value}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    ))}
                                </>
                            ))
                            .orSome(<Loading />)}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={onClose}
                    >
                        Close
                    </Button>
                </DialogActions>
            </ModalDialog>
        )
    },
)

export default function ListingsTable() {
    const theme = useTheme()
    const api = useApi()
    const type = useListingType()
    const listingsApi = useListingsApi()
    const savedFiltersApi = useSavedFiltersApi()
    const navigate = useNavigate()
    const { user } = useUserData()
    const usersApi = useUsersApi()

    const [statusFilter, setStatusFilter] = useState<string[]>(listingsApi.params.status ?? [])
    const [agentsFilter, setAgentsFilter] = useState<string[]>(
        listingsApi.params.agentIds ??
            (user.salesAgent
                ? [user.userId]
                : user.salesAssistantLeadAgentId
                  ? [user.salesAssistantLeadAgentId]
                  : []),
    )
    const [actionFilters, setActionFilters] = useState<Record<string, string[]>>(
        listingsApi.params.actionFilters ?? {},
    )

    useEffect(() => {
        if (usersApi.data) {
            if (user.salesAssistantConjunctionalAgencyId) {
                setAgentsFilter(
                    usersApi.data
                        .filter(
                            x =>
                                x.conjunctionalAgencyId == user.salesAssistantConjunctionalAgencyId &&
                                user.salesAgent,
                        )
                        .map(prop('userId')),
                )
            }
        }
    }, [usersApi.data != undefined])

    const [search, setSearch] = useState(listingsApi.params.search ?? '')
    const [actionFilterOpen, setActionFilterOpen] = useState(false)
    const [saveFilterOpen, setSaveFilterOpen] = useState(false)
    const [newFilterName, setNewFilterName] = useState('')
    const [saveFilterLoading, setSaveFilterLoading] = useState(false)
    const [updateFilterLoading, setUpdateFilterLoading] = useState<string>()

    const [activeFilter, setActiveFilter] = useState<string>()
    const [allFiltersOpen, setAllFilersOpen] = useState(false)
    const [columnConfigOpen, setColumnConfigOpen] = useState(false)

    const savedFilters = savedFiltersApi.data?.sale ?? {}

    const saveFilter = async (name: string) =>
        api.post('/savedfilters', {
            sale: {
                ...savedFilters,
                [name]: {
                    status: statusFilter,
                    agentIds: agentsFilter,
                    search,
                    actions: actionFilters,
                },
            },
        })

    const [tabValue, setTabValue] = useSearchParamState<'list' | 'map'>('view', 'list')

    const applyParamsMemo = useMemo(
        () => [statusFilter, agentsFilter, search, actionFilters],
        [statusFilter, agentsFilter, search, actionFilters],
    )

    const [debouncedApplyParams] = useDebounce(applyParamsMemo, 100)

    useEffect(() => {
        const searchParams: Partial<SaleListingSearchSchema> = {
            search: search,
            status: statusFilter,
            agentIds: agentsFilter,
            actionFilters,
        }

        listingsApi.setParams(x => ({
            ...x,
            ...searchParams,
        }))
    }, debouncedApplyParams)

    return (
        <>
            <Box
                sx={{
                    px: {
                        xs: 2,
                        sm: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    },
                }}
            >
                <SectionHead
                    title={`${type == 'bdm' ? 'BDM ' : ''}Listings`}
                    titleChip={
                        <IconButton onClick={() => setColumnConfigOpen(true)}>
                            <SettingsRounded />
                        </IconButton>
                    }
                    buttons={
                        (user.permissions.includes('listings.edit') ||
                            user.permissions.includes('listings.fullControl')) && (
                            <Button
                                component="a"
                                startDecorator={<AddRounded />}
                                //href='listings/new'
                                onClick={() => navigate('new')}
                            >
                                Add Listing
                            </Button>
                        )
                    }
                />
                <MobileFriendlyStack
                    sx={{
                        [theme.breakpoints.down('sm')]: {
                            display: 'none',
                        },
                    }}
                >
                    <StatusFilter
                        {...{
                            statusFilter,
                            setStatusFilter,
                        }}
                    />
                    <FormControl
                        sx={{
                            flex: 1,
                        }}
                    >
                        <FormLabel>Agents</FormLabel>
                        <MultipleAgentInput
                            value={agentsFilter}
                            onChange={setAgentsFilter}
                            filter={x => x.salesAgent}
                        />
                    </FormControl>
                </MobileFriendlyStack>
                <MobileFriendlyStack sx={{ my: 2 }}>
                    <Stack
                        direction="row"
                        spacing={2}
                        sx={{ flex: 1 }}
                    >
                        <Input
                            placeholder="Search by address, agent, or status"
                            value={search}
                            onChange={e => setSearch(e.target.value)}
                            startDecorator={<Search />}
                            {...(search != '' && {
                                // display the button and remove select indicator
                                // when user has selected a value
                                endDecorator: (
                                    <IconButton
                                        size="sm"
                                        variant="plain"
                                        color="neutral"
                                        onMouseDown={event => {
                                            // don't open the popup when clicking on this button
                                            event.stopPropagation()
                                        }}
                                        onClick={() => {
                                            setSearch('')
                                            //action.current?.focusVisible();
                                        }}
                                    >
                                        <CloseRounded />
                                    </IconButton>
                                ),
                                indicator: null,
                            })}
                            sx={{
                                flex: 1,
                            }}
                        />
                        <Box
                            sx={{
                                [theme.breakpoints.up('sm')]: {
                                    display: 'none',
                                },
                            }}
                        >
                            <Badge
                                badgeContent={
                                    [
                                        statusFilter.length,
                                        agentsFilter.length,
                                        search.length,
                                        ...Object.values(actionFilters).map(x => x.length),
                                    ].filter(x => x > 0).length
                                }
                            >
                                <IconButton onClick={() => setAllFilersOpen(true)}>
                                    <FilterAltRounded />
                                </IconButton>
                            </Badge>
                        </Box>
                    </Stack>
                    <Box
                        sx={{
                            [theme.breakpoints.down('sm')]: {
                                display: 'none',
                            },
                        }}
                    >
                        <Badge
                            badgeContent={Object.keys(actionFilters).length}
                            color="primary"
                        >
                            <Button
                                variant="soft"
                                onClick={() => setActionFilterOpen(true)}
                            >
                                Action Filters
                            </Button>
                        </Badge>
                    </Box>
                </MobileFriendlyStack>
                <Stack
                    direction="row"
                    sx={{
                        display: {
                            xs: 'none',
                            sm: 'flex',
                        },
                    }}
                >
                    <Tabs
                        value={tabValue}
                        onChange={(e, val) => setTabValue(val as any)}
                        sx={{ flex: 1 }}
                    >
                        <TabList>
                            <Tab value="list">List</Tab>
                            <Tab value="map">Map View</Tab>
                        </TabList>
                    </Tabs>

                    {false && (
                        <Stack
                            direction="row"
                            spacing={2}
                        >
                            {Object.entries(savedFilters).map(([name, filters]) => (
                                <Badge
                                    key={name}
                                    variant={updateFilterLoading == name ? 'plain' : undefined}
                                    badgeContent={
                                        activeFilter == name ? (
                                            updateFilterLoading == name ? (
                                                <CircularProgress size="sm" />
                                            ) : !equals(filters.status, statusFilter) ||
                                              !equals(filters.agentIds, agentsFilter) ||
                                              !equals(filters.search, search) ||
                                              !equals(filters.actions, actionFilters) ? (
                                                <SaveRounded fontSize={'sm' as any} />
                                            ) : (
                                                0
                                            )
                                        ) : (
                                            0
                                        )
                                    }
                                    onClick={async e => {
                                        if ((e.target as any).type == undefined) {
                                            setUpdateFilterLoading(name)
                                            //await new Promise(resolve => setTimeout(resolve, 10000))
                                            await saveFilter(name)
                                            await savedFiltersApi.refresh()
                                            setUpdateFilterLoading(undefined)
                                        }
                                    }}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <Button
                                        variant={activeFilter == name ? 'soft' : 'outlined'}
                                        value={name}
                                        onClick={() => {
                                            setActiveFilter(name)
                                            const savedFilter = savedFilters[name]!
                                            setStatusFilter(savedFilter.status)
                                            setAgentsFilter(savedFilter.agentIds)
                                            setSearch(savedFilter.search)
                                            setActionFilters(savedFilter.actions)
                                        }}
                                    >
                                        {name}
                                    </Button>
                                </Badge>
                            ))}
                            <IconButton
                                variant="soft"
                                onClick={() => setSaveFilterOpen(true)}
                            >
                                <AddRounded />
                            </IconButton>
                        </Stack>
                    )}
                </Stack>
                <Box sx={{ flex: 1, mt: 2 }}>
                    <Box
                        hidden={tabValue != 'list'}
                        sx={{ height: '100%' }}
                    >
                        <ListingsTableDesktop />
                        <ListingsTableMobile />
                    </Box>
                    <Box
                        hidden={tabValue != 'map'}
                        sx={{ height: '100%' }}
                    >
                        <CoreMapView />
                    </Box>
                </Box>
            </Box>
            <Modal
                open={actionFilterOpen}
                onClose={dontCloseOnBackgroundClick(() => setActionFilterOpen(false))}
            >
                <ActionFilterModal
                    actionFilters={actionFilters}
                    close={() => setActionFilterOpen(false)}
                    setActionFilters={setActionFilters}
                />
            </Modal>
            <Modal
                open={saveFilterOpen}
                onClose={dontCloseOnBackgroundClick(() => setSaveFilterOpen(false))}
            >
                <ModalDialog>
                    <ModalClose />
                    <DialogTitle>Save Filter</DialogTitle>
                    <DialogContent>
                        <Input
                            value={newFilterName}
                            placeholder="Filter Name"
                            onChange={e => {
                                setNewFilterName(e.target.value)
                            }}
                            sx={{ width: '100%' }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            loading={saveFilterLoading}
                            onClick={async () => {
                                setSaveFilterLoading(true)
                                await saveFilter(newFilterName)
                                await savedFiltersApi.refresh()
                                setActiveFilter(newFilterName)
                                setSaveFilterLoading(false)
                                setSaveFilterOpen(false)
                            }}
                        >
                            Save
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => setSaveFilterOpen(false)}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>
            <Modal
                open={allFiltersOpen}
                onClose={dontCloseOnBackgroundClick(() => setAllFilersOpen(false))}
            >
                <AllFilersModal
                    {...{
                        statusFilter,
                        setStatusFilter,
                        agentsFilter,
                        setAgentsFilter,
                        actionFilters,
                        setActionFilters,
                    }}
                    onClose={() => setAllFilersOpen(false)}
                />
            </Modal>
            <ColumnConfigModal
                open={columnConfigOpen}
                onClose={() => setColumnConfigOpen(false)}
            />
        </>
    )
}
