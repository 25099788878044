import { InternalAgentStatement } from '@fastre/core/src/schemas/agentStatement'
import { Box, Button } from '@mui/joy'
import { useAgentStatementApi } from 'apiProviders'
import { useUserData } from 'auth'
import Loading from 'components/Loading'
import { useState } from 'react'
import { useParams } from 'react-router'
import AddAgentStatementModal from './addAgentStatementModal'
import AgentStatementTable from './agentStatementTable'
import EditAgentStatementModal from './editAgentStatementModal'
import GeneratePayrollModal from './generatePayrollModal'

const AgentStatement = () => {
    const { userId } = useParams<{ userId: string }>()
    const { user } = useUserData()
    const agentStatementApi = useAgentStatementApi(userId!)

    const [editItem, setEditItem] = useState<InternalAgentStatement>()
    const [createNewItem, setCreateNewItem] = useState(false)
    const [generatePayroll, setGeneratePayroll] = useState(false)

    if (!agentStatementApi.data) {
        return <Loading />
    }

    return (
        <>
            <AgentStatementTable
                items={agentStatementApi.data}
                onEdit={item => setEditItem(item)}
            />
            <Box sx={{ my: 4, display: 'flex' }}>
                {user.permissions.includes('agentStatement.edit') && (
                    <Button
                        variant="outlined"
                        onClick={() => setCreateNewItem(true)}
                    >
                        Add Item
                    </Button>
                )}
                {user.permissions.includes('agentStatement.generatePayroll') && (
                    <Button
                        sx={{ ml: 'auto' }}
                        onClick={() => setGeneratePayroll(true)}
                    >
                        Process Payroll
                    </Button>
                )}
            </Box>
            <EditAgentStatementModal
                item={editItem}
                onClose={newVal => {
                    setEditItem(undefined)
                    console.log('newVal', newVal)
                    if (newVal) {
                        agentStatementApi.setVals(items => items.map(i => (i.id == newVal.id ? newVal : i)))
                    }
                }}
            />
            <AddAgentStatementModal
                userId={userId!}
                open={createNewItem}
                onClose={() => setCreateNewItem(false)}
            />
            {generatePayroll && (
                <GeneratePayrollModal
                    items={agentStatementApi.data}
                    onClose={() => setGeneratePayroll(false)}
                />
            )}
        </>
    )
}

export default AgentStatement
