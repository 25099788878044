import { ListingContractSchema } from '@fastre/core/src/schemas/contract'
import { SlotInput } from 'components/input'
import { MobileFriendlyStack } from 'components/stack'
import { Controller } from 'react-hook-form'

interface EditSolicitorProps {
    control
    solicitor: keyof ListingContractSchema
    disabled?: boolean
}

const EditSolicitor = ({ control, solicitor, disabled }: EditSolicitorProps) => {
    return (
        <>
            <MobileFriendlyStack>
                <Controller
                    name={`${solicitor}.name`}
                    disabled={disabled}
                    control={control}
                    render={field => (
                        <SlotInput
                            label="Name"
                            {...field}
                            formControlProps={{
                                sx: { flex: 1 },
                            }}
                        />
                    )}
                />
                <Controller
                    name={`${solicitor}.contact`}
                    control={control}
                    disabled={disabled}
                    render={field => (
                        <SlotInput
                            label="Contact"
                            {...field}
                            formControlProps={{
                                sx: { flex: 1 },
                            }}
                        />
                    )}
                />
            </MobileFriendlyStack>
            <MobileFriendlyStack>
                <Controller
                    name={`${solicitor}.email`}
                    disabled={disabled}
                    control={control}
                    render={field => (
                        <SlotInput
                            label="Email"
                            {...field}
                            formControlProps={{
                                sx: { flex: 1 },
                            }}
                        />
                    )}
                />
                <Controller
                    name={`${solicitor}.mobileNumber`}
                    disabled={disabled}
                    control={control}
                    render={field => (
                        <SlotInput
                            label="Phone Number"
                            {...field}
                            formControlProps={{
                                sx: { flex: 1 },
                            }}
                        />
                    )}
                />
            </MobileFriendlyStack>
        </>
    )
}

export default EditSolicitor
