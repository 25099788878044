import ChevronRightRounded from '@mui/icons-material/ChevronRightRounded'
import HomeRounded from '@mui/icons-material/HomeRounded'
import { Box, Breadcrumbs, Link, Stack, Typography } from '@mui/joy'
import { useUserData } from 'auth'

interface Props {
    title: string
    titleChip?: React.ReactNode
    breadcrumbs?: Array<string>
    buttons?: React.ReactNode
    sx?: any
}

export default ({ title, titleChip, breadcrumbs, buttons, sx }: Props) => {
    const { user } = useUserData()

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', ...sx }}>
                <Breadcrumbs
                    size="sm"
                    aria-label="breadcrumbs"
                    separator={<ChevronRightRounded fontSize="small" />}
                    sx={{ pl: 0 }}
                >
                    <Link
                        underline="none"
                        color="neutral"
                        href={`/${user.orgId}`}
                        aria-label="Home"
                    >
                        <HomeRounded />
                    </Link>
                    {breadcrumbs?.map(breadcrumb => (
                        <Link
                            key={breadcrumb}
                            underline="hover"
                            fontSize={12}
                            fontWeight={500}
                            color="neutral"
                            href=".."
                        >
                            {breadcrumb}
                        </Link>
                    ))}
                    <Typography
                        color="primary"
                        fontWeight={500}
                        fontSize={12}
                    >
                        {title}
                    </Typography>
                </Breadcrumbs>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    mt: 1,
                    mb: 3,
                    gap: 1,
                    flexDirection: 'row',
                    alignItems: 'center',
                    //flexDirection: { xs: 'column', sm: 'row' },
                    //alignItems: { xs: 'start', sm: 'center' },
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                }}
            >
                <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                >
                    <Typography level="h2">{title}</Typography>
                    {titleChip}
                </Stack>
                {buttons}
            </Box>
        </>
    )
}
