import { getColumnName, SalesListingColumns } from '@fastre/core/src/schemas/columnConfig'
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Modal,
    ModalDialog,
    Stack,
    Switch,
    Typography,
} from '@mui/joy'
import { useApi } from 'api'
import { useListingConfigApi } from 'apiProviders'
import { useUserData } from 'auth'
import { sortBy } from 'ramda'
import { useState } from 'react'

interface Props {
    open: boolean
    onClose: () => void
}

export default ({ open, onClose }: Props) => {
    const api = useApi()
    const { user, refreshUser } = useUserData()
    const listingConfigApi = useListingConfigApi()

    const [config, setConfig] = useState(user.customColumnsConfig)
    const [loading, setLoading] = useState(false)

    const save = async () => {
        setLoading(true)
        try {
            await api.post(
                '/columnconfig',
                sortBy(col => (col.key == 'action' ? 999 : SalesListingColumns.indexOf(col.key)), config),
            )
            await refreshUser()
            onClose()
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <ModalDialog>
                <DialogTitle>Column Configuration</DialogTitle>
                <DialogContent>
                    <Stack
                        gap={2}
                        sx={{ mt: 2 }}
                    >
                        {SalesListingColumns.map(col => (
                            <Box key={col}>
                                <Switch
                                    endDecorator={getColumnName(col)}
                                    checked={config.some(c => c.key == col)}
                                    onChange={e =>
                                        setConfig(prev => {
                                            if (e.target.checked) {
                                                return [...prev, { key: col }]
                                            } else {
                                                return prev.filter(c => c.key != col)
                                            }
                                        })
                                    }
                                />
                            </Box>
                        ))}
                        <Typography sx={{ mt: 2 }}>Actions</Typography>
                        {listingConfigApi.data?.map(action => (
                            <Box key={action.id}>
                                <Switch
                                    endDecorator={action.configName}
                                    checked={config.some(c => c.key == 'action' && c.actionId == action.id)}
                                    onChange={e =>
                                        setConfig(prev => {
                                            if (e.target.checked) {
                                                return [...prev, { key: 'action', actionId: action.id }]
                                            } else {
                                                return prev.filter(
                                                    c => c.key != 'action' || c.actionId != action.id,
                                                )
                                            }
                                        })
                                    }
                                />
                            </Box>
                        ))}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        loading={loading}
                        onClick={save}
                    >
                        Save
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    )
}
