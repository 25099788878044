import { Maybe } from 'monet'
import { equals } from 'ramda'

export function injectIf<T extends {}>(
    condition: boolean | null | undefined,
    obj: { [propName: string]: T },
): { [propName: string]: T } | {} {
    return condition ? obj : {}
}

export function injectMaybe<T extends {}>(propName: string, maybeValue: Maybe<T>): { [propName: string]: T } | {} {
    return maybeValue.map(x => ({ [propName]: x })).orSome({})
}

export const trimUndefined = (obj: any): any =>
    obj
        ? Object.entries(obj).reduce((prev, [key, value]: any) => {
              //console.log('checking', key, value)

              if (typeof value == 'object') {
                  const trimmedValue = trimUndefined(value)

                  return {
                      ...prev,
                      ...injectIf(Object.keys(trimmedValue).length > 0, {
                          [key]: trimmedValue,
                      }),
                  }
              } else {
                  return {
                      ...prev,
                      ...injectIf(value !== undefined, { [key]: value }),
                  }
              }
          }, {})
        : undefined

export const flattenObj = (obj: Object, leaveArrObjsWhole = false, prepend?: string): Record<string, any> =>
    Object.entries(obj).reduce((acc, [key, value]) => {
        const objKey = `${prepend ? prepend : ''}${Array.isArray(obj) ? `[${key}]` : `${prepend ? '.' : ''}${key}`}`

        if (leaveArrObjsWhole && !isNaN(key as any)) {
            return {
                ...acc,
                [objKey]: value,
            }
        }

        if (value != null && typeof value === 'object') {
            return {
                ...acc,
                ...flattenObj(value, leaveArrObjsWhole, objKey),
            }
        } else {
            return {
                ...acc,
                [objKey]: value,
            }
        }
    }, {})

export const getDiff = <T extends {}>(newObj: T, oldObj: T) => {
    const diffed = Object.entries(newObj).reduce((prev, [key, value]) => {
        return {
            ...prev,
            ...injectIf(!equals(value, oldObj[key as keyof T]), {
                [key]: value,
            } as any),
        }
    }, {})

    return diffed as T
}

export const getMaybeDiff = <T extends {}>(object: T, maybeOriginalOjbect: Maybe<T>): any =>
    maybeOriginalOjbect.map(ogObject => getDiff(object, ogObject)).orSome(object)
