import { roundTo2 } from '@fastre/core/src/helperFunctions/number'
import { toCurrency } from '@fastre/core/src/helperFunctions/string'
import { InternalAgentStatement } from '@fastre/core/src/schemas/agentStatement'
import { EditRounded } from '@mui/icons-material'
import { IconButton, Table } from '@mui/joy'
import { useUserData } from 'auth'
import { format } from 'date-fns'
import SortableHeader, { Sort } from 'listings/listingsTable/sortableHeader'
import { ascend, descend, sortWith } from 'ramda'
import { Fragment, useState } from 'react'

const RenderItem = ({ item, children }: { item: InternalAgentStatement; children }) => {
    if (item.type == 'debit') {
        return (
            item.type == 'debit' && (
                <tr>
                    <td>{format(item.date, 'dd/MM/yy')}</td>
                    <td>{item.description}</td>
                    <td style={{ textAlign: 'right' }}>{toCurrency(item.amount)}</td>
                    <td></td>
                    {children}
                </tr>
            )
        )
    } else {
        if (item.creditType == 'listingCommission') {
            return (
                <tr>
                    <td>{format(item.date, 'dd/MM/yy')}</td>
                    <td>
                        <b>Agent Commission - {item.listingName}</b>
                        <br />
                        <b>TOTAL (Inc.):</b> {toCurrency(item.totalCommissionAmountIncGst)}
                        {item.conjunctionalAgreements.map(ca => (
                            <Fragment key={ca.name}>
                                <br />
                                <b>Conjunction ({ca.name}):</b> {toCurrency(ca.amount)}
                            </Fragment>
                        ))}
                        <br />
                        <b>AGENT inc. Super:</b> {toCurrency(item.agentCommissionAmountIncSuper)}
                        <br />
                        <b>AGENT PAYMENT:</b> {toCurrency(item.agentCommissionAmountExSuper)}
                    </td>
                    <td></td>
                    <td style={{ textAlign: 'right' }}>{toCurrency(item.amount)}</td>
                    {children}
                </tr>
            )
        } else if (item.creditType == 'conjunctionalAgreement') {
            return (
                <tr>
                    <td>{format(item.date, 'dd/MM/yy')}</td>
                    <td>
                        <b>Referral Commission</b> - {item.listingName}
                        <br />
                        <b>TOTAL (Inc.):</b> {toCurrency(item.amountIncGst)}
                        <br />
                        <b>AGENT inc. Super:</b> {toCurrency(item.agentCommissionAmountIncSuper)}
                        <br />
                        <b>AGENT PAYMENT:</b> {toCurrency(item.agentCommissionAmountExSuper)}
                    </td>
                    <td></td>
                    <td style={{ textAlign: 'right' }}>${toCurrency(item.amount)}</td>
                    {children}
                </tr>
            )
        } else {
            return (
                <tr>
                    <td>{format(item.date, 'dd/MM/yy')}</td>
                    <td>{item.description}</td>
                    <td></td>
                    <td style={{ textAlign: 'right' }}>{toCurrency(item.amount)}</td>
                    {children}
                </tr>
            )
        }
    }
}

export default function AgentStatementTable({
    items,
    onEdit,
}: {
    items: InternalAgentStatement[]
    onEdit?: (item: InternalAgentStatement) => void
}) {
    const { user } = useUserData()
    const [sort, setSort] = useState<Sort>({
        sort: 'date',
        sortDirection: 'asc',
    })

    const balance = roundTo2(
        items.reduce((acc, item) => acc + (item.type == 'debit' ? -item.amount : item.amount), 0),
    )

    const sortFunc = sort.sortDirection == 'asc' ? ascend : descend

    return (
        <Table>
            <thead>
                <tr>
                    <th style={{ width: '120px' }}>
                        <SortableHeader
                            sort={sort}
                            setSort={setSort}
                            sortString="date"
                        >
                            Date
                        </SortableHeader>
                    </th>
                    <th>Description</th>
                    <th style={{ width: '140px', textAlign: 'right' }}>
                        <SortableHeader
                            sort={sort}
                            setSort={setSort}
                            sortString="debit"
                        >
                            Debit
                        </SortableHeader>
                    </th>
                    <th style={{ width: '140px', textAlign: 'right' }}>
                        <SortableHeader
                            sort={sort}
                            setSort={setSort}
                            sortString="credit"
                        >
                            Credit
                        </SortableHeader>
                    </th>
                    {user.permissions.includes('agentStatement.edit') && <th style={{ width: '40px' }}></th>}
                </tr>
            </thead>
            <tbody>
                {sortWith(
                    [sortFunc(x => x[sort.sort] ?? '')],
                    items.map(item => ({
                        ...item,
                        credit: item.type == 'credit' ? item.amount : 0,
                        debit: item.type == 'debit' ? item.amount : 0,
                    })),
                ).map(item => (
                    <RenderItem
                        item={item}
                        key={item.id}
                    >
                        {user.permissions.includes('agentStatement.edit') && onEdit && (
                            <td>
                                <IconButton onClick={() => onEdit(item)}>
                                    <EditRounded />
                                </IconButton>
                            </td>
                        )}
                    </RenderItem>
                ))}
                <tr style={{ fontSize: 16, height: '40px' }}>
                    <td></td>
                    <td style={{ paddingTop: 32, textAlign: 'right' }}>
                        <b>Total</b>
                    </td>
                    <td style={{ paddingTop: 32, textAlign: 'right' }}>
                        <b>
                            {toCurrency(
                                items
                                    .filter(item => item.type == 'debit')
                                    .reduce((acc, item) => acc + item.amount, 0),
                            )}
                        </b>
                    </td>
                    <td style={{ paddingTop: 32, textAlign: 'right' }}>
                        <b>
                            {toCurrency(
                                items
                                    .filter(item => item.type == 'credit')
                                    .reduce((acc, item) => acc + item.amount, 0),
                            )}
                        </b>
                    </td>
                    {user.permissions.includes('agentStatement.edit') && <td />}
                </tr>
                <tr style={{ fontSize: 20, height: '40px' }}>
                    <td></td>
                    <td style={{ paddingTop: 32, textAlign: 'right' }}>
                        <b>Current Balance</b>
                    </td>
                    <td></td>
                    <td style={{ fontSize: 16, paddingTop: 32, textAlign: 'right' }}>
                        <b>${balance}</b>
                    </td>
                    {user.permissions.includes('agentStatement.edit') && <td />}
                </tr>
            </tbody>
        </Table>
    )
}
