import { insertIf } from '@fastre/core/src/helperFunctions/array'
import { capitalize } from '@fastre/core/src/helperFunctions/string'
import { EmailOrUser } from '@fastre/core/src/schemas/automation'
import { Autocomplete, Box } from '@mui/joy'
import { useFindUserFromId, useUsersApi } from 'apiProviders'
import { propEq } from 'ramda'
import { useState } from 'react'
import isEmail from 'validator/es/lib/isEmail'

interface MultiEmailInputProps {
    value?: EmailOrUser[]
    onChange?: (value: EmailOrUser[]) => void
    allowedTypes?: ('email' | 'user' | 'special')[]
}

export default ({ value: inputValue, allowedTypes = ['email', 'user', 'special'], onChange }: MultiEmailInputProps) => {
    const usersApi = useUsersApi()
    const findUserFromId = useFindUserFromId()

    const [inputText, setInputText] = useState('')
    const value = inputValue ?? []

    return (
        <Box>
            <Autocomplete
                multiple
                value={value}
                onChange={(e, val) => onChange && onChange(val)}
                onInputChange={(e, value) => setInputText(value)}
                options={
                    [
                        ...insertIf(!value.some(propEq('type', 'special')), { type: 'special', value: 'agent' }),
                        ...usersApi.maybeData
                            .orSome([])
                            .filter(({ userId }) => !value.some(propEq('value', userId)))
                            .map(({ userId }) => ({
                                type: 'user',
                                value: userId,
                            })),
                        ...insertIf(isEmail(inputText), {
                            type: 'email',
                            value: inputText,
                        }),
                    ].filter(({ type }) => allowedTypes.includes(type as EmailOrUser['type'])) as EmailOrUser[]
                }
                groupBy={({ type }) => (type == 'special' ? 'From Listing' : type == 'email' ? 'Email' : 'Users')}
                getOptionLabel={option =>
                    option.type == 'email'
                        ? option.value
                        : option.type == 'special'
                          ? capitalize(option.value)
                          : findUserFromId(option.value)
                                .map(user => `${user.firstName} ${user.lastName}`)
                                .orSome('loading...')
                }
            />
        </Box>
    )
}
