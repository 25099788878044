import {
    DndContext,
    DragEndEvent,
    DragOverlay,
    DragStartEvent,
    MouseSensor,
    TouchSensor,
    closestCenter,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import {
    SortableContext,
    arrayMove,
    rectSortingStrategy,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { hasValue } from '@fastre/core/src/helperFunctions/array'
import { InternalLinkSchema } from '@fastre/core/src/schemas/link'
import { DragHandle, Edit } from '@mui/icons-material'
import { Box, Button, Grid, IconButton, Modal, Stack, Typography } from '@mui/joy'
import { useApi } from 'api'
import { useLinksApi } from 'apiProviders'
import Loading from 'components/Loading'
import { dontCloseOnBackgroundClick } from 'components/modal'
import { LinkGridContainer } from 'links/components'
import LinkBox from 'links/linkBox'
import { Maybe } from 'monet'
import { map, prop } from 'ramda'
import { HTMLAttributes, forwardRef, useCallback, useEffect, useState } from 'react'
import EditLink from 'settings/links/editLink'

export type LinkItemProps = HTMLAttributes<HTMLDivElement> & {
    id: string
    withOpacity?: boolean
    isDragging?: boolean
    link: InternalLinkSchema
}

const LinkItem = forwardRef<HTMLDivElement, LinkItemProps>(
    ({ id, link, withOpacity, isDragging, style, children, ...props }, ref) => {
        return (
            <Grid
                xs={1}
                sx={{ display: 'flex', justifyContent: 'center' }}
            >
                <Box
                    ref={ref}
                    sx={{
                        opacity: withOpacity ? '0.5' : '1',
                        transformOrigin: '50% 50%',
                        transform: isDragging ? 'scale(1.05)' : 'scale(1)',
                        width: 'fit-content',
                        maxWidth: '100%',
                        ...style,
                        position: 'relative',
                        ':hover': {
                            '> #edit': {
                                opacity: 0.7,
                            },
                        },
                    }}
                    {...props}
                >
                    <LinkBox
                        link={link}
                        editMode
                    />
                    {children}
                </Box>
            </Grid>
        )
    },
)

const SortableItem = ({ setEditingLink, ...props }) => {
    const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id })

    const style = {
        transform: CSS.Transform.toString(transform),
        transition: transition || undefined,
    }

    const linksApi = useLinksApi()

    const link = linksApi.maybeData
        .bind(links =>
            Maybe.fromUndefined(
                links
                    .map(prop('links'))
                    .flat()
                    .find(link => link.linkId === props.id),
            ),
        )
        .orUndefined()

    if (!link) {
        return <></>
    }

    return (
        <LinkItem
            ref={setNodeRef}
            link={link}
            style={style}
            withOpacity={isDragging}
            {...props}
            id={props.id}
        >
            <IconButton
                id="edit"
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    m: 1,
                    opacity: 0,
                    cursor: isDragging ? 'grabbing' : 'grab',
                }}
                {...listeners}
                {...attributes}
            >
                <DragHandle fontSize={'small' as any} />
            </IconButton>
            {setEditingLink && (
                <IconButton
                    id="edit"
                    sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        m: 1,
                        opacity: 0,
                    }}
                    onClick={() => setEditingLink(link)}
                >
                    <Edit fontSize={'small' as any} />
                </IconButton>
            )}
        </LinkItem>
    )
}

interface LinksDragListProps {
    links: InternalLinkSchema[]
    setEditingLink?: (link: InternalLinkSchema | undefined | null) => void
}

const LinksDragList = ({ links, setEditingLink }: LinksDragListProps) => {
    const api = useApi()
    const linksApi = useLinksApi()

    const [items, setItems] = useState(links.map(prop('linkId')))
    const [activeId, setActiveId] = useState<string | null>(null)
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))

    useEffect(() => {
        setItems(links.map(prop('linkId')))
    }, [links.map(prop('linkId')).join(',')])

    const handleDragStart = useCallback((event: DragStartEvent) => {
        setActiveId(event.active.id as any)
    }, [])

    const handleDragEnd = useCallback((event: DragEndEvent) => {
        const { active, over } = event

        if (active.id !== over?.id) {
            setItems(items => {
                const oldIndex = items.indexOf(active.id as any)
                const newIndex = items.indexOf(over!.id as any)

                const newArray = arrayMove(items, oldIndex, newIndex)

                const newOrders = newArray
                    .map((linkId, index) => {
                        const oldLink = items.findIndex(oldlinkId => linkId === oldlinkId)
                        if (oldLink != index) return { linkId, order: index }
                    })
                    .filter(hasValue)

                if (newOrders.length > 0) {
                    api.post(`/link/order`, newOrders).then(() => linksApi.refresh())
                }

                /*Promise.all(
                    newArray
                        .filter((linkId, index) => {
                            const oldLink = items.findIndex(oldlinkId => linkId === oldlinkId)
                            return oldLink != index
                        })
                        .map((linkId, index) => api.post(`/link/${linkId}/update`, { order: index })),
                ).then(() => linksApi.refresh())*/
                return newArray
            })
        }

        setActiveId(null)
    }, [])

    const handleDragCancel = useCallback(() => {
        setActiveId(null)
    }, [])

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
        >
            <SortableContext
                items={items}
                strategy={rectSortingStrategy}
            >
                <LinkGridContainer>
                    {items.map(id => (
                        <SortableItem
                            key={id}
                            id={id}
                            setEditingLink={setEditingLink}
                        />
                    ))}
                </LinkGridContainer>
            </SortableContext>
            <DragOverlay
                adjustScale
                style={{ transformOrigin: '0 0 ' }}
            >
                {linksApi.maybeData
                    .filter(_ => activeId != undefined)
                    .map(links => (
                        <LinkItem
                            id={activeId!}
                            link={
                                links
                                    .map(prop('links'))
                                    .flat()
                                    .find(link => link.linkId == activeId)!
                            }
                            isDragging
                        />
                    ))
                    .orUndefined()}
            </DragOverlay>
        </DndContext>
    )
}

export type CategoryItemProps = HTMLAttributes<HTMLDivElement> & {
    id: string
    withOpacity?: boolean
    isDragging?: boolean
    category: string
    setEditingLink?: (link: InternalLinkSchema | undefined | null) => void
}

const CategoryItem = forwardRef<HTMLDivElement, CategoryItemProps>(
    ({ id, category, withOpacity, isDragging, style, children, setEditingLink, ...props }, ref) => {
        const linksApi = useLinksApi()
        const links = linksApi.maybeData.orSome([]).find(link => link.category === category)?.links ?? []

        return (
            <Box
                ref={ref}
                sx={{
                    opacity: withOpacity ? '0.5' : '1',
                    transformOrigin: '50% 50%',
                    transform: isDragging ? 'scale(1.05)' : 'scale(1)',
                    width: '100%',
                    ...style,
                    position: 'relative',
                    ':hover': {
                        '> #edit': {
                            opacity: 0.7,
                        },
                    },
                }}
                {...props}
            >
                <Box
                    key={category}
                    sx={{ mb: 2 }}
                >
                    <Stack
                        direction="row"
                        gap={2}
                    >
                        <Box>{children}</Box>
                        <Typography
                            level="h3"
                            sx={{ mb: 2 }}
                        >
                            {category}
                        </Typography>
                    </Stack>
                    <LinksDragList
                        links={links}
                        setEditingLink={setEditingLink}
                    />
                </Box>
            </Box>
        )
    },
)

const SortableCategoryItem = ({ setEditingLink, ...props }) => {
    const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id })

    const style = {
        transform: CSS.Transform.toString(transform),
        transition: transition || undefined,
    }

    return (
        <Stack>
            <CategoryItem
                ref={setNodeRef}
                category={props.id}
                style={style}
                withOpacity={isDragging}
                {...props}
                id={props.id}
                setEditingLink={setEditingLink}
            >
                <IconButton
                    id="edit"
                    sx={{
                        cursor: isDragging ? 'grabbing' : 'grab',
                    }}
                    {...listeners}
                    {...attributes}
                >
                    <DragHandle fontSize={'small' as any} />
                </IconButton>
            </CategoryItem>
        </Stack>
    )
}

interface CategoriesDragListProps {
    categories: string[]
    setEditingLink: (link: InternalLinkSchema | undefined | null) => void
}

const CategoriesDragList = ({ categories, setEditingLink }: CategoriesDragListProps) => {
    const api = useApi()
    const linksApi = useLinksApi()

    const [items, setItems] = useState(categories)
    const [activeId, setActiveId] = useState<string | null>(null)
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))

    /*useEffect(() => {
        setItems(categories)
    }, [categories])*/

    const handleDragStart = useCallback((event: DragStartEvent) => {
        setActiveId(event.active.id as any)
    }, [])

    const handleDragEnd = useCallback((event: DragEndEvent) => {
        const { active, over } = event

        if (active.id !== over?.id) {
            setItems(items => {
                const oldIndex = items.indexOf(active.id as any)
                const newIndex = items.indexOf(over!.id as any)

                const newArray = arrayMove(items, oldIndex, newIndex)

                api.post(`/linkcategoryorder`, newArray)
                linksApi.refresh()

                return newArray
            })
        }

        setActiveId(null)
    }, [])

    const handleDragCancel = useCallback(() => {
        setActiveId(null)
    }, [])

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
        >
            <SortableContext
                items={items}
                strategy={verticalListSortingStrategy}
            >
                {items.map(id => (
                    <SortableCategoryItem
                        key={id}
                        id={id}
                        setEditingLink={setEditingLink}
                    />
                ))}
            </SortableContext>
            <DragOverlay
                adjustScale
                style={{ transformOrigin: '0 0 ' }}
            >
                {activeId && (
                    <CategoryItem
                        id={activeId}
                        category={activeId}
                        isDragging
                    />
                )}
            </DragOverlay>
        </DndContext>
    )
}

export default () => {
    const linksApi = useLinksApi()
    const maybeCategories = linksApi.maybeData.map(map(prop('category')))

    const [editingLink, setEditingLink] = useState<InternalLinkSchema | undefined | null>(null)

    return maybeCategories
        .map(categories => (
            <>
                <CategoriesDragList
                    categories={categories}
                    setEditingLink={setEditingLink}
                />

                <Box sx={{ mt: 4 }}>
                    <Button
                        variant="outlined"
                        onClick={() => setEditingLink(undefined)}
                    >
                        Create New Link
                    </Button>
                </Box>

                <Modal
                    open={editingLink !== null}
                    onClose={dontCloseOnBackgroundClick(() => setEditingLink(null))}
                >
                    <EditLink
                        link={editingLink ?? undefined}
                        close={() => setEditingLink(null)}
                    />
                </Modal>
            </>
        ))
        .orSome(<Loading />)
}
