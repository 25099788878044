import { InternalAutomationSchema } from '@fastre/core/src/schemas/automation'
import { DeleteRounded } from '@mui/icons-material'
import { Box, Button, DialogActions, DialogContent, DialogTitle, IconButton, Modal, ModalDialog, Stack } from '@mui/joy'
import { useApi } from 'api'
import { useAutomationsApi } from 'apiProviders'
import ButtonSheet from 'components/buttonSheet'
import Input from 'components/input'
import { dontCloseOnBackgroundClick } from 'components/modal'
import { assoc } from 'ramda'
import { Dispatch, SetStateAction, useState } from 'react'
import { v4 as uuid } from 'uuid'

interface AutomationListProps {
    selectedAutomation: InternalAutomationSchema | undefined
    setSelectedAutomation: Dispatch<SetStateAction<InternalAutomationSchema | undefined>>
}

export default ({ selectedAutomation, setSelectedAutomation }: AutomationListProps) => {
    const api = useApi()
    const automationsApi = useAutomationsApi()

    const [newAutomation, setNewAutomation] = useState<string | undefined>()
    const [confirmDelete, setConfirmDelete] = useState<string | undefined>()
    const [loadingDelete, setLoadingDelete] = useState<Record<string, boolean>>({})
    const [loading, setLoading] = useState(false)

    return (
        <>
            <Box sx={{ minWidth: '150px' }}>
                <Stack gap={2}>
                    {automationsApi.maybeData.map(automations =>
                        automations.map(automation => (
                            <Box
                                sx={{ display: 'flex', gap: 2 }}
                                key={automation.automationId}
                            >
                                <ButtonSheet
                                    onClick={() => setSelectedAutomation(automation)}
                                    selected={selectedAutomation?.automationId == automation.automationId}
                                >
                                    {automation.automationName}
                                </ButtonSheet>
                                <IconButton
                                    loading={loadingDelete[automation.automationId]}
                                    onClick={() => setConfirmDelete(automation.automationId)}
                                >
                                    <DeleteRounded />
                                </IconButton>
                            </Box>
                        )),
                    )}
                    <Button
                        variant="outlined"
                        onClick={() => setNewAutomation('')}
                        sx={{ mt: 2 }}
                    >
                        Add Automation
                    </Button>
                </Stack>
            </Box>
            <Modal
                open={confirmDelete != undefined}
                onClose={dontCloseOnBackgroundClick(() => setConfirmDelete(undefined))}
            >
                <ModalDialog>
                    <DialogTitle>Delete Automation</DialogTitle>
                    <DialogContent>Are you sure you want to delete this automation?</DialogContent>
                    <DialogActions>
                        <Button
                            loading={loadingDelete[confirmDelete!]}
                            color="danger"
                            startDecorator={<DeleteRounded />}
                            onClick={async () => {
                                setLoadingDelete(assoc(confirmDelete!, true))
                                await api.post(`automation/${confirmDelete}/delete`, {
                                    id: confirmDelete,
                                })
                                await automationsApi.refresh()
                                setLoadingDelete(assoc(confirmDelete!, false))
                                setConfirmDelete(undefined)
                            }}
                        >
                            Delete
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => setConfirmDelete(undefined)}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>

            <Modal
                open={newAutomation != undefined}
                onClose={dontCloseOnBackgroundClick(() => setNewAutomation(undefined))}
            >
                <ModalDialog>
                    <DialogTitle>New Automation Name</DialogTitle>
                    <DialogContent>
                        <Input
                            value={newAutomation}
                            onChange={setNewAutomation}
                            placeholder="Automation Name"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            loading={loading}
                            onClick={async () => {
                                await api.post('automation/addorupdate', {
                                    id: uuid(),
                                    automationName: newAutomation,
                                    triggerEquation: [],
                                    actions: [],
                                })
                                await automationsApi.refresh()

                                setNewAutomation(undefined)
                                setLoading(false)
                            }}
                        >
                            Create
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => setNewAutomation(undefined)}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>
        </>
    )
}
