import { Box } from '@mui/joy'
import { Route, Routes } from 'react-router'
import EditUser from '../users/editUser'
import BulkUploadLedger from './bulkUploadLedger'
import LedgersView from './ledgersView'

export default function ManageLedgers() {
    return (
        <Routes>
            <Route
                path="bulkupload"
                element={<BulkUploadLedger />}
            />
            <Route
                path=":userId"
                element={
                    <Box
                        sx={{
                            px: {
                                xs: 2,
                                sm: 4,
                            },
                        }}
                    >
                        <EditUser breadcrumbs={['Ledger Manager']} />
                    </Box>
                }
            />
            <Route
                index
                element={<LedgersView />}
            />
        </Routes>
    )
}
