import { FormControl, FormLabel, Autocomplete as JoyAutocomplete, AutocompleteProps as JoyAutocompleteProps } from '@mui/joy'
import { omit } from 'ramda'
import { SlotWrapper } from './input'

export interface AutocompleteProps<T, Multiple extends boolean | undefined, DisableClearable extends boolean | undefined, FreeSolo extends boolean | undefined> extends JoyAutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
    label?: string
}

export const Autocomplete = <T, Multiple extends boolean | undefined, DisableClearable extends boolean | undefined, FreeSolo extends boolean | undefined>(props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) => (
    <FormControl>
        <FormLabel>{props.label}</FormLabel>
        <JoyAutocomplete {...props} />
    </FormControl>
)

export const SlotAutocomplete = <T, Multiple extends boolean | undefined, DisableClearable extends boolean | undefined, FreeSolo extends boolean | undefined>({
    field,
    formState,
    ...props
}: Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'error'> & {
    field
    formState
}) => (
    <SlotWrapper
        field={field}
        formState={formState}
        {...props}
    >
        <JoyAutocomplete {...omit(['fieldState'], props)} />
    </SlotWrapper>
)
