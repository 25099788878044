import { CloseRounded, Search } from '@mui/icons-material'
import { Box, Grid, IconButton, Typography, useTheme } from '@mui/joy'
import { useLinksApi } from 'apiProviders'
import Loading from 'components/Loading'
import Input from 'components/input'
import SectionHead from 'components/sectionHead'
import { Fragment, useState } from 'react'
import { LinkGridContainer } from './components'
import LinkBox from './linkBox'

export default () => {
    const linksApi = useLinksApi()
    const theme = useTheme()
    //const groupsApi = useGroupsApi()

    const [groups, setGroups] = useState<string[]>([])
    const [search, setSearch] = useState('')

    return (
        <>
            <Box
                sx={{
                    px: {
                        xs: 2,
                        sm: 4,
                    },
                }}
            >
                <SectionHead
                    title="Links"
                    /*buttons={
                        <Autocomplete
                            multiple
                            placeholder={groups.length == 0 ? 'Filter by groups' : undefined}
                            value={groups}
                            onChange={(e, vals) => setGroups(vals)}
                            options={groupsApi.maybeData.map(map(prop('groupId'))).orSome([])}
                            getOptionLabel={option =>
                                groupsApi.maybeData
                                    .map(groups => groups.find(group => group.groupId === option)!)
                                    .map(prop('groupName'))
                                    .orSome('')
                            }
                            sx={{
                                width: '350px',
                            }}
                        />
                    }*/
                />
                <Input
                    placeholder="Search Links"
                    sx={{
                        mb: 4,
                    }}
                    value={search}
                    onChange={setSearch}
                    startDecorator={<Search />}
                    {...(search.length > 0 && {
                        // display the button and remove select indicator
                        // when user has selected a value
                        endDecorator: (
                            <IconButton
                                size="sm"
                                variant="plain"
                                color="neutral"
                                onMouseDown={event => {
                                    // don't open the popup when clicking on this button
                                    event.stopPropagation()
                                }}
                                onClick={() => {
                                    setSearch('')
                                    //action.current?.focusVisible();
                                }}
                            >
                                <CloseRounded />
                            </IconButton>
                        ),
                        indicator: null,
                    })}
                />
                {linksApi.maybeData
                    .map(allLinks => (
                        <Fragment key="1">
                            {allLinks.map(({ category, links }) => {
                                const filteredLinks = links.filter(
                                    link =>
                                        category.toLowerCase().includes(search.toLowerCase()) ||
                                        link.linkName.toLowerCase().includes(search.toLowerCase()),
                                )
                                if (filteredLinks.length === 0) return undefined

                                return (
                                    <Box
                                        key={category}
                                        sx={{ mb: 2 }}
                                    >
                                        <Typography
                                            level="h3"
                                            sx={{ mb: 2 }}
                                        >
                                            {category}
                                        </Typography>
                                        <LinkGridContainer>
                                            {filteredLinks
                                                //.filter(link => groups.length === 0 || link.availableToGroups.some(group => groups.includes(group)))
                                                .map(link => (
                                                    <Grid
                                                        xs={1}
                                                        key={link.linkName + link.linkUrl}
                                                    >
                                                        <LinkBox
                                                            link={link}
                                                            onClick={() => {
                                                                console.log('link', link)
                                                                window.open(link.linkUrl, '_blank')
                                                            }}
                                                            sx={{
                                                                ':hover': {
                                                                    background: theme.palette.background.level1,
                                                                },
                                                            }}
                                                        />
                                                    </Grid>
                                                ))}
                                        </LinkGridContainer>
                                    </Box>
                                )
                            })}
                        </Fragment>
                    ))
                    .orSome(<Loading />)}
            </Box>
        </>
    )
}
