import { InternalLinkSchema } from '@fastre/core/src/schemas/link'
import { Box, Sheet, Typography } from '@mui/joy'

const LinkBox = ({ link, editMode, sx, onClick }: { link: InternalLinkSchema } & any) => (
    <>
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <Sheet
                variant="outlined"
                sx={{
                    borderRadius: '4px',
                    height: '170px',
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    gap: 1,
                    alignItems: 'center',
                    cursor: editMode ? undefined : 'pointer',
                    width: '12rem',
                    ...sx,
                }}
                onClick={onClick}
            >
                <Box
                    sx={{
                        display: 'flex',
                        width: '4rem',
                        height: '4rem',
                        m: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <img
                        src={link.icon}
                        style={{
                            borderRadius: 2,
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                        }}
                    />
                </Box>
                <Typography
                    sx={{
                        textAlign: 'center',
                    }}
                >
                    {link.linkName}
                </Typography>
            </Sheet>
        </Box>
    </>
)

export default LinkBox
