import { useApi } from 'api'
import { useUserData } from 'auth'
import Loading from 'components/Loading'
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

const prod = import.meta.env.VITE_APP_STAGE == 'prod'

const docusignBaseUrl = `https://account${prod ? '' : '-d'}.docusign.com`

export const XeroConnectionResponse = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const state = searchParams.get('state')

    useEffect(() => {
        navigate(`/${state}/xero/auth?${searchParams.toString()}`)
    }, [])

    return <Loading />
}

export const LoggedInXeroConnectionResponse = () => {
    const navigate = useNavigate()
    const api = useApi()
    const { user, refreshUser } = useUserData()
    const [searchParams] = useSearchParams()

    const code = searchParams.get('code')

    const setToken = async () => {
        await new Promise(r => setTimeout(r, 100))
        await api.post('/xero/settoken', {
            code,
        })
        await refreshUser()
        navigate(`../../settings?tab=Integrations`, {
            replace: true,
            relative: 'path',
        })
    }

    useEffect(() => {
        setToken()
    }, [])

    return <Loading />
}
