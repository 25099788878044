import z from 'zod'
import { AddressSchema, State } from './generic'

const BankAccountSchema = z.object({
    name: z.string(),
    bank: z.string(),
    bsb: z.string(),
    accountNumber: z.string(),
})

export const OrgSettingsSchema = z
    .object({
        tradingName: z.string(),
        licenseeName: z.string(),
        licenceNumber: z.string(),
        licenceExpiry: z.string(),
        abn: z.string(),
        acn: z.string().optional(),
        address: AddressSchema.optional(),
        phone: z.string(),
        mobileNumber: z.string().optional(),
        fax: z.string().optional(),
        email: z.string().email(),
        website: z.string().optional(),
        headOfficeCommission: z.number().optional(),

        // Trust
        trustHolderName: z.string(),
        trustAccountName: z.string(),
        trustBank: z.string(),
        trustBsb: z.string(),
        trustAccountNumber: z.string(),

        // Marketing
        marketingDisclosure: z.string().optional(),
    })
    .and(
        z.union([
            z.object({
                marketingAccountSameAsTrust: z.literal(true),
            }),
            z.object({
                marketingAccountSameAsTrust: z.literal(false),
                marketingAccount: BankAccountSchema,
            }),
        ]),
    )

export type OrgSettingsSchema = z.infer<typeof OrgSettingsSchema>

export interface InternalOrgSchema {
    orgId: string
    orgName: string
    state: State
    logo?: string
    xeroConnected?: boolean
    fundDisbursementFormConfigured?: boolean
}
