import { SharedTodoSchema } from '@fastre/core/src/schemas/todo'
import { DeleteRounded } from '@mui/icons-material'
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Modal,
    ModalClose,
    ModalDialog,
    Typography,
} from '@mui/joy'
import { useApi } from 'api'
import { useSharedTodosApi } from 'apiProviders'
import { useState } from 'react'

interface Props {
    sharedTodo?: SharedTodoSchema
    onClose: () => void
}

export default ({ sharedTodo, onClose }: Props) => {
    const api = useApi()
    const sharedTodosApi = useSharedTodosApi()

    const [loading, setLoading] = useState(false)

    return (
        <Modal
            open={sharedTodo !== undefined}
            onClose={onClose}
        >
            <ModalDialog>
                <ModalClose sx={{ zIndex: 3 }} />
                <DialogTitle>Delete Shared Todo List</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this shared todo list?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        loading={loading}
                        color="danger"
                        startDecorator={<DeleteRounded />}
                        onClick={async () => {
                            setLoading(true)
                            await api.post(`/sharedtodo/${sharedTodo!.id}/delete`)
                            await sharedTodosApi.refresh()
                            onClose()
                        }}
                    >
                        Delete
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    )
}
