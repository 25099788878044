import { UserMonthlyLedgerEntrySchema } from '@fastre/core/src/schemas/ledger'
import { zodResolver } from '@hookform/resolvers/zod'
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    ModalDialog,
    Option,
    Select,
    Stack,
} from '@mui/joy'
import { SlotInput, SlotWrapper } from 'components/input'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

interface Props {
    row: UserMonthlyLedgerEntrySchema
    onClose: (updatedRow?: UserMonthlyLedgerEntrySchema) => Promise<void>
}

export const EditRowModal = ({ row, onClose }: Props) => {
    const { handleSubmit, setValue, formState, control } = useForm<UserMonthlyLedgerEntrySchema>({
        defaultValues: {
            ...row,
        },
        resolver: zodResolver(UserMonthlyLedgerEntrySchema),
    })

    if (Object.keys(formState.errors).length > 0) {
        console.log(formState.errors)
    }

    const [loading, setLoading] = useState(false)

    const onSubmit = async (data: UserMonthlyLedgerEntrySchema) => {
        setLoading(true)
        console.log(data)
        await onClose(data)
        setLoading(false)
    }

    return (
        <ModalDialog>
            <DialogTitle>Edit Montly Charge</DialogTitle>
            <DialogContent>
                <form noValidate>
                    <Stack spacing={2}>
                        <Controller
                            name="supplier"
                            control={control}
                            render={field => (
                                <SlotInput
                                    label="Supplier"
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            name="description"
                            control={control}
                            render={field => (
                                <SlotInput
                                    label="Description"
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            name="type"
                            control={control}
                            render={field => (
                                <SlotWrapper
                                    label="Type"
                                    {...field}
                                >
                                    <Select
                                        {...field.field}
                                        onChange={(e, val) => setValue('type', val as any)}
                                    >
                                        <Option value="credit">Credit</Option>
                                        <Option value="debit">Debit</Option>
                                    </Select>
                                </SlotWrapper>
                            )}
                        />
                        <Controller
                            name="amount"
                            control={control}
                            render={field => (
                                <SlotInput
                                    label="Amount"
                                    type="dollar"
                                    {...field}
                                />
                            )}
                        />
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions>
                <Button
                    loading={loading}
                    onClick={handleSubmit(onSubmit)}
                >
                    Save
                </Button>
                <Button
                    variant="outlined"
                    onClick={() => onClose()}
                >
                    Cancel
                </Button>
            </DialogActions>
        </ModalDialog>
    )
}
