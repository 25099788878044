import {
    AutomationTriggerSchema,
    getEnumsFromListingField,
    getFieldDescription,
    getTriggerDescription,
    getValidTriggersForFieldType,
    validAutomationListingFields,
} from '@fastre/core/src/schemas/automation/condition'
import { zodResolver } from '@hookform/resolvers/zod'
import { DeleteRounded } from '@mui/icons-material'
import {
    Autocomplete,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Modal,
    ModalClose,
    ModalDialog,
    Option,
    Select,
} from '@mui/joy'
import { SlotInput, SlotWrapper } from 'components/input'
import { dontCloseOnBackgroundClick } from 'components/modal'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'

interface AddConditionModalProps {
    //open: boolean
    editCondition: AutomationTriggerSchema | undefined | null
    close: () => void
    onSet: (condition: AutomationTriggerSchema) => void
    onDelete: () => void
}

export default ({ editCondition, close, onSet, onDelete }: AddConditionModalProps) => {
    const hookForm = useForm<AutomationTriggerSchema>({
        defaultValues: {},
        resolver: zodResolver(AutomationTriggerSchema),
    })

    useEffect(() => {
        hookForm.reset(editCondition ?? { triggerFieldName: undefined }, {
            keepValues: false,
            keepDefaultValues: false,
        })
    }, [JSON.stringify(editCondition ?? {})])

    const { control, setValue, handleSubmit, watch } = hookForm

    const triggerType = watch('triggerType')
    const triggerFieldName = watch('triggerFieldName')

    const triggerFieldType = triggerFieldName
        ? validAutomationListingFields.find(x => x[0] == triggerFieldName)![2]
        : undefined

    const validTriggerTypes = triggerFieldType ? getValidTriggersForFieldType(triggerFieldType) : []

    const onSubmit = (data: AutomationTriggerSchema) => {
        console.log('onSubmit', data)
        onSet(data)
        close()
    }

    return (
        <Modal
            open={editCondition !== null}
            onClose={dontCloseOnBackgroundClick(close)}
        >
            <form
                noValidate
                onSubmit={handleSubmit(onSubmit)}
            >
                <ModalDialog>
                    <ModalClose />
                    <DialogTitle>Add Condition</DialogTitle>
                    <DialogContent>
                        <Controller
                            name="triggerFieldName"
                            control={control}
                            render={field => (
                                <SlotWrapper
                                    {...field}
                                    label="Value"
                                >
                                    <Autocomplete
                                        {...field.field}
                                        options={validAutomationListingFields.map(([field]) => field)}
                                        getOptionLabel={getFieldDescription as any}
                                        onChange={(e, value) => field.field.onChange(value as any)}
                                    />
                                </SlotWrapper>
                            )}
                        />
                        <Controller
                            name="triggerType"
                            control={control}
                            render={field => (
                                <SlotWrapper
                                    {...field}
                                    label="Type"
                                >
                                    <Select
                                        {...field.field}
                                        onChange={(e, value) => field.field.onChange(value)}
                                        disabled={!triggerFieldName}
                                    >
                                        {validTriggerTypes.map(type => (
                                            <Option
                                                key={type}
                                                value={type}
                                            >
                                                {getTriggerDescription(type)}
                                            </Option>
                                        ))}
                                    </Select>
                                </SlotWrapper>
                            )}
                        />
                        {triggerType != 'updated' && (
                            <>
                                {triggerType == 'upcomingDate' && (
                                    <Controller
                                        name="daysBefore"
                                        control={control}
                                        render={field => (
                                            <SlotInput
                                                {...field}
                                                label="Days Before"
                                                type="number"
                                            />
                                        )}
                                    />
                                )}
                                {['string', 'number'].includes(triggerFieldType!) && (
                                    <Controller
                                        name="triggerFieldValue"
                                        control={control}
                                        render={field => (
                                            <SlotInput
                                                {...field}
                                                label="Value"
                                                type={triggerFieldType == 'number' ? 'number' : 'text'}
                                            />
                                        )}
                                    />
                                )}
                                {triggerFieldType == 'enum' && (
                                    <Controller
                                        name="triggerFieldValue"
                                        control={control}
                                        render={field => (
                                            <SlotWrapper
                                                {...field}
                                                label="Type"
                                            >
                                                <Select
                                                    {...(field.field as any)}
                                                    multiple
                                                    onChange={(e, value) => field.field.onChange(value)}
                                                >
                                                    {getEnumsFromListingField(triggerFieldName as any).map(
                                                        en => (
                                                            <Option
                                                                key={en}
                                                                value={en}
                                                            >
                                                                {en}
                                                            </Option>
                                                        ),
                                                    )}
                                                </Select>
                                            </SlotWrapper>
                                        )}
                                    />
                                )}
                            </>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit">Add</Button>
                        <Button
                            variant="outlined"
                            onClick={close}
                        >
                            Cancel
                        </Button>
                        {editCondition && (
                            <Button
                                variant="outlined"
                                onClick={onDelete}
                                color="danger"
                                startDecorator={<DeleteRounded />}
                                sx={{ mr: 'auto' }}
                            >
                                Delete
                            </Button>
                        )}
                    </DialogActions>
                </ModalDialog>
            </form>
        </Modal>
    )
}
