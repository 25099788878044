import MSGReader from '@kenjiuno/msgreader'
import { Box, CircularProgress, Link } from '@mui/joy'
import axios from 'axios'
import { useMaybeState } from 'helperFunctions/react'
import PostalMime, { Email } from 'postal-mime'
import { prop } from 'ramda'
import { useEffect } from 'react'

const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
    let binary = ''
    const bytes = new Uint8Array(buffer)
    const len = bytes.byteLength
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i])
    }
    return window.btoa(binary)
}

export default ({ name, url }: { name; url: string }) => {
    const [maybeEmail, setEmail] = useMaybeState<Partial<Email>>()

    useEffect(() => {
        const getEmail = async () => {
            /*const url = await api
                .post(`/listing/${listingType}/${listing.listingId}/getfile`, {
                    filename: fileKey,
                })
                .then(prop('data'))*/

            if (name.endsWith('.msg')) {
                const arrayBuffer = await axios.get(url, { responseType: 'arraybuffer' }).then(prop('data'))
                const msgReader = new MSGReader(arrayBuffer)
                const msgData = msgReader.getFileData()

                console.log('msgData', msgData)

                setEmail({
                    html: `
                    <h2>${msgData.subject}</h2>
                    <p><strong>From:</strong> ${msgData.senderEmail}</p>
                    <p><strong>To:</strong> ${msgData.inetAcctName}</p>
                    <p><strong>Date:</strong> ${new Date(msgData.messageDeliveryTime ?? msgData.creationTime!).toUTCString()}</p>
                    <hr>
                    <div>${msgData.body}</div>
                `,
                    attachments:
                        (msgData.attachments?.map(attachment => ({
                            filename: attachment.fileName!,
                            content: msgReader.getAttachment(attachment).content,
                            mimeType: attachment.attachMimeTag,
                            disposition: null, // todo check
                        })) as any[]) ?? ([] as any),
                })
            } else {
                const txt = (await axios.get(url, { responseType: 'text' }).then(prop('data'))) as string

                const email = await new PostalMime().parse(txt)

                email.attachments = email.attachments?.filter(attachment => {
                    if (attachment.contentId) {
                        const cid = attachment.contentId.replace('<', '').replace('>', '')
                        const base64String = arrayBufferToBase64(attachment.content)

                        const index = email.html?.indexOf(`cid:${cid}`)

                        console.log('index', index)

                        email.html = email.html?.replaceAll(
                            `cid:${cid}`,
                            `data:${attachment.mimeType};base64,${base64String}`,
                        )

                        return index == -1
                    }
                })

                setEmail(email)
            }
        }
        getEmail()
    }, [])

    return maybeEmail
        .map(email => (
            <>
                <div
                    dangerouslySetInnerHTML={{
                        __html: email.html!,
                    }}
                />
                <Box sx={{ mt: 2 }}>
                    {email.attachments?.map(attachment => (
                        <Box key={attachment.filename}>
                            <Link
                                onClick={() => {
                                    const url = URL.createObjectURL(
                                        new Blob([attachment.content], {
                                            type: attachment.mimeType,
                                        }),
                                    )
                                    window.open(url, '_blank')
                                }}
                            >
                                {attachment.filename}
                            </Link>
                        </Box>
                    ))}
                </Box>
            </>
        ))
        .orSome(<CircularProgress />)
}
