import { formatAddress } from '@fastre/core/src/schemas/generic'
import { InternalListingSchema } from '@fastre/core/src/schemas/listing'
import { Box, useTheme } from '@mui/joy'
import { useApi } from 'api'
import { useUsersApi } from 'apiProviders'
import React, {
    forwardRef,
    ReactNode,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react'
import { createPortal } from 'react-dom'
import { createEditor, Editor, Range, Text, Transforms } from 'slate'
import { withHistory } from 'slate-history'
import { Editable, ReactEditor, Slate, useFocused, useSelected, withReact } from 'slate-react'
import { useDebounce } from 'use-debounce'

const CustomElements = ['listingAddress', 'sendingUser', 'agent', 'vendorFullName'] as const
type CustomElement = (typeof CustomElements)[number]

export const renderNotification = (listing: InternalListingSchema) => node => {
    if (Text.isText(node)) {
        let string = node.text
        return string
    }

    switch (node.type) {
        case 'custom':
            return formatAddress(listing.listingAddress)
        case 'paragraph':
            return (
                <>
                    {node.children.map(renderNotification(listing))}
                    <br />
                </>
            )
        default:
            return node.children.map(renderNotification(listing))
    }
}

export const Portal = ({ children }: { children?: ReactNode }) => {
    return typeof document === 'object' ? createPortal(children, document.body) : null
}

export type CustomText = {
    bold?: boolean
    italic?: boolean
    code?: boolean
    text: string
}

export type MentionElement = {
    type: 'custom'
    character: string
    children: CustomText[]
}

const NotificationInput = forwardRef(
    (
        { /*editor,*/ initialContents, allowedCustomElements = ['listingAddress'], ...textAreaProps }: any,
        ref,
    ) => {
        const api = useApi()
        const portalRef = useRef<HTMLDivElement | null>()
        const [target, setTarget] = useState<Range | undefined | null>()
        const [index, setIndex] = useState(0)
        const [search, setSearch] = useState('')
        const renderElement = useCallback(props => <Element {...props} />, [])
        const renderLeaf = useCallback(props => <Leaf {...props} />, [])

        const [editor] = useState(() => withMentions(withReact(withHistory(createEditor()))))

        const usersApi = useUsersApi()

        const [listingSearchText, setListingSearchText] = useState('')
        const [debouncedListingSearchText] = useDebounce(listingSearchText, 200)

        const chars = usersApi.maybeData
            .orSome([])
            .filter(
                c =>
                    c.firstName.toLowerCase().startsWith(search.toLowerCase()) ||
                    c.lastName.toLowerCase().includes(search.toLocaleLowerCase()),
            )
            .slice(0, 10)
            .map(({ userId, firstName, lastName }) => ({
                userId,
                firstName,
                lastName,
                name: `${firstName} ${lastName}`,
            }))

        const onKeyDown = useCallback(
            e => {
                if (target) {
                    switch (e.key) {
                        case 'ArrowDown':
                            e.preventDefault()
                            const prevIndex = index >= allowedCustomElements.length - 1 ? 0 : index + 1
                            setIndex(prevIndex)
                            break
                        case 'ArrowUp':
                            e.preventDefault()
                            const nextIndex = index <= 0 ? allowedCustomElements.length - 1 : index - 1
                            setIndex(nextIndex)
                            break
                        case 'Tab':
                        case 'Enter':
                            e.preventDefault()
                            Transforms.select(editor, target)
                            insertMention(editor, 'custom', allowedCustomElements[index])
                            setTarget(null)
                            break
                        case 'Escape':
                            e.preventDefault()
                            setTarget(null)
                            break
                    }
                }

                //console.log('e', e)
                if (e.key == 'Enter') {
                    e.preventDefault()
                }
            },
            [chars, editor, index, target],
        )

        useEffect(() => {
            if (target) {
                const el = portalRef.current!
                const domRange = ReactEditor.toDOMRange(editor, target)
                const portalRect = el.getBoundingClientRect()
                const rect = domRange.getBoundingClientRect()
                el.style.top = `${rect.top + window.scrollY - portalRect.height - 10}px`
                el.style.left = `${rect.left + window.scrollX}px`
            }
        }, [chars.length, editor, index, search, target])

        useImperativeHandle(ref, () => ({
            getContents: () => editor.children,
        }))

        return (
            <Slate
                editor={editor}
                initialValue={
                    initialContents ?? [
                        {
                            type: 'paragraph',
                            children: [
                                {
                                    text: '',
                                },
                            ],
                        } as any,
                    ]
                }
                onChange={x => {
                    const { selection } = editor

                    if (selection && Range.isCollapsed(selection)) {
                        const [start] = Range.edges(selection)
                        const wordBefore = Editor.before(editor, start, {
                            unit: 'word',
                        })
                        const before = wordBefore && Editor.before(editor, wordBefore)
                        const beforeRange = before && Editor.range(editor, before, start)
                        const beforeText = beforeRange && Editor.string(editor, beforeRange)
                        const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/)
                        const beforeMatchHash = beforeText && beforeText.match(/^#(\w+)$/)

                        const after = Editor.after(editor, start)
                        const afterRange = Editor.range(editor, start, after)
                        const afterText = Editor.string(editor, afterRange)
                        const afterMatch = afterText.match(/^(\s|$)/)

                        if (beforeMatch && afterMatch) {
                            setTarget(beforeRange)
                            setSearch(beforeMatch[1])
                            setIndex(0)
                            return
                        }
                        if (beforeMatchHash && afterMatch) {
                            setTarget(beforeRange)
                            setListingSearchText(beforeMatchHash[1])
                            setIndex(0)
                            return
                        }
                    }

                    setTarget(null)
                }}
            >
                <Editable
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    onKeyDown={onKeyDown}
                    //placeholder="Enter some text..."
                    //disableDefaultStyles
                    style={{
                        outline: 'none',
                    }}
                    {...textAreaProps}
                />
                {target && (
                    <Portal>
                        <Box
                            ref={portalRef as any}
                            sx={{
                                top: '-9999px',
                                left: '-9999px',
                                position: 'absolute',
                                zIndex: 9999,
                                padding: '3px',
                                backgroundColor: 'background.level3',
                                borderRadius: '4px',
                                boxShadow: '0 1px 5px rgba(0,0,0,.2)',
                            }}
                            data-cy="mentions-portal"
                        >
                            {allowedCustomElements.map((customItem, i) => (
                                <Box
                                    key={customItem}
                                    onClick={() => {
                                        Transforms.select(editor, target)
                                        insertMention(editor, 'custom', customItem)
                                        setTarget(null)
                                    }}
                                    sx={{
                                        padding: '1px 3px',
                                        borderRadius: '3px',
                                        backgroundColor: i === index ? 'focusVisible' : 'transparent',
                                    }}
                                >
                                    {customItem}
                                </Box>
                            ))}
                        </Box>
                    </Portal>
                )}
            </Slate>
        )
    },
)

const isMention = el => el.type == 'custom'

export const withMentions = editor => {
    const { isInline, isVoid, markableVoid } = editor

    editor.isInline = element => {
        return isMention(element) ? true : isInline(element)
    }

    editor.isVoid = element => {
        return isMention(element) ? true : isVoid(element)
    }

    editor.markableVoid = element => {
        return isMention(element) || markableVoid(element)
    }

    return editor
}

const insertMention = (editor, type: 'custom', character) => {
    const mention: MentionElement = {
        type,
        character,
        children: [{ text: '' }],
    }
    Transforms.insertNodes(editor, mention)
    Transforms.move(editor)
}

// Borrow Leaf renderer from the Rich Text example.
// In a real project you would get this via `withRichText(editor)` or similar.
export const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.code) {
        children = <code>{children}</code>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }

    return <span {...attributes}>{children}</span>
}

const Element = props => {
    const { attributes, children, element } = props
    switch (element.type) {
        case 'custom':
            return <Mention {...props} />
        default:
            return <p {...attributes}>{children}</p>
    }
}

export const Mention = ({ attributes, children, element }) => {
    const theme = useTheme()
    const selected = useSelected()
    const focused = useFocused()
    const style: React.CSSProperties = {
        padding: '3px 3px 2px',
        margin: '0 1px',
        verticalAlign: 'baseline',
        display: 'inline-block',
        borderRadius: '4px',
        //backgroundColor: '#eee',
        backgroundColor: 'background.level2',
        fontSize: '0.9em',
        boxShadow: selected && focused ? `0 0 0 2px ${theme.vars.palette.primary[500]}` : 'none',
    }
    // See if our empty text child has any styling marks applied and apply those
    if (element.children[0].bold) {
        style.fontWeight = 'bold'
    }
    if (element.children[0].italic) {
        style.fontStyle = 'italic'
    }

    console.log('element', element)
    return (
        <Box
            component="span"
            {...attributes}
            contentEditable={false}
            //data-cy={`mention-${element.character.replace(' ', '-')}`}
            sx={style}
        >
            @{element.character}
            {children}
        </Box>
    )
}

export default NotificationInput
