import { getContractCommission } from '@fastre/core/src/schemas/contract'
import { TypesenseListingSchema } from '@fastre/core/src/schemas/typesense'
import { AttachMoney, ChecklistRtlRounded, FeedRounded, StarRounded } from '@mui/icons-material'
import { Box, Divider, Grid, Sheet, Stack, Typography } from '@mui/joy'
import { useFindUserFromId } from 'apiProviders'
import { useUserData } from 'auth'
import { isSameMonth } from 'date-fns'
import { groupBy, prop, sortBy } from 'ramda'
import { Fragment } from 'react/jsx-runtime'

const red = '#8B0000'
const blue = '#002147'

const DataRow = ({ title, value, Icon }) => (
    <Stack
        direction="row"
        spacing={2}
        sx={{
            justifyContent: 'space-between',
        }}
    >
        <Stack
            direction="row"
            spacing={2}
        >
            <Icon />
            <Typography
                level="title-lg"
                sx={{ color: 'white' }}
            >
                {title}:
            </Typography>
        </Stack>
        <Typography
            level="title-lg"
            sx={{ color: 'white' }}
        >
            {value}
        </Typography>
    </Stack>
)

const sameMonthFilter = (date?: string | number) => date && isSameMonth(date, new Date())

export default ({ listings }: { listings: TypesenseListingSchema[] }) => {
    const { user } = useUserData()
    const findUserFromId = useFindUserFromId()

    const groupedByAgent = Object.entries(groupBy(prop('agentUserId'), listings)).map(
        ([agentId, agentListings]) => {
            const contracts = agentListings.filter(x => sameMonthFilter(x.contract?.contractDate))
            const sold = contracts.filter(x => x.statusLog?.unconditional != undefined)

            return {
                agentId,
                sold: sold.length,
                contracts: contracts.length,
                commission: sold.reduce((acc, x) => acc + getContractCommission(x), 0),
            }
        },
    )

    return (
        <Box>
            <Grid
                container
                columns={{
                    xs: 1,
                    md: 3,
                }}
                spacing={2}
                sx={{
                    mt: 4,
                }}
            >
                {sortBy(x => -x.commission, groupedByAgent)
                    .filter(x => x.commission > 0)
                    .slice(0, 3)
                    .map((data, index) => (
                        <Grid
                            key={data.agentId}
                            xs={1}
                        >
                            <Sheet
                                variant="solid"
                                sx={{
                                    borderRadius: 'sm',
                                    p: 2,
                                    background: [blue, red][index],
                                    position: 'relative',
                                    ...(data.agentId == user.userId && {
                                        boxShadow: theme => `0px 0px 0px 3px gold`,
                                    }),
                                }}
                            >
                                {data.agentId == user.userId && (
                                    <StarRounded
                                        sx={{
                                            fill: 'gold',
                                            position: 'absolute',
                                            top: 4,
                                            right: 4,
                                            width: 32,
                                            height: 32,
                                        }}
                                    />
                                )}
                                <Stack gap={1}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: 2,
                                            alignItems: 'flex-end',
                                        }}
                                    >
                                        <Typography
                                            level="h2"
                                            sx={{ color: 'white' }}
                                        >
                                            #{index + 1}
                                        </Typography>
                                        <Typography
                                            level="h3"
                                            sx={{ color: 'white' }}
                                        >
                                            {findUserFromId(data.agentId)
                                                .map(user => `${user.firstName} ${user.lastName}`)
                                                .orUndefined()}
                                        </Typography>
                                    </Box>

                                    {[
                                        ['Sold', data.sold, ChecklistRtlRounded],
                                        ['Contracts', data.contracts, FeedRounded],
                                        [
                                            'Comm',
                                            '$' +
                                                data.commission.toLocaleString('en-US', {
                                                    maximumFractionDigits: 0,
                                                }),
                                            AttachMoney,
                                        ],
                                    ].map(([title, value, Icon]) => (
                                        <Fragment key={title as any}>
                                            <Divider />
                                            <DataRow
                                                title={title}
                                                value={value}
                                                Icon={Icon}
                                            />
                                        </Fragment>
                                    ))}
                                </Stack>
                            </Sheet>
                        </Grid>
                    ))}
            </Grid>
        </Box>
    )
}
