import { Box, DialogActions, DialogContent, DialogTitle, Modal, ModalClose, ModalDialog, Slider } from '@mui/joy'
import Button from '@mui/joy/Button'
import { dontCloseOnBackgroundClick } from 'components/modal'
import { useRef, useState } from 'react'
import AvatarEditor from 'react-avatar-editor'

interface EditAvatarModalProps {
    image: string | null
    onClose: () => void
    onSave: (base64String: string) => void
}

const zoomStep = 0.2

export default ({ image, onClose, onSave }: EditAvatarModalProps) => {
    const editorRef = useRef<any>()

    const [loading, setLoading] = useState(false)
    const [zoomValue, setZoomValue] = useState(1)

    return (
        <Modal
            open={image != null}
            onClose={dontCloseOnBackgroundClick(() => onClose())}
        >
            <ModalDialog>
                <DialogTitle>
                    <ModalClose />
                    Edit Avatar
                </DialogTitle>
                <DialogContent
                    onWheel={event => {
                        if (event.deltaY < 0) {
                            setZoomValue(Math.min(10, zoomValue + zoomStep))
                        } else {
                            setZoomValue(Math.max(1, zoomValue - zoomStep))
                        }
                    }}
                    sx={{ overflow: 'hidden' }}
                >
                    <AvatarEditor
                        ref={editorRef}
                        style={{ width: '100%', height: '250px' }}
                        width={250}
                        height={250}
                        image={image ?? ''}
                        borderRadius={250 / 2}
                        scale={zoomValue}
                    />
                    <Box sx={{ px: 1 }}>
                        <Slider
                            value={zoomValue}
                            onChange={(_, value) => setZoomValue(value as number)}
                            min={1}
                            max={4}
                            step={zoomStep}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        loading={loading}
                        onClick={async () => {
                            setLoading(true)
                            await onSave((editorRef.current as any).getImageScaledToCanvas().toDataURL())
                            onClose()
                        }}
                    >
                        Save
                    </Button>
                    <Button
                        onClick={onClose}
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    )
}
