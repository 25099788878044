import { UserMonthlyLedgerEntrySchema } from '@fastre/core/src/schemas/ledger'
import { FrontendUserSchema } from '@fastre/core/src/schemas/userMembership'
import { DeleteRounded, EditRounded } from '@mui/icons-material'
import { Button, IconButton, Modal, Table } from '@mui/joy'
import { useApi } from 'api'
import { useUserData } from 'auth'
import Loading from 'components/Loading'
import { dontCloseOnBackgroundClick } from 'components/modal'
import { useOrgId } from 'helpers'
import { assoc, prop } from 'ramda'
import { useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { EditRowModal } from './editRowModal'

export default ({ user }: { user: FrontendUserSchema }) => {
    const orgId = useOrgId()!
    const api = useApi()
    const { user: userData } = useUserData()

    const [rows, setRows] = useState<UserMonthlyLedgerEntrySchema[]>()
    const [editRow, setEditRow] = useState<UserMonthlyLedgerEntrySchema>()
    const [deleteLoading, setDeleteLoading] = useState<Record<string, boolean>>({})

    const refreshRows = async () => {
        api.get(`/user/${user.userId}/monthlycharges`).then(({ data }) => {
            setRows(data.ledger)
        })
    }

    useEffect(() => {
        refreshRows()
    }, [])

    if (!rows) return <Loading />

    return (
        <>
            <Table>
                <thead>
                    <tr>
                        <th>Supplier</th>
                        <th>Description</th>
                        <th>Debit</th>
                        <th>Credit</th>
                        {userData.permissions.includes('monthlyCharges.edit') && <th />}
                    </tr>
                </thead>
                <tbody>
                    {rows.map(row => (
                        <tr key={row.id}>
                            <td>{row.supplier}</td>
                            <td>{row.description}</td>
                            <td>{row.type == 'debit' ? '$' + row.amount : ''}</td>
                            <td>{row.type == 'credit' ? '$' + row.amount : ''}</td>
                            {userData.permissions.includes('monthlyCharges.edit') && (
                                <td>
                                    <IconButton
                                        size="sm"
                                        onClick={() => setEditRow(row)}
                                    >
                                        <EditRounded />
                                    </IconButton>
                                    <IconButton
                                        size="sm"
                                        sx={{ ml: 1 }}
                                        loading={deleteLoading[row.id]}
                                        onClick={async () => {
                                            setDeleteLoading(assoc(row.id, true))
                                            const newRows = rows.filter(r => r.id !== row.id)
                                            await api.post(`/user/${user.userId}/monthlycharges`, {
                                                ledger: newRows,
                                            })
                                            setDeleteLoading(assoc(row.id, false))
                                            setRows(newRows)
                                        }}
                                    >
                                        <DeleteRounded />
                                    </IconButton>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </Table>
            {userData.permissions.includes('monthlyCharges.edit') && (
                <Button
                    variant="soft"
                    sx={{ mt: 2 }}
                    onClick={() =>
                        setEditRow({
                            id: uuid(),
                            orgId,
                            userId: user.userId,
                        } as any)
                    }
                >
                    Add Row
                </Button>
            )}
            <Modal
                open={!!editRow}
                onClose={dontCloseOnBackgroundClick(() => setEditRow(undefined))}
            >
                <EditRowModal
                    row={editRow!}
                    onClose={async updatedRow => {
                        if (updatedRow) {
                            const isNewRow = !rows.find(row => row.id === updatedRow.id)
                            const newRows = isNewRow
                                ? [...rows, updatedRow]
                                : rows.map(row => (row.id === updatedRow.id ? updatedRow : row))
                            await api
                                .post(`/user/${user.userId}/monthlycharges`, { ledger: newRows })
                                .then(prop('data'))
                            setRows(newRows)
                        }
                        setEditRow(undefined)
                    }}
                />
            </Modal>
        </>
    )
}
