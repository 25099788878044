import { FormType, ReiForm } from '@fastre/core/src/schemas/rei'
import {
    Box,
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle,
    Modal,
    ModalClose,
    ModalDialog,
    Typography,
} from '@mui/joy'
import { useListingFilesApi, useListingType } from 'apiProviders'
import Loading from 'components/Loading'
import { dontCloseOnBackgroundClick } from 'components/modal'
import { useShowSnack } from 'components/snackbar'
import { useOrgId } from 'helpers'
import { useListingContext } from 'listings/listingProvider'
import { useState } from 'react'

interface Props {
    formType: FormType
    open: boolean
    onClose: () => void
    onAccept: (s3Keys: string[]) => Promise<void>
}

export default ({ formType, onAccept, open, ...props }: Props) => {
    const listingType = useListingType()
    const { listing, setListing } = useListingContext()
    const showSnack = useShowSnack()
    const orgId = useOrgId()
    const filesApi = useListingFilesApi({ excludeFolders: true })

    const onClose = () => {
        props.onClose()
        sets3Keys([])
    }

    const [s3Keys, sets3Keys] = useState<string[]>([])
    const [tabValue, setTabValue] = useState<'attachment' | 'load'>('attachment')
    const [loading, setLoading] = useState(false)

    const locationRoot = `${orgId}/${listingType}/${listing.listingId}/files/`

    const form = listing[formType] as ReiForm | undefined

    return (
        <Modal
            open={open}
            onClose={dontCloseOnBackgroundClick(onClose)}
        >
            <ModalDialog
                sx={{
                    minWidth: 500,
                    minHeight: 450,
                }}
            >
                <ModalClose />
                <DialogTitle>Attach Form</DialogTitle>
                <DialogContent>
                    {/*<Input
                        label="Attachment Name"
                        value={filename}
                        onChange={setFilename}
                        formControlProps={{
                            sx: {
                                mb: 2,
                                mt: 1,
                            },
                        }}
                    />
                    {/*<Tabs
                        value={tabValue}
                        onChange={(e, value) => setTabValue(value as any)}
                    >
                        <TabList>
                            <Tab value="attachment">From Attachments</Tab>
                            <Tab value="load">Upload</Tab>
                        </TabList>
                        <TabPanel value="attachment">*/}
                    <Box sx={{ px: 1, mt: 1 }}>
                        {filesApi?.data?.length == 0 && (
                            <Typography key="1">No PDF files uploaded to listing</Typography>
                        )}
                        {filesApi.data?.map(file => (
                            <Checkbox
                                key={file.Key}
                                checked={
                                    s3Keys.includes(file.Key) ||
                                    form?.attachments?.some(({ s3Key }) => s3Key == file.Key)
                                }
                                onChange={e => {
                                    if (e.target.checked) {
                                        sets3Keys([...s3Keys, file.Key])
                                    } else {
                                        sets3Keys(s3Keys.filter(x => x != file.Key))
                                    }
                                }}
                                label={file.Key.replace(locationRoot, '')}
                                disabled={form?.attachments?.some(({ s3Key }) => s3Key == file.Key)}
                            />
                        ))}
                        {filesApi.data == undefined && <Loading />}
                    </Box>
                    {/*</TabPanel>
                        <TabPanel value="load">
                            <DropZoneInner
                                options={{
                                    accept: {
                                        'application/pdf': ['.pdf'],
                                    },
                                    maxFiles: 1,
                                }}
                                onUpload={files => {
                                    setFile(files[0])
                                }}
                            />
                            {file && <Typography>Selected file: {file.name}</Typography>}
                        </TabPanel>
                    </Tabs>*/}
                </DialogContent>
                <DialogActions>
                    <Button
                        loading={loading}
                        onClick={async () => {
                            try {
                                setLoading(true)
                                if (tabValue == 'attachment') {
                                    await onAccept(s3Keys)
                                    /*const updatedListing = await api
                                        .post(
                                            `/listing/${listingType}/${listing.listingId}/forms/${formType}/attachfile`,
                                            {
                                                filename,
                                                s3Key: selectedFile,
                                            },
                                        )
                                        .then(prop('data'))
                                    setListing(updatedListing)*/
                                } else {
                                }
                                showSnack('File attached', 'success')
                                onClose()
                            } catch (error) {
                                showSnack('Error attaching file', 'danger')
                                console.error(error)
                            } finally {
                                setLoading(false)
                            }
                        }}
                        //disabled={s3Keys.length == 0}
                    >
                        Upload
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    )
}
