import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded'
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded'
import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import CircularProgress from '@mui/joy/CircularProgress'
import FormControl from '@mui/joy/FormControl'
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel'
import GlobalStyles from '@mui/joy/GlobalStyles'
import IconButton, { IconButtonProps } from '@mui/joy/IconButton'
import Input, { InputProps } from '@mui/joy/Input'
import Stack from '@mui/joy/Stack'
import Typography from '@mui/joy/Typography'
import { useColorScheme } from '@mui/joy/styles'
import { useMaybeState } from 'helperFunctions/react'
import { forwardRef, useEffect, useState } from 'react'
import OtpInput from 'react-otp-input'
import { useLocation } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import isEmail from 'validator/es/lib/isEmail'
import isMobilePhone from 'validator/es/lib/isMobilePhone'
import logo from './images/logo.png'

function ColorSchemeToggle({ onClick, ...props }: IconButtonProps) {
    const { mode, setMode } = useColorScheme()
    const [mounted, setMounted] = useState(false)

    useEffect(() => {
        setMounted(true)
    }, [])
    if (!mounted) {
        return (
            <IconButton
                size="sm"
                variant="outlined"
                color="neutral"
                disabled
            />
        )
    }
    return (
        <IconButton
            id="toggle-mode"
            size="sm"
            variant="outlined"
            color="neutral"
            aria-label="toggle light/dark mode"
            {...props}
            onClick={event => {
                if (mode === 'light') {
                    setMode('dark')
                } else {
                    setMode('light')
                }
                onClick?.(event)
            }}
        >
            {mode === 'light' ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
        </IconButton>
    )
}

const Input2 = forwardRef((props: InputProps, ref: any) => {
    return (
        <Input
            {...props}
            slotProps={{
                input: {
                    ref,
                },
            }}
        />
    )
})

export default function JoySignInSideTemplate() {
    const { pathname } = useLocation()
    const [searchParams] = useSearchParams()

    const emailSent = pathname.includes('/code')
    const error = pathname.includes('/error')

    //console.log('otpType', searchParams.get('otpType'))

    const [loading, setLoading] = useState(false)
    //const [ emailSent, setEmailSent ] = useState(false)
    const [maybeError, setError] = useMaybeState<string>()
    const [username, setUsername] = useState('')
    const [otpType, setOtpType] = useState<'email' | 'mobileNumber'>(
        (searchParams.get('otpType') as any) ?? 'email',
    )
    const [otp, setOtp] = useState('')

    const page_redirect = searchParams.get('page_redirect')

    useEffect(() => {
        if (isEmail(username)) {
            setOtpType('email')
        } else if (isMobilePhone(username)) {
            setOtpType('mobileNumber')
        }
    }, [username])

    const checkOtp = async () => {
        setLoading(true)
        setError(undefined)
        try {
            const params = new URLSearchParams({
                code: otp,
            })

            location.href = `${import.meta.env.VITE_AUTH_URL}/callback?${params.toString()}`
        } catch (e: any) {
            console.log('error', e)
            setError(e.response.data ?? "Sorry, we counldn't validate your code. Please try again.")
            setLoading(false)
        } finally {
            /*if (!error) {
                window.location.reload()
            }*/
        }
    }

    useEffect(() => {
        if (otp.length == 6) {
            console.log('CHECK OTP')
            checkOtp()
        }
    }, [otp])

    const expoPushToken = localStorage.getItem('expoPushToken')

    return (
        <>
            <GlobalStyles
                styles={{
                    ':root': {
                        '--Collapsed-breakpoint': '769px', // form will stretch when viewport is below `769px`
                        '--Cover-width': '50vw', // must be `vw` only
                        '--Form-maxWidth': '800px',
                        '--Transition-duration': '0.4s', // set to `none` to disable transition
                    },
                }}
            />
            <Box
                sx={theme => ({
                    width: 'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
                    transition: 'width var(--Transition-duration)',
                    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    position: 'relative',
                    zIndex: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    backdropFilter: 'blur(12px)',
                    backgroundColor: 'rgba(255 255 255 / 0.2)',
                    [theme.getColorSchemeSelector('dark')]: {
                        backgroundColor: 'rgba(19 19 24 / 0.4)',
                    },
                })}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100dvh',
                        width: 'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
                        maxWidth: '100%',
                        px: 2,
                    }}
                >
                    <Box
                        component="header"
                        sx={{
                            py: 3,
                            display: 'flex',
                            alignItems: 'left',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box
                            sx={{
                                gap: 2,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            {/*<IconButton variant="soft" color="primary" size="sm">
                            <BadgeRoundedIcon />
                            </IconButton>*/}
                            <Typography level="title-lg">Fastre</Typography>
                        </Box>
                        <ColorSchemeToggle />
                    </Box>
                    <Box
                        component="main"
                        sx={{
                            my: 'auto',
                            py: 2,
                            pb: 20,

                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            width: 400,
                            maxWidth: '100%',
                            mx: 'auto',
                            borderRadius: 'sm',
                            '& form': {
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                            },
                            [`& .${formLabelClasses.asterisk}`]: {
                                visibility: 'hidden',
                            },
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <img
                                src={logo}
                                style={{ width: '200px' }}
                            />
                        </Box>
                        <Stack
                            gap={4}
                            sx={{ mb: 2 }}
                        >
                            <Stack gap={1}>
                                {!emailSent && (
                                    <>
                                        <Typography level="h3">Sign in</Typography>
                                        {/*<Typography level="body-sm">
                                        New to Fastre?{' '}
                                        <Link href="#replace-with-a-link" level="title-sm">
                                            Sign up!
                                        </Link>
                                    </Typography>*/}
                                    </>
                                )}
                            </Stack>
                        </Stack>
                        <Stack
                            gap={4}
                            sx={{ mt: 2 }}
                        >
                            {emailSent && (
                                <>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            mb: 8,
                                        }}
                                    >
                                        <Typography
                                            textAlign="center"
                                            fontSize={16}
                                            fontWeight={600}
                                        >
                                            Let's verify your {otpType == 'email' ? 'email' : 'mobile number'}
                                        </Typography>
                                        <Typography textAlign="center">
                                            Check your {otpType == 'email' ? 'inbox' : 'messages'} for the
                                            code we sent you
                                        </Typography>
                                    </Box>
                                    <Box sx={{}}>
                                        <OtpInput
                                            value={otp}
                                            onChange={setOtp}
                                            numInputs={6}
                                            containerStyle={{
                                                justifyContent: 'center',
                                                gap: '24px',
                                            }}
                                            //disabled={loading}
                                            //shouldAutoFocus
                                            renderInput={props => (
                                                <Input2
                                                    {...props}
                                                    style={{
                                                        ...props.style,
                                                        width: '36px',
                                                    }}
                                                />
                                            )}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            mt: 4,
                                            visibility: loading ? 'visible' : 'hidden',
                                        }}
                                    >
                                        <CircularProgress />
                                    </Box>
                                    {maybeError.map(error => (
                                        <Typography
                                            color="danger"
                                            textAlign="center"
                                            mt={4}
                                        >
                                            {error}
                                        </Typography>
                                    ))}
                                </>
                            )}

                            {!emailSent && (
                                <form
                                    onSubmit={async event => {
                                        event.preventDefault()
                                        setLoading(true)
                                        setError(undefined)
                                        try {
                                            const params = new URLSearchParams({
                                                userIdentifier: username,
                                                otpType,
                                                redirect_uri: `${location.origin.replace(/\/$/, '')}${page_redirect ?? ''}`,
                                                response_type: 'token',
                                                provider: 'otp',
                                                client_id: 'solid',
                                                ...(expoPushToken && {
                                                    expoPushToken,
                                                }),
                                            })

                                            location.href = `${import.meta.env.VITE_AUTH_URL}/authorize?${params.toString()}`
                                            setLoading(false)
                                        } catch (e: any) {
                                            setLoading(false)
                                            console.log('e', e)
                                            setError(e?.response?.data?.message)
                                        }
                                    }}
                                >
                                    <FormControl required>
                                        <FormLabel>Email or Mobile</FormLabel>
                                        <Input
                                            type="mobile"
                                            name="userIdentifier"
                                            value={username}
                                            onChange={e => setUsername(e.target.value)}
                                        />
                                    </FormControl>
                                    <Stack
                                        gap={2}
                                        sx={{ mt: 2 }}
                                    >
                                        <Button
                                            type="submit"
                                            fullWidth
                                        >
                                            Sign in
                                        </Button>
                                        <Typography
                                            sx={{ minHeight: '21px' }}
                                            color="danger"
                                            level="body-sm"
                                        >
                                            {error && "Couldn't sign you in"}
                                        </Typography>
                                    </Stack>
                                </form>
                            )}
                        </Stack>
                    </Box>
                    <Box
                        component="footer"
                        sx={{ py: 3 }}
                    >
                        <Typography
                            level="body-xs"
                            textAlign="center"
                        >
                            © Fastre {new Date().getFullYear()}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={theme => ({
                    height: '100%',
                    position: 'fixed',
                    right: 0,
                    top: 0,
                    bottom: 0,
                    left: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
                    transition:
                        'background-image var(--Transition-duration), left var(--Transition-duration) !important',
                    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    backgroundColor: 'background.level1',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage:
                        'url(https://images.unsplash.com/photo-1527181152855-fc03fc7949c8?auto=format&w=1000&dpr=2)',
                    [theme.getColorSchemeSelector('dark')]: {
                        backgroundImage:
                            'url(https://images.unsplash.com/photo-1572072393749-3ca9c8ea0831?auto=format&w=1000&dpr=2)',
                    },
                })}
            />
        </>
    )
}
