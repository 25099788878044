import { toCurrency } from '@fastre/core/src/helperFunctions/string'
import { InternalLedgerSchema } from '@fastre/core/src/schemas/ledger'
import { DoneRounded, EditRounded, ErrorRounded } from '@mui/icons-material'
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Link,
    Modal,
    ModalDialog,
    Stack,
    Table,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/joy'
import { useApi } from 'api'
import {
    LedgerApiContext,
    useAgentStatementApi,
    useBulkLedgerApi,
    useConjunctionalAgenciesApi,
    useFindUserFromId,
    useLedgerMonth,
} from 'apiProviders'
import { useUserData } from 'auth'
import Loading from 'components/Loading'
import Input from 'components/input'
import { dontCloseOnBackgroundClick } from 'components/modal'
import { useShowSnack } from 'components/snackbar'
import { format } from 'date-fns'
import OptionalTooltip from 'links/optionalTooltip'
import SortableHeader from 'listings/listingsTable/sortableHeader'
import { ascend, descend, prop, sortWith, sum } from 'ramda'
import { useState } from 'react'
import EditLedgerItemModal from './editLedgerItemModal'

interface LedgerProps {
    listingId?: string
    userId: string
    ledgerApi: Omit<LedgerApiContext, 'subscribe' | 'unsubscribe'>
}

export default ({ listingId, userId, ledgerApi }: LedgerProps) => {
    const theme = useTheme()
    const api = useApi()
    const { user: userData } = useUserData()
    const conjunctionalAgenciesApi = useConjunctionalAgenciesApi()
    const findUserFromId = useFindUserFromId()
    const user = findUserFromId(userId).orUndefined()
    const showSnack = useShowSnack()
    const agentStatementApi = useAgentStatementApi(userId, false)
    const { sort, setSort } = useLedgerMonth()
    const bulkLedgerApi = useBulkLedgerApi(false)

    const conjunctional = conjunctionalAgenciesApi.data?.find(
        x => x.conjunctionalAgencyId == user?.conjunctionalAgencyId,
    )

    const rows = ledgerApi.data?.items
    const approved = ledgerApi.data?.ledgerApproval

    const [editRow, setEditRow] = useState<InternalLedgerSchema>()
    const [approvalLoading, setApprovalLoading] = useState(false)
    const month = ledgerApi.params.month as string | undefined

    const [showDisputeDialog, setShowDisputeDialog] = useState<string>()
    const [disputeReason, setDisputeReason] = useState<string>('')
    const [disputLoading, setDisputeLoading] = useState(false)
    const [createInvoiceLoading, setCreateInvoiceLoading] = useState(false)
    const [createAgentStatementLoading, setCreateAgentStatementLoading] = useState(false)

    if (!rows) return <Loading />

    const balance = sum(rows.map(row => (row.credit ?? 0) - (row.debit ?? 0)))

    const sortFunc = sort.sortDirection == 'asc' ? ascend : descend

    return (
        <>
            <Table>
                <thead>
                    <tr>
                        <th>
                            <SortableHeader
                                sort={sort}
                                setSort={setSort}
                                sortString="ledgerDate"
                            >
                                Date
                            </SortableHeader>
                        </th>
                        <th>
                            <SortableHeader
                                sort={sort}
                                setSort={setSort}
                                sortString="supplier"
                            >
                                Supplier
                            </SortableHeader>
                        </th>
                        <th>
                            <SortableHeader
                                sort={sort}
                                setSort={setSort}
                                sortString="invoiceNumber"
                            >
                                Invoice Number
                            </SortableHeader>
                        </th>
                        <th>
                            <SortableHeader
                                sort={sort}
                                setSort={setSort}
                                sortString="description"
                            >
                                Description
                            </SortableHeader>
                        </th>
                        <th>Listing</th>
                        <th style={{ textAlign: 'right' }}>
                            <SortableHeader
                                sort={sort}
                                setSort={setSort}
                                sortString="debit"
                            >
                                Debit
                            </SortableHeader>
                        </th>
                        <th style={{ textAlign: 'right' }}>
                            <SortableHeader
                                sort={sort}
                                setSort={setSort}
                                sortString="credit"
                            >
                                Credit
                            </SortableHeader>
                        </th>
                        <th style={{ width: '48px' }} />
                        <th style={{ width: '54px' }} />
                    </tr>
                </thead>
                <tbody>
                    {sortWith([sortFunc(x => x[sort.sort] ?? '')], rows).map(row => (
                        <tr key={row.id}>
                            <td>{!row.monthlyCharge && format(new Date(row.ledgerDate), 'dd/MM/yyyy')}</td>
                            <td>{row.supplier}</td>
                            <td>{row.invoiceNumber}</td>
                            <td>{row.description}</td>
                            <td>{row.listingName}</td>
                            <td style={{ textAlign: 'right' }}>{row.debit && toCurrency(row.debit)}</td>
                            <td style={{ textAlign: 'right' }}>{row.credit && toCurrency(row.credit)}</td>
                            <td>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexDirection: 'row',
                                    }}
                                >
                                    {userData.userId == userId && !approved && !row.disputeReason && (
                                        <IconButton
                                            size="sm"
                                            onClick={() => {
                                                setDisputeReason('')
                                                setShowDisputeDialog(row.id)
                                            }}
                                        >
                                            <ErrorRounded />
                                        </IconButton>
                                    )}
                                    {row.disputeReason && (
                                        <Tooltip title={row.disputeReason}>
                                            <ErrorRounded
                                                sx={{
                                                    width: '20px',
                                                    height: '20px',
                                                }}
                                                color={'danger' as any}
                                            />
                                        </Tooltip>
                                    )}
                                </Box>
                            </td>
                            <td>
                                {userData.permissions.includes('ledger.edit') &&
                                    (!row.approved || userData.superUser) &&
                                    approved == undefined && (
                                        <IconButton
                                            size="sm"
                                            onClick={() => setEditRow(row)}
                                        >
                                            <EditRounded />
                                        </IconButton>
                                    )}
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td
                            colSpan={5}
                            style={{ textAlign: 'right' }}
                        >
                            <b>Total</b>
                        </td>
                        <td style={{ textAlign: 'right' }}>
                            <b>{toCurrency(sum(rows.map(row => row.debit ?? 0)))}</b>
                        </td>
                        <td style={{ textAlign: 'right' }}>
                            <b>{toCurrency(sum(rows.map(row => row.credit ?? 0)))}</b>
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td
                            colSpan={5}
                            style={{ textAlign: 'right', fontWeight: 'bold' }}
                        >
                            Balance
                        </td>
                        <td
                            style={{
                                textAlign: 'right',
                                color: theme.palette.danger[500],
                                fontWeight: 'bold',
                            }}
                        >
                            <b>{balance < 0 && toCurrency(-balance)}</b>
                        </td>
                        <td
                            style={{
                                textAlign: 'right',
                                color: theme.palette.success[500],
                                fontWeight: 'bold',
                            }}
                        >
                            {balance >= 0 && toCurrency(balance)}
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </Table>
            <Stack
                direction="row"
                sx={{ mt: 2 }}
            >
                {userData.permissions.includes('ledger.edit') && (!approved || userData.superUser) && (
                    <Button
                        variant="soft"
                        onClick={() =>
                            setEditRow({
                                listingId,
                                userId,
                                ledgerDate: month ?? format(new Date(), 'yyyy-MM-dd'),
                            } as any)
                        }
                    >
                        Add Row
                    </Button>
                )}
                {(userId == userData.userId || userData.permissions.includes('ledger.approve')) &&
                    approved == undefined && (
                        <OptionalTooltip
                            show={!ledgerApi.data?.ledgerOpen}
                            title="Ledger has not yet been opened"
                        >
                            <Box sx={{ ml: 'auto' }}>
                                <Button
                                    loading={approvalLoading}
                                    onClick={async () => {
                                        setApprovalLoading(true)
                                        const ledgerApproval = await api
                                            .post('/ledger/approve', { userId, month: month! })
                                            .then(prop('data'))
                                        //setApproved(ledgerApproval)
                                        ledgerApi.refresh()
                                        bulkLedgerApi.refresh()
                                        setApprovalLoading(false)
                                    }}
                                    disabled={!ledgerApi.data?.ledgerOpen}
                                >
                                    Approve
                                </Button>
                            </Box>
                        </OptionalTooltip>
                    )}
                {approved && (
                    <Box
                        sx={{
                            display: 'flex',
                            ml: 'auto',
                            alignItems: 'center',
                        }}
                    >
                        <DoneRounded
                            fontSize="large"
                            color="success"
                            sx={{
                                mr: 1,
                                width: '20px',
                                height: '20px',
                            }}
                        />
                        <Typography>Approved</Typography>
                    </Box>
                )}
            </Stack>
            {user !== undefined &&
                user.conjunctionalAgencyId == undefined &&
                approved &&
                !approvalLoading && (
                    <Box
                        sx={{
                            mt: 2,
                            display: 'flex',
                            justifyContent: 'right',
                        }}
                    >
                        {approved.agentStatementAdded ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    ml: 'auto',
                                    alignItems: 'center',
                                }}
                            >
                                <DoneRounded
                                    fontSize="large"
                                    color="success"
                                    sx={{
                                        mr: 1,
                                        width: '20px',
                                        height: '20px',
                                    }}
                                />
                                <Typography>Agent statement added</Typography>
                            </Box>
                        ) : (
                            <Button
                                loading={createAgentStatementLoading}
                                onClick={async () => {
                                    setCreateAgentStatementLoading(true)
                                    try {
                                        await api.post('/ledger/createagentstatement', {
                                            userId,
                                            month: format(month!, 'yyyy-MM-01'),
                                        })
                                        await Promise.all([ledgerApi.refresh(), agentStatementApi.refresh()])
                                        bulkLedgerApi.refresh()
                                        showSnack('Agent statement added', 'success')
                                    } catch (e) {
                                        console.error(e)
                                        showSnack('Error adding agent statement', 'danger')
                                    } finally {
                                        setCreateAgentStatementLoading(false)
                                    }
                                }}
                            >
                                Add Agent Statement
                            </Button>
                        )}
                    </Box>
                )}
            <Box
                sx={{
                    mt: 2,
                    display: 'flex',
                    justifyContent: 'right',
                }}
            >
                {userData.xeroConnected &&
                    userData.permissions.includes('ledger.approve') &&
                    !approvalLoading &&
                    user?.conjunctionalAgencyId != undefined &&
                    balance < 0 && (
                        <>
                            {approved?.xeroInvoice ? (
                                <>
                                    <Typography>
                                        Invoiced:{' '}
                                        <Link
                                            target="_blank"
                                            href={approved.xeroInvoice.url}
                                        >
                                            {approved.xeroInvoice.invoiceNumber}
                                        </Link>
                                    </Typography>
                                    {approved.xeroInvoiceStatus == 'PAID' && (
                                        <Typography
                                            sx={{
                                                color: theme.palette.success[500],
                                                ml: 1,
                                            }}
                                        >
                                            Paid
                                        </Typography>
                                    )}
                                </>
                            ) : (
                                <OptionalTooltip
                                    show={conjunctional?.xero == undefined}
                                    title="Must select Xero contact for agency first"
                                >
                                    <Box>
                                        {balance < 0 && (
                                            <Button
                                                loading={createInvoiceLoading}
                                                onClick={async () => {
                                                    setCreateInvoiceLoading(true)
                                                    await api.post('/xero/createledgerinvoice', {
                                                        userId,
                                                        month: format(month!, 'yyyy-MM-01'),
                                                    })
                                                    await ledgerApi.refresh()
                                                    bulkLedgerApi.refresh()
                                                    setCreateInvoiceLoading(false)
                                                }}
                                                disabled={
                                                    approved == undefined || conjunctional?.xero == undefined
                                                }
                                            >
                                                Create Invoice
                                            </Button>
                                        )}
                                    </Box>
                                </OptionalTooltip>
                            )}
                        </>
                    )}
                {userData.permissions.includes('ledger.approve') &&
                    !approvalLoading &&
                    user?.conjunctionalAgencyId != undefined && (
                        <>
                            {balance > 0 &&
                                (approved?.creditMovedForwards ? (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            ml: 'auto',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <DoneRounded
                                            fontSize="large"
                                            color="success"
                                            sx={{
                                                mr: 1,
                                                width: '20px',
                                                height: '20px',
                                            }}
                                        />
                                        <Typography>Credit Carried Forwards</Typography>
                                    </Box>
                                ) : (
                                    <Button
                                        loading={createInvoiceLoading}
                                        onClick={async () => {
                                            setCreateInvoiceLoading(true)
                                            await api.post('/ledger/moveconjunctionalcreditforwards', {
                                                userId,
                                                month: format(month!, 'yyyy-MM-01'),
                                            })
                                            await ledgerApi.refresh()
                                            bulkLedgerApi.refresh()
                                            setCreateInvoiceLoading(false)
                                        }}
                                        disabled={approved == undefined}
                                    >
                                        Carry Credit Forwards
                                    </Button>
                                ))}
                        </>
                    )}
            </Box>

            <EditLedgerItemModal
                row={editRow}
                onClose={updated => {
                    setEditRow(undefined)
                    if (updated) {
                        ledgerApi.refresh()
                        bulkLedgerApi.refresh()
                    }
                }}
            />
            <Modal
                open={showDisputeDialog != undefined}
                onClose={dontCloseOnBackgroundClick(() => setShowDisputeDialog(undefined))}
            >
                <ModalDialog>
                    <DialogTitle>Dispute</DialogTitle>
                    <DialogContent>
                        <Stack spacing={2}>
                            <Typography>Please provide a reason for disputing this ledger entry.</Typography>
                            <Input
                                label="Reason"
                                value={disputeReason}
                                onChange={setDisputeReason}
                            />
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="danger"
                            startDecorator={<ErrorRounded />}
                            loading={disputLoading}
                            onClick={async () => {
                                setDisputeLoading(true)
                                await api.post(`/ledger/${showDisputeDialog}/dispute`, {
                                    disputeReason,
                                })
                                await ledgerApi.refresh()
                                bulkLedgerApi.refresh()
                                setDisputeLoading(false)
                                setShowDisputeDialog(undefined)
                            }}
                        >
                            Dispute
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => setShowDisputeDialog(undefined)}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>
        </>
    )
}
