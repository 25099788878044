import { useApi } from 'api'
import { useUserData } from 'auth'
import Loading from 'components/Loading'
import { useEffect } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

const ReiConnectionResponse = () => {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const { hash } = useLocation()
    const params = new URLSearchParams(hash)

    const accessToken = params.get('access_token')
    const state = params.get('state')
    const reiApi = params.get('api')

    useEffect(() => {
        if (accessToken && state && reiApi) {
            console.log('accessToken', accessToken)
            console.log('state', state)
            console.log('stateApi', reiApi)

            const newSearchParams = new URLSearchParams(searchParams)
            newSearchParams.set('reiAccessToken', accessToken)
            newSearchParams.set('reiApi', reiApi)

            navigate(`/${state}/rei/auth?${newSearchParams.toString()}`)
        }
    }, [])

    return <Loading />
}

export const LoggedInReiConnectionResponse = () => {
    const navigate = useNavigate()
    const api = useApi()
    const { user, refreshUser } = useUserData()
    const [searchParams] = useSearchParams()

    const reiAccessToken = searchParams.get('reiAccessToken')
    const reiApi = searchParams.get('reiApi')

    const setReiToken = async () => {
        await new Promise(r => setTimeout(r, 100))
        await api.post('/rei/settoken', { accessToken: reiAccessToken, reiApi })
        await refreshUser()
        navigate(`../../users/${user.userId}`, {
            replace: true,
            relative: 'path',
        })
    }

    useEffect(() => {
        setReiToken()
    }, [])

    return <Loading />
}

export default ReiConnectionResponse
