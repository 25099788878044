import z from 'zod'

export const CreateLinkSchema = z.object({
    linkName: z.string(),
    linkUrl: z.string().url(),
    //availableToGroups: z.array(z.string()),
    availableToRoles: z.array(z.string()).default([]),
    availableToUsers: z.array(z.string()).default([]),
    icon: z.string().optional(),

    category: z.string().optional(),
    order: z.number(),
    showInTab: z.string().optional(),
})

export const UpdateLinkSchema = z.object({
    linkName: z.string().optional(),
    linkUrl: z.string().url().optional() /*.url()*/,
    //availableToGroups: z.array(z.string()),
    availableToRoles: z.array(z.string()).optional(),
    availableToUsers: z.array(z.string()).optional(),
    //file: z.instanceof(File).optional(),
    icon: z.string().optional(),

    category: z.string().optional(),
    order: z.number().optional(),
    showInTab: z.string().optional(),
})

export type UpdateLinkSchema = z.infer<typeof UpdateLinkSchema>
export type CreateLinkSchema = z.infer<typeof CreateLinkSchema>

export type InternalLinkSchema = CreateLinkSchema & {
    linkId: string
    icon: string
    category: string
}
