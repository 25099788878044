import { Route, Routes } from 'react-router'
import ListingDetails from './listingDetails'
import ListingsTable from './listingsTable'

export default () => (
    <Routes>
        <Route
            path=":listingId/*"
            element={<ListingDetails />}
        />
        <Route
            index
            element={<ListingsTable />}
        />
    </Routes>
)
