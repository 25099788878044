import { InternalRoleSchema, Permissions, RoleSchema } from '@fastre/core/src/schemas/roles'
import { FrontendUserSchema } from '@fastre/core/src/schemas/userMembership'
import { zodResolver } from '@hookform/resolvers/zod'
import { DeleteRounded } from '@mui/icons-material'
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    ModalClose,
    ModalDialog,
    Stack,
    Switch,
} from '@mui/joy'
import { useApi } from 'api'
import { useFindUserFromId, useRolesApi, useUsersApi } from 'apiProviders'
import { SlotInput } from 'components/input'
import { useShowSnack } from 'components/snackbar'
import { prop } from 'ramda'
import { useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

export function EditRoleModal({
    role,
    users,
    close,
}: {
    role: {} | InternalRoleSchema
    close
    users: FrontendUserSchema[]
}) {
    const api = useApi()
    const rolesApi = useRolesApi()
    const usersApi = useUsersApi()
    const showSnack = useShowSnack()
    const findUserFromId = useFindUserFromId()
    const isNew = (role as InternalRoleSchema).id == undefined

    const [loading, setLoading] = useState(false)

    const currentUsers = users
        .filter(user => user.conjunctionalAgencyId == (role as InternalRoleSchema).id)
        .map(prop('userId'))

    const { register, control, handleSubmit, formState, getValues, setValue } = useForm<RoleSchema>({
        defaultValues: role
            ? {
                  rolePermissions: [],
                  ...role,
              }
            : {},
        resolver: zodResolver(RoleSchema),
    })

    if (Object.keys(formState.errors).length > 0) {
        console.log(formState.errors)
    }

    const onSubmit: SubmitHandler<RoleSchema> = async data => {
        setLoading(true)
        try {
            if (isNew) {
                await api.post('/roles', data)
                close()
            } else {
                await api.post(`/roles/${(role as any).id}`, {
                    ...data,
                    //removedUserIds: without(data.userIds, currentUsers),
                })
            }
            await Promise.all([rolesApi.refresh(), usersApi.refresh()])
            showSnack(`Role ${isNew ? 'Added' : 'Saved'}`, 'success')
            close()
        } catch (e) {
            console.error(e)
            showSnack('Error saving role', 'danger')
        } finally {
            setLoading(false)
        }
    }

    return (
        <form
            noValidate
            onSubmit={handleSubmit(onSubmit)}
        >
            <ModalDialog>
                <ModalClose />
                <DialogTitle>{isNew ? 'Create Role' : 'Edit Role'}</DialogTitle>
                <DialogContent sx={{ minWidth: '350px', pt: 1 }}>
                    <Stack spacing={2}>
                        <Controller
                            name="roleName"
                            control={control}
                            render={field => (
                                <SlotInput
                                    {...field}
                                    label="Role Name"
                                />
                            )}
                        />
                        <Controller
                            name="rolePermissions"
                            control={control}
                            render={({ field }) => (
                                <Box
                                    sx={{
                                        gap: 1,
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        flexDirection: 'column',
                                    }}
                                >
                                    {Object.entries(Permissions).map(([key, value]) => (
                                        <Box>
                                            <Switch
                                                key={key}
                                                endDecorator={value}
                                                checked={field.value.includes(key as any)}
                                                onChange={e => {
                                                    field.onChange(
                                                        e.target.checked
                                                            ? [...field.value, key]
                                                            : field.value.filter(v => v != key),
                                                    )
                                                }}
                                            />
                                        </Box>
                                    ))}
                                </Box>
                            )}
                        />
                        {/*<Controller
                            name="userIds"
                            control={control}
                            render={field => (
                                <SlotAutocomplete
                                    {...field}
                                    label="Users"
                                    multiple
                                    options={usersApi.maybeData.orSome([]).map(prop('userId'))}
                                    getOptionLabel={userId =>
                                        findUserFromId(userId)
                                            .map(user => `${user.firstName} ${user.lastName}`)
                                            .orSome('loading...')
                                    }
                                    onChange={(e, value) => field.field.onChange(value)}
                                />
                            )}
                        />*/}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        loading={loading}
                    >
                        Save
                    </Button>
                    <Button
                        onClick={close}
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                    {!isNew && (
                        <Button
                            variant="outlined"
                            color="danger"
                            loading={loading}
                            disabled={
                                usersApi.data?.some(user => user.roleId == (role as InternalRoleSchema).id) ??
                                true
                            }
                            startDecorator={<DeleteRounded />}
                            sx={{ mr: 'auto' }}
                            onClick={async () => {
                                setLoading(true)
                                try {
                                    await api.delete(`/roles/${(role as InternalRoleSchema).id}`)
                                    await rolesApi.refresh()
                                    showSnack('Role Deleted', 'success')
                                    close()
                                } catch (e) {
                                    console.error(e)
                                    showSnack('Error deleting role', 'danger')
                                } finally {
                                    setLoading(false)
                                }
                            }}
                        >
                            Delete
                        </Button>
                    )}
                </DialogActions>
            </ModalDialog>
        </form>
    )
}
