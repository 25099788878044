import { extendTheme } from '@mui/joy/styles'
import { forwardRef } from 'react'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'

const LinkBehavior = forwardRef<
    HTMLAnchorElement,
    Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
    const { href, ...other } = props
    // Map href (Material UI) -> to (react-router)
    return (
        <RouterLink
            ref={ref}
            to={href}
            {...other}
        />
    )
})

export default extendTheme({
    components: {
        JoyTabs: {
            styleOverrides: {
                root: {
                    backgroundColor: 'inherit',
                },
            },
        },
        JoyLink: {
            defaultProps: {
                component: LinkBehavior,
            },
        },
        JoyListItem: {
            defaultProps: {
                //component: LinkBehavior,
            },
        },
        JoyModalDialog: {
            styleOverrides: {
                root: ({ theme }) => ({
                    [theme.breakpoints.down('sm')]: {
                        width: '100%',
                    },
                }),
            },
        },
        /*JoyStack: {
            defaultProps: ({ theme }) => ({ spacing: theme.spacing(2) }),

            },
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    gap: theme.breakpoints.up('sm') ? 2 : 1,
                    direction: 'column',
                    [theme.breakpoints.up('sm')]: {
                        direction: 'row',
                    },
                    mt: 9,
                }),
            }
        },*/
    },
})
