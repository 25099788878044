import z from 'zod'
import { AddressSchema } from '../generic'

const SlateText = z.object({
    text: z.string(),
})

const SlateSpecialElement = z
    .object({
        type: z.literal('user'),
        character: z.object({
            name: z.string(),
            firstName: z.string(),
            lastName: z.string(),
            userId: z.string(),
        }),
    })
    .or(
        z.object({
            type: z.literal('listing'),
            character: z.object({
                listingId: z.string(),
                listingAddress: AddressSchema,
            }),
        }),
    )

const SlateParagraph = z.object({
    type: z.literal('paragraph'),
    children: z.array(z.union([SlateText, SlateSpecialElement])),
})

const AutomationActionEmailSchema = z.object({
    actionType: z.literal('email'),
    toUsers: z.array(
        z.object({
            type: z.enum(['email', 'user', 'special']),
            value: z.string(),
        }),
    ),
    subject: z.string(),
    body: z.array(SlateParagraph),
})

const AutomationActionNotificationSchema = z.object({
    actionType: z.literal('notification'),
    toUsers: z.array(
        z.object({
            type: z.enum(['user', 'special']),
            value: z.string(),
        }),
    ),
    notification: z.any(),
})

export const AutomationActionUpdateSchema = z.object({
    actionType: z.literal('update'),
    updateType: z.enum(['additionalDetails']),
    updateFieldName: z.string(),
    updateFieldValue: z.string().or(z.number()),
})

export const AutomationActionSharedTodoSchema = z.object({
    actionType: z.literal('sharedTodo'),
    todoId: z.string(),
    todoDescription: z.any(),
})

export const AutomationActionSchema = z.union([
    AutomationActionEmailSchema,
    AutomationActionNotificationSchema,
    AutomationActionUpdateSchema,
    AutomationActionSharedTodoSchema,
])

export type AutomationActionEmailSchema = z.infer<typeof AutomationActionEmailSchema>
export type AutomationActionSchema = z.infer<typeof AutomationActionSchema>
export type AutomationActionUpdateSchema = z.infer<typeof AutomationActionUpdateSchema>
