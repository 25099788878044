import { z } from 'zod'

export const showInTabOptions = ['listing', 'contract', 'marketing'] as const
export type ShowInTabOption = (typeof showInTabOptions)[number]

export const ListingValueConfigSchema = z.object({
    configName: z.string().min(1),
    configOrder: z.number().default(0),
    configValues: z.array(
        z.object({
            value: z.string(),
            color: z.string(),
        }),
    ),
    showInTab: z.enum(showInTabOptions).default('listing'),
    visibleTo: z.array(z.string()).optional(),
})

export type ListingValueConfigSchema = z.infer<typeof ListingValueConfigSchema>
export interface InternalListingValueConfigSchema extends ListingValueConfigSchema {
    id: string
}
