import { WebsocketMessage } from '@fastre/core/src/schemas/websocket'
import { useOrgId } from 'helpers'
import { createContext, useContext, useEffect, useState } from 'react'
import * as reactUseWebsocket from 'react-use-websocket'

const GeneralWebsocketContext = createContext<WebsocketMessage | null>(null)

export const GeneralWebsocketProvider = ({ children }) => {
    const orgId = useOrgId()
    const authToken = localStorage.getItem('token')!
    const { lastMessage } = reactUseWebsocket.default(`${import.meta.env.VITE_APP_WEBSOCKET_URL}?orgId=${orgId}`, {
        protocols: authToken,
        heartbeat: true,
        shouldReconnect: closeEvent => true,
    })

    const [newMessage, setNewMessage] = useState<WebsocketMessage | null>(null)

    useEffect(() => {
        if (lastMessage) {
            const data = JSON.parse(lastMessage.data)
            setNewMessage(data)
        }
    }, [lastMessage])

    return <GeneralWebsocketContext.Provider value={newMessage}>{children}</GeneralWebsocketContext.Provider>
}

const useGeneralWebSocket = () => useContext(GeneralWebsocketContext)

/*export function useWebSocket(type: 'notification'): NotificationWebsocket['payload'] | null
export function useWebSocket(type: 'chat'): ChatWebsocket['payload'] | null
export function useWebSocket(type: 'chatConfig'): ChatConfigWebsocket['payload'] | null
export function useWebSocket(type: undefined): null*/

export function useWebSocket(type: WebsocketMessage['type'] | undefined) {
    const [newMessage, setNewMessage] = useState<WebsocketMessage['payload'] | null>(null)

    const lastMessage = useGeneralWebSocket()

    useEffect(() => {
        if (lastMessage) {
            if (lastMessage.type == type) {
                setNewMessage(lastMessage.payload)
            }
        }
    }, [lastMessage])

    return newMessage
}
