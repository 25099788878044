import { Box } from '@mui/joy'
import logo from 'images/logo.png'

export default () => (
    <Box
        sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
        }}
    >
        <Box
            sx={{
                width: '100px',
                height: '100px',
                animation: 'spin 4s linear infinite',

                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-50px',
                marginLeft: '-50px',
                '@keyframes spin': {
                    '0%': {
                        transform: 'rotate(0deg)',
                    },
                    '100%': {
                        transform: 'rotate(360deg)',
                    },
                },
            }}
        >
            <img
                src={logo}
                alt="loading..."
                style={{
                    width: '100%',
                    height: '100%',
                }}
            />
        </Box>
    </Box>
)
