import { Sheet, SheetProps, useTheme } from '@mui/joy'

interface ButtonSheetProps extends SheetProps {
    selected?: boolean
}

export default ({ children, selected, ...props }: ButtonSheetProps) => {
    const theme = useTheme()

    return (
        <Sheet
            {...props}
            sx={{
                background: theme.palette.background.level2,
                ':hover': {
                    background: theme.palette.background.level1,
                },
                flexGrow: 1,
                py: 1,
                px: 2,
                borderRadius: 4,
                cursor: 'pointer',
                ...props.sx,
                border: `1px solid ${theme.palette.divider}`,
                ...(selected && {
                    border: `1px solid ${theme.palette.primary[500]}`,
                }),
            }}
        >
            {children}
        </Sheet>
    )
}
