import { getContractCommission } from '@fastre/core/src/schemas/contract'
import { formatAddress } from '@fastre/core/src/schemas/generic'
import { TypesenseListingSchema } from '@fastre/core/src/schemas/typesense'
import { Link, Sheet, Table, useTheme } from '@mui/joy'
import { useOrgId } from 'helpers'

export default function MainTable({ listings }: { listings: TypesenseListingSchema[] }) {
    const theme = useTheme()
    const orgId = useOrgId()

    return (
        <Sheet
            variant="outlined"
            sx={{ mb: 4, width: '100%', borderRadius: 'sm' }}
        >
            <Table
                //stripe="odd"
                sx={{
                    '& th': {
                        height: '65px',
                        backgroundColor: '#002147',
                        color: 'white',
                    },
                    '& tr': {
                        '&:nth-of-type(odd)': {
                            backgroundColor: theme.palette.background.level1,
                        },
                    },
                }}
            >
                <thead>
                    <tr>
                        <th>Agent</th>
                        <th>Address</th>
                        <th
                            style={{ textAlign: 'right' }}
                            align="right"
                        >
                            Contract $
                        </th>
                        <th
                            style={{ textAlign: 'right' }}
                            align="right"
                        >
                            Gross Comm $ ex
                        </th>
                        <th
                            style={{ textAlign: 'right' }}
                            align="right"
                        >
                            Comm % ex
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {listings.map((listing, index) => (
                        <tr key={index}>
                            <td>{listing.userNames}</td>
                            <td>
                                <Link href={`/${orgId}/listings/sale/${listing.id}`}>
                                    {formatAddress(listing.listingAddress)}
                                </Link>
                            </td>
                            <td align="right">
                                {listing.contract?.salePrice &&
                                    `$${listing.contract?.salePrice.toLocaleString()}`}
                            </td>
                            <td align="right">
                                {listing.contract?.salePrice &&
                                    `$${getContractCommission(listing).toLocaleString('en-US', { maximumFractionDigits: 0 })}`}
                            </td>
                            <td align="right">
                                {listing.commission &&
                                    `${listing.commission.toLocaleString('en-US', { minimumFractionDigits: 2 })}%`}
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td>Total</td>
                        <td></td>
                        <td align="right">
                            {listings.length > 0 &&
                                `$${listings.reduce((acc, listing) => acc + listing.contract?.salePrice!, 0).toLocaleString()}`}
                        </td>
                        <td align="right">
                            {listings.length > 0 &&
                                `$${listings.reduce((acc, listing) => acc + getContractCommission(listing), 0).toLocaleString('en-US', { maximumFractionDigits: 0 })}`}
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </Table>
        </Sheet>
    )
}
