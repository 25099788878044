import { FormType } from '@fastre/core/src/schemas/rei'
import { UpdateSaleListingSchema } from '@fastre/core/src/schemas/saleListing'
import { Button, DialogActions, DialogContent, DialogTitle, Modal, ModalClose, ModalDialog, Typography } from '@mui/joy'
import { useApi } from 'api'
import { useListingType } from 'apiProviders'
import { dontCloseOnBackgroundClick } from 'components/modal'
import { useShowSnack } from 'components/snackbar'
import { useListingContext } from 'listings/listingProvider'
import { useState } from 'react'
import { UseFormSetValue } from 'react-hook-form'

interface AbandonFormModalProps {
    formType: FormType
    open: boolean
    close: () => void
    setValue: UseFormSetValue<UpdateSaleListingSchema>
}

export const AbandonFormModal = ({ formType, open, close, setValue }: AbandonFormModalProps) => {
    const api = useApi()
    const listingType = useListingType()
    const { listing, setListing } = useListingContext()
    const showSnack = useShowSnack()

    const [loading, setLoading] = useState(false)

    return (
        <Modal
            open={open}
            onClose={dontCloseOnBackgroundClick(() => close())}
        >
            <ModalDialog sx={{}}>
                <ModalClose />
                <DialogTitle>Abandon Form</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you wish to abandon this form?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="danger"
                        loading={loading}
                        onClick={async () => {
                            try {
                                setLoading(true)
                                await api.post(`/listing/${listingType}/${listing.listingId}/rei/${formType}/delete`)
                                setListing({ ...listing, [formType]: undefined })
                                showSnack('Form abandoned', 'success')
                                close()
                            } catch (error) {
                                showSnack('Error abandoning form', 'danger')
                                console.error(error)
                            } finally {
                                setLoading(false)
                            }
                        }}
                    >
                        Abandon
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={close}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    )
}
