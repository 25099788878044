/* eslint-disable jsx-a11y/anchor-is-valid */
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded'
import { Box, useTheme } from '@mui/joy'
import AspectRatio from '@mui/joy/AspectRatio'
import Card, { CardProps } from '@mui/joy/Card'
import Link from '@mui/joy/Link'
import Typography from '@mui/joy/Typography'
import { useEffect, useMemo } from 'react'
import { DropzoneOptions, useDropzone } from 'react-dropzone'

interface Props extends CardProps {
    options?: DropzoneOptions
    onUpload?: (files: File[]) => void
}

export default function DropZoneInner({ sx, options, ...props }: Props) {
    const theme = useTheme()
    const { acceptedFiles, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone(options)

    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 3,
        borderColor: theme.palette.background.level3,
        borderStyle: 'dashed',
        outline: 'none',
        transition: 'border .24s ease-in-out',
        mt: 1,
    }

    const focusedStyle = {
        //borderColor: theme.palette.primary[500],
    }

    const acceptStyle = {
        borderColor: theme.palette.success[500],
    }

    const rejectStyle = {
        borderColor: theme.palette.danger[500],
    }

    useEffect(() => {
        if (props.onUpload) {
            props.onUpload(acceptedFiles)
        }
    }, [acceptedFiles])

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject],
    )

    return (
        <Box {...getRootProps({ className: 'dropzone' })}>
            <Card
                variant="soft"
                {...props}
                sx={[
                    style,
                    {
                        borderRadius: 'sm',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        alignItems: 'center',
                        px: 3,
                        flexGrow: 1,
                        boxShadow: 'none',
                    },
                    ...(Array.isArray(sx) ? sx : [sx]),
                ]}
            >
                <input {...getInputProps()} />
                <AspectRatio
                    ratio="1"
                    variant="solid"
                    color="primary"
                    sx={{
                        minWidth: 32,
                        borderRadius: '50%',
                        '--Icon-fontSize': '16px',
                    }}
                >
                    <div>
                        <FileUploadRoundedIcon />
                    </div>
                </AspectRatio>

                <Typography
                    level="body-sm"
                    textAlign="center"
                >
                    <Link
                        component="button"
                        overlay
                    >
                        Click to upload
                    </Link>{' '}
                    or drag and drop
                    <br /> PDF
                </Typography>
            </Card>
        </Box>
    )
}
