import { FrontendUserSchema } from '@fastre/core/src/schemas/userMembership'
import { Autocomplete } from '@mui/joy'
import { useFindUserFromId, useUsersApi } from 'apiProviders'
import { T, omit, pipe, prop } from 'ramda'
import { forwardRef } from 'react'

interface Props {
    value?: string
    onChange: (agent: string | null) => void
    disableClearable?: boolean
    disabled?: boolean
    filter?: (user: FrontendUserSchema) => boolean
}

export default forwardRef(
    ({ value, onChange, disableClearable = true, disabled, filter }: Props, ref: any) => {
        const usersApi = useUsersApi()
        const findUserFromId = useFindUserFromId()

        console.log('agent input', value)

        return (
            <Autocomplete
                slotProps={{
                    input: {
                        ref,
                    },
                }}
                disabled={disabled}
                multiple={false}
                disableClearable={disableClearable}
                options={usersApi.maybeData
                    .orSome([])
                    .filter(filter ?? T)
                    .map(prop('userId'))}
                getOptionLabel={pipe(findUserFromId, mUser =>
                    mUser.map(user => `${user.firstName} ${user.lastName}`).orSome('Loading...'),
                )}
                value={value ?? null}
                onChange={(e, value) => {
                    onChange(value)
                }}
                //disabled={value?.length >= 2}
            />
        )
    },
)

interface MultipleProps {
    value?: string[]
    onChange?: (agents: string[]) => void
    getOptionDisabled?: (userId: string) => boolean
    filter?: (user: FrontendUserSchema) => boolean
}

export const MultipleAgentInput = forwardRef(
    ({ value, onChange, filter, ...props }: MultipleProps, ref: any) => {
        const usersApi = useUsersApi()

        return (
            <Autocomplete
                slotProps={{
                    input: {
                        ref,
                    },
                }}
                {...omit(['key'], props)}
                multiple
                options={usersApi.maybeData
                    .orSome([])
                    .filter(filter ?? T)
                    .map(prop('userId'))}
                getOptionLabel={userId =>
                    usersApi.maybeData
                        .map(users => {
                            const user = users.find(user => user.userId === userId)

                            if (user) {
                                return `${user.firstName} ${user.lastName}`
                            } else {
                                return 'not found'
                            }
                        })
                        .orSome('loading...')
                }
                value={value}
                onChange={(e, value) => {
                    onChange && onChange(value)
                }}
                //disabled={value?.length >= 2}
            />
        )
    },
)
