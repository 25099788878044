import { CalendarEventSchema, InternalCalendarEventSchema } from '@fastre/core/src/schemas/calendar'
import { zodResolver } from '@hookform/resolvers/zod'
import { DeleteRounded, SaveRounded } from '@mui/icons-material'
import {
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle,
    Modal,
    ModalClose,
    ModalDialog,
    Stack,
} from '@mui/joy'
import { useApi } from 'api'
import { useCalendarEventsApi } from 'apiProviders'
import { useUserData } from 'auth'
import { SlotInput } from 'components/input'
import { addHours, addMinutes, differenceInMinutes, format, parse, startOfDay, startOfHour } from 'date-fns'
import { concat, filter, omit, propEq } from 'ramda'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

interface Props {
    event: InternalCalendarEventSchema | undefined | null
    onClose: any
}

const EditEventModal = ({ onClose, event }: Props) => {
    const api = useApi()
    const eventsApi = useCalendarEventsApi()
    const { user } = useUserData()

    const { handleSubmit, control, watch, reset, formState, setValue } = useForm<CalendarEventSchema>({
        defaultValues: event ?? {},
        resolver: zodResolver(CalendarEventSchema),
    })

    if (Object.keys(formState.errors).length > 0) {
        console.log(formState.errors)
    }

    useEffect(() => {
        if (event !== null) {
            const start = addHours(startOfHour(new Date()), 1).getTime()
            reset(
                event ?? {
                    title: '',
                    allDay: false,
                    start,
                    end: addHours(start, 1).getTime(),
                    calendarId: user.userId,
                    userCalendar: true,
                },
            )
        }
    }, [event])

    const allDay = watch('allDay')
    const end = watch('end')

    const [loading, setLoading] = useState(false)

    const onSubmit = async data => {
        console.log(data)
        setLoading(true)
        const newEvent = await api.post('/calendar/events', {
            id: event?.id,
            ...data,
        })
        eventsApi.setVals(concat([newEvent]))
        onClose()
        setLoading(false)
    }

    return (
        <Modal
            open={event !== null}
            onClose={onClose}
        >
            <ModalDialog>
                <ModalClose />
                <DialogTitle>{event ? 'Edit' : 'Add'} Event</DialogTitle>
                <DialogContent sx={{ width: '350px' }}>
                    <form
                        noValidate
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Stack
                            spacing={2}
                            sx={{ mt: 2 }}
                        >
                            <Controller
                                name="title"
                                control={control}
                                render={field => (
                                    <SlotInput
                                        label="Title"
                                        {...field}
                                    />
                                )}
                            />
                            <Controller
                                name="allDay"
                                control={control}
                                render={({ field }) => (
                                    <Checkbox
                                        label="All Day"
                                        {...omit(['value'], field)}
                                        checked={field.value}
                                        onChange={e => {
                                            field.onChange(e.target.checked)
                                        }}
                                    />
                                )}
                            />
                        </Stack>
                        <Stack
                            spacing={2}
                            mt={2}
                        >
                            <Controller
                                name="start"
                                control={control}
                                render={field => (
                                    <SlotInput
                                        label="Start"
                                        type="date"
                                        {...omit(['value', 'onChange'], field)}
                                        value={new Date(field.field.value).toISOString().split('T')[0]}
                                        onChange={val => {
                                            field.field.onChange(Date.parse(val))

                                            if (end) {
                                                const endMinutes = differenceInMinutes(end, startOfDay(end))
                                                const newEnd = addMinutes(
                                                    startOfDay(Date.parse(val)),
                                                    endMinutes,
                                                )
                                                setValue('end', newEnd.getTime())
                                            }
                                        }}
                                    />
                                )}
                            />
                            {!allDay && (
                                <Controller
                                    name="end"
                                    control={control}
                                    render={field => (
                                        <SlotInput
                                            label="End"
                                            type="time"
                                            {...omit(['value', 'onChange'], field)}
                                            value={
                                                field.field.value
                                                    ? format(field.field.value, 'HH:mm')
                                                    : undefined
                                            }
                                            onChange={val => {
                                                console.log('onchange', val)
                                                // convert time string to date
                                                const time = parse(val, 'HH:mm', new Date())
                                                console.log('time', time)

                                                field.field.onChange(time.getTime())
                                            }}
                                        />
                                    )}
                                />
                            )}
                        </Stack>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        loading={loading}
                        startDecorator={<SaveRounded />}
                        onClick={handleSubmit(onSubmit)}
                    >
                        Save
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    {event && (
                        <Button
                            color="danger"
                            variant="outlined"
                            loading={loading}
                            startDecorator={<DeleteRounded />}
                            sx={{ mr: 'auto' }}
                            onClick={async () => {
                                setLoading(true)
                                await api.delete(`/calendar/events/${event.id}`)
                                eventsApi.setVals(filter(propEq('id', event.id)))
                                onClose()
                                setLoading(false)
                            }}
                        >
                            Delete
                        </Button>
                    )}
                </DialogActions>
            </ModalDialog>
        </Modal>
    )
}

export default EditEventModal
