import { injectIf } from '@fastre/core/src/helperFunctions/object'
import {
    MarketingItemCategories,
    MarketingItemSchema,
    UpdateMarketingItemSchema,
} from '@fastre/core/src/schemas/marketingPackage'
import { zodResolver } from '@hookform/resolvers/zod'
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Modal,
    ModalClose,
    ModalDialog,
    Option,
    Select,
    Stack,
    Typography,
} from '@mui/joy'
import { useApi } from 'api'
import { useMarketingItemsApi } from 'apiProviders'
import ButtonSheet from 'components/buttonSheet'
import { SlotInput, SlotWrapper } from 'components/input'
import { dontCloseOnBackgroundClick } from 'components/modal'
import { useShowSnack } from 'components/snackbar'
import { useMaybeState } from 'helperFunctions/react'
import { useOrgId } from 'helpers'
import { groupBy, prop } from 'ramda'
import { useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { v4 as uuid } from 'uuid'

const EditItemModal = ({ item, close }: { item: MarketingItemSchema; close: () => void }) => {
    const isNew = item.itemId == undefined
    const api = useApi()
    const showSnack = useShowSnack()
    const itemsApi = useMarketingItemsApi()
    const orgId = useOrgId()

    const { register, control, handleSubmit, formState, getValues, setValue } = useForm<UpdateMarketingItemSchema>({
        defaultValues: {
            ...injectIf(isNew, {
                parentId: orgId!,
                itemId: uuid(),
            }),
            ...item,
        },
        resolver: zodResolver(UpdateMarketingItemSchema),
    })

    const [loading, setLoading] = useState(false)

    if (Object.keys(formState.errors).length > 0) {
        console.log(formState.errors)
    }

    const onSubmit: SubmitHandler<MarketingItemSchema> = async data => {
        setLoading(true)
        try {
            await api.post(`/marketingitem/addupdate`, data).then(prop('data'))
            itemsApi.refresh()
            close()
            showSnack('Marketing item saved', 'success')
        } catch (e) {
            console.error(e)
            showSnack('Error saving marketing item', 'danger')
        } finally {
            setLoading(false)
        }
    }

    return (
        <ModalDialog minWidth={600}>
            <DialogTitle>{isNew ? 'Create' : 'Edit'} Marketing Item</DialogTitle>
            <ModalClose />
            <form
                noValidate
                onSubmit={handleSubmit(onSubmit)}
            >
                <DialogContent>
                    <Controller
                        name="itemCategory"
                        control={control}
                        render={field => (
                            <SlotWrapper
                                {...field}
                                label="Category"
                            >
                                <Select onChange={(e, value: any) => setValue('itemCategory', value)}>
                                    {MarketingItemCategories.map(cat => (
                                        <Option value={cat}>{cat}</Option>
                                    ))}
                                </Select>
                            </SlotWrapper>
                        )}
                    />
                    <Controller
                        name="itemName"
                        control={control}
                        render={field => (
                            <SlotInput
                                label="Name"
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="itemPrice"
                        control={control}
                        render={field => (
                            <SlotInput
                                label="Price"
                                type="number"
                                startDecorator="$"
                                {...field}
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        loading={loading}
                    >
                        Save
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={close}
                    >
                        Cancel
                    </Button>
                    {!isNew && (
                        <Box
                            sx={{
                                flexGrow: 1,
                            }}
                        >
                            <Button
                                color="danger"
                                variant="outlined"
                                loading={loading}
                                onClick={async () => {
                                    setLoading(true)
                                    await api.post('/marketingitem/delete', {
                                        parentId: orgId,
                                        itemId: item.itemId,
                                    })
                                    await itemsApi.refresh()
                                    close()
                                }}
                            >
                                Delete
                            </Button>
                        </Box>
                    )}
                </DialogActions>
            </form>
        </ModalDialog>
    )
}

const MarketingItemsSettings = () => {
    const itemsApi = useMarketingItemsApi()
    const [maybeEditItem, setEditItem] = useMaybeState<MarketingItemSchema>()

    return (
        <>
            <Box>
                <Stack spacing={2}>
                    {itemsApi.maybeData
                        .map(items =>
                            Object.entries(groupBy(prop('itemCategory'), items)).map(([cat, items]) => (
                                <Stack
                                    key={cat}
                                    spacing={2}
                                >
                                    <Typography>{cat}</Typography>
                                    {items.map(item => (
                                        <ButtonSheet
                                            key={item.itemId}
                                            variant="outlined"
                                            onClick={() => setEditItem(item)}
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                maxWidth: 700,
                                            }}
                                        >
                                            <Box>{item.itemName}</Box>
                                            <Box>${item.itemPrice}</Box>
                                        </ButtonSheet>
                                    ))}
                                </Stack>
                            )),
                        )
                        .orSome([])}

                    <Box>
                        <Button
                            onClick={() =>
                                setEditItem({
                                    itemCategory: MarketingItemCategories[0],
                                    itemName: '',
                                    itemPrice: 0,
                                })
                            }
                            sx={{ mt: 2 }}
                            variant="outlined"
                        >
                            New Item
                        </Button>
                    </Box>
                </Stack>
                <Modal
                    open={maybeEditItem.isSome()}
                    onClose={dontCloseOnBackgroundClick(() => setEditItem(undefined))}
                >
                    {maybeEditItem
                        .map(item => (
                            <EditItemModal
                                item={item}
                                close={() => setEditItem(undefined)}
                            />
                        ))
                        .orSome(<></>)}
                </Modal>
            </Box>
        </>
    )
}

export default MarketingItemsSettings
