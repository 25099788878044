import { capitalize } from '@fastre/core/src/helperFunctions/string'
import {
    EmailOrUser,
    InternalAutomationSchema,
    UpdateAutomationSchema,
} from '@fastre/core/src/schemas/automation'
import { AutomationActionUpdateSchema } from '@fastre/core/src/schemas/automation/action'
import {
    AutomationTriggerSchema,
    getFieldDescription,
    getTriggerDescription,
} from '@fastre/core/src/schemas/automation/condition'
import { zodResolver } from '@hookform/resolvers/zod'
import { Cancel, Edit, Save } from '@mui/icons-material'
import { Box, Button, Chip, IconButton, Stack, Typography } from '@mui/joy'
import { useApi } from 'api'
import { useFindUserFromId, useListingConfigApi, useSharedTodosApi } from 'apiProviders'
import ButtonSheet from 'components/buttonSheet'
import Input from 'components/input'
import { useShowSnack } from 'components/snackbar'
import { useMaybeState } from 'helperFunctions/react'
import { useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import AddActionModal from './addActionModal'
import AddConditionModal from './addConditionModal'
import AutomationList from './automationList'

export const useEmailOrUserToString = () => {
    const findUserFromId = useFindUserFromId()

    return (value: EmailOrUser) => {
        //console.log('check val', value)
        return value.type == 'email'
            ? value.value
            : value.type == 'special'
              ? capitalize(value.value)
              : findUserFromId(value.value)
                    .map(user => `${user.firstName} ${user.lastName}`)
                    .orSome('loading...')
    }
}

export const useGetUpdateFieldDescription = () => (action: AutomationActionUpdateSchema) => {
    const listingConfigApi = useListingConfigApi()

    switch (action.updateType) {
        case 'additionalDetails':
            const config = listingConfigApi.maybeData.orSome([]).find(x => x.id == action.updateFieldName)

            return `Set ${config?.configName} to ${action.updateFieldValue}`
        default:
            return 'error'
    }
}

const AutomationDetails = ({ automation }: { automation: InternalAutomationSchema }) => {
    const api = useApi()
    const showSnack = useShowSnack()
    const emailOrUserToString = useEmailOrUserToString()
    const getUpdateFieldDescription = useGetUpdateFieldDescription()
    const sharedTodosApi = useSharedTodosApi(true)

    const { reset, control, handleSubmit, formState, getValues, setValue, watch } =
        useForm<UpdateAutomationSchema>({
            defaultValues: {
                ...automation,
            },
            resolver: zodResolver(UpdateAutomationSchema),
        })

    useEffect(() => {
        reset(automation)
    }, [automation.automationId])

    const {
        fields: triggerEquations,
        append: addTrigger,
        remove: removeTrigger,
        update: updateTrigger,
    } = useFieldArray({
        control,
        name: 'triggerEquation',
    })

    const automationName = watch('automationName')

    const {
        fields: actions,
        append: addAction,
        remove: removeAction,
        update: updateAction,
    } = useFieldArray({
        control,
        name: 'actions',
    })

    if (Object.keys(formState.errors).length > 0) {
        console.log(formState.errors)
    }

    const [selectedConditionIndex, setSelectedConditionIndex] = useState<number | null | undefined>(null)
    const selectedCondition =
        selectedConditionIndex != undefined
            ? triggerEquations[selectedConditionIndex].trigger
            : selectedConditionIndex

    const [selectedActionIndex, setSelectedActionIndex] = useState<number | null | undefined>(null)
    const selectedAction =
        selectedActionIndex != undefined ? actions[selectedActionIndex] : selectedActionIndex

    const [loading, setLoading] = useState(false)
    const [maybeEditName, setEditName] = useMaybeState<string>()

    const onSubmit = async (data: UpdateAutomationSchema) => {
        setLoading(true)
        try {
            await api.post('/automation/addorupdate', data)
            showSnack('Automation saved', 'success')
        } catch (e) {
            showSnack('Failed to save automation', 'danger')
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <form
                noValidate
                onSubmit={handleSubmit(onSubmit)}
            >
                <Stack gap={4}>
                    <Stack
                        direction="row"
                        gap={2}
                        alignItems="center"
                    >
                        {maybeEditName
                            .map(editName => (
                                <Stack
                                    direction="row"
                                    gap={2}
                                >
                                    <Input
                                        value={editName}
                                        onChange={setEditName}
                                    />
                                    <IconButton
                                        size="sm"
                                        onClick={() => {
                                            setValue('automationName', editName)
                                            setEditName(undefined)
                                        }}
                                    >
                                        <Save fontSize="small" />
                                    </IconButton>
                                    <IconButton
                                        size="sm"
                                        onClick={() => setEditName(undefined)}
                                    >
                                        <Cancel fontSize="small" />
                                    </IconButton>
                                </Stack>
                            ))
                            .orSome(
                                <>
                                    <Typography level="h2">{automationName}</Typography>
                                    <IconButton
                                        size="sm"
                                        onClick={() => setEditName(automationName)}
                                    >
                                        <Edit fontSize="small" />
                                    </IconButton>
                                </>,
                            )}
                    </Stack>
                    <Box>
                        <Typography level="h4">Conditions</Typography>
                        {triggerEquations.map((equation, i) => (
                            <ButtonSheet
                                key={equation.id}
                                variant="outlined"
                                selected={selectedCondition == equation.trigger}
                                onClick={() => setSelectedConditionIndex(i)}
                                sx={{ my: 1 }}
                            >
                                {getFieldDescription(equation.trigger.triggerFieldName as any)}{' '}
                                {getTriggerDescription(equation.trigger.triggerType)}
                                {(equation.trigger as any).triggerFieldValue
                                    ? ` [${(equation.trigger as any).triggerFieldValue.join(', ')}]`
                                    : ''}
                            </ButtonSheet>
                        ))}
                        <Button
                            variant="outlined"
                            sx={{ mt: 2 }}
                            onClick={() => setSelectedConditionIndex(undefined)}
                        >
                            Add Condition
                        </Button>
                        <Typography
                            level="h4"
                            sx={{ mt: 4 }}
                        >
                            Actions
                        </Typography>
                        {actions.map((action, i) => (
                            <ButtonSheet
                                key={action.id}
                                variant="outlined"
                                sx={{ my: 1, display: 'flex', alignItems: 'center', gap: 1 }}
                                onClick={() => setSelectedActionIndex(i)}
                            >
                                {(action.actionType == 'email' || action.actionType == 'notification') && (
                                    <>
                                        Send {action.actionType} to
                                        {action.toUsers.map(x => (
                                            <Chip
                                                color="primary"
                                                key={x.value}
                                            >
                                                {emailOrUserToString(x)}
                                            </Chip>
                                        ))}
                                    </>
                                )}
                                {action.actionType == 'update' && <>{getUpdateFieldDescription(action)}</>}
                                {action.actionType == 'sharedTodo' && (
                                    <>
                                        Add todo to{' '}
                                        {sharedTodosApi.data?.find(x => x.id == action.todoId)?.name ??
                                            'loading...'}
                                    </>
                                )}
                            </ButtonSheet>
                        ))}
                        <Button
                            variant="outlined"
                            sx={{ mt: 2 }}
                            onClick={() => setSelectedActionIndex(undefined)}
                        >
                            Add Action
                        </Button>
                        <Box sx={{ mt: 6 }}>
                            <Button
                                type="submit"
                                loading={loading}
                                startDecorator={<Save />}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Stack>
            </form>
            <AddConditionModal
                editCondition={selectedCondition}
                close={() => setSelectedConditionIndex(null)}
                onSet={(data: AutomationTriggerSchema) => {
                    if (selectedCondition != undefined) {
                        console.log('editing trigger')
                        updateTrigger(selectedConditionIndex!, {
                            ...triggerEquations[selectedConditionIndex!],
                            trigger: data,
                        })
                    } else {
                        console.log('adding trigger')
                        addTrigger({
                            trigger: data,
                            condition: 'and',
                        })
                    }
                }}
                onDelete={() => {
                    if (selectedConditionIndex != undefined) {
                        removeTrigger(selectedConditionIndex)
                        setSelectedConditionIndex(null)
                    }
                }}
            />
            <AddActionModal
                editAction={selectedAction}
                close={() => setSelectedActionIndex(null)}
                onSet={data => {
                    if (selectedActionIndex != undefined) {
                        console.log('editing action')
                        updateAction(selectedActionIndex!, data)
                    } else {
                        console.log('adding action')
                        addAction(data)
                    }
                }}
                onDelete={() => {
                    if (selectedActionIndex != undefined) {
                        removeAction(selectedActionIndex)
                        setSelectedActionIndex(null)
                    }
                }}
            />
            {/*<Modal
                open={maybeEditName.isSome()}
                onClose={dontCloseOnBackgroundClick(() => setEditName(undefined))}
            >
                <ModalDialog>
                    <ModalClose />
                    <DialogTitle>Edit Name</DialogTitle>
                    <DialogContent>

                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => setEditName(undefined)}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                setValue('automationName', maybeEditName.orSome(''))
                                setEditName(undefined)
                            }}
                        >
                            Save
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>*/}
        </>
    )
}

export default () => {
    const [selectedAutomation, setSelectedAutomation] = useState<InternalAutomationSchema | undefined>(
        undefined,
    )

    return (
        <Stack
            direction="row"
            gap={6}
            height="100%"
        >
            <Box>
                <AutomationList {...{ selectedAutomation, setSelectedAutomation }} />
            </Box>
            <Box>{selectedAutomation && <AutomationDetails automation={selectedAutomation} />}</Box>
        </Stack>
    )
}
