import z from 'zod'

export const SalesListingColumns = [
    'status',
    'agent',
    'agentAppointmentForm',
    'contractForm',
    'authorityEnd',
    'settlementDate',
    'contractDate',
    //'unconditionalDate',
] as const

export const getColumnName = (key: (typeof SalesListingColumns)[number]) =>
    ({
        status: 'Status',
        agent: 'Agent',
        agentAppointmentForm: 'Form 6',
        contractForm: 'Contract',
        authorityEnd: 'Authority End',
        settlementDate: 'Settlement Date',
        contractDate: 'Contract Date',
        //unconditionalDate: 'Unconditional Date',
    })[key]

export const SingleColumnCongifSchema = z
    .object({
        key: z.enum(SalesListingColumns),
    })
    .or(
        z.object({
            key: z.literal('action'),
            actionId: z.string(),
        }),
    )

export type SingleColumnCongifSchema = z.infer<typeof SingleColumnCongifSchema>

export const defaultColumnConfig: SingleColumnCongifSchema[] = [
    {
        key: 'status',
    },
    {
        key: 'agent',
    },
    {
        key: 'agentAppointmentForm',
    },
    {
        key: 'contractForm',
    },
    {
        key: 'authorityEnd',
    },
]
