import { InternalRoleSchema } from '@fastre/core/src/schemas/roles'
import { Box, Button, Chip, Modal, Typography, useTheme } from '@mui/joy'
import List from '@mui/joy/List'
import ListDivider from '@mui/joy/ListDivider'
import ListItem from '@mui/joy/ListItem'
import ListItemContent from '@mui/joy/ListItemContent'
import { useRolesApi, useUsersApi } from 'apiProviders'
import { useUserData } from 'auth'
import Loading from 'components/Loading'
import { dontCloseOnBackgroundClick } from 'components/modal'
import SectionHead from 'components/sectionHead'
import { useMaybeState } from 'helperFunctions/react'
import { sortBy } from 'ramda'
import { EditRoleModal } from './editRoleModal'

const RolesPage = () => {
    const theme = useTheme()
    const usersApi = useUsersApi()
    const rolesApi = useRolesApi()
    const { user } = useUserData()

    const [maybeEditRole, setEditRole] = useMaybeState<{} | InternalRoleSchema>()

    return (
        <Box
            sx={{
                px: {
                    xs: 2,
                    sm: 4,
                },
            }}
        >
            <SectionHead title="Roles" />
            {rolesApi.maybeData
                .map(role => (
                    <Box
                        //sx={{ display: { xs: 'block', sm: 'none' } }}
                        sx={{ maxWidth: theme.breakpoints.values.sm + 'px' }}
                    >
                        <>
                            {sortBy(role => role.roleName.toLowerCase(), role).map(role => (
                                <List
                                    key={role.id}
                                    size="sm"
                                    sx={{
                                        '--ListItem-paddingX': 0,
                                    }}
                                >
                                    <ListItem
                                        component="a"
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'start',
                                            cursor: 'pointer',
                                            textDecoration: 'none',
                                            ':hover': {
                                                backgroundColor: 'var(--joy-palette-background-level2)',
                                                borderRadius: 'sm',
                                            },
                                            px: 1,
                                            pt: 1,
                                        }}
                                        onClick={() => setEditRole(role)}
                                    >
                                        <ListItemContent
                                            sx={{
                                                display: 'flex',
                                                gap: 4,
                                                alignItems: 'start',
                                            }}
                                        >
                                            <Typography
                                                fontWeight={600}
                                                gutterBottom
                                            >
                                                {role.roleName}
                                            </Typography>
                                            <Box sx={{ flexGrow: 1 }} />
                                            <Box>
                                                {usersApi.maybeData
                                                    .orSome([])
                                                    .filter(user => user.roleId == role.id)
                                                    .map(user => (
                                                        <Chip
                                                            key={user.userId}
                                                            variant="soft"
                                                            size="sm"
                                                            sx={{
                                                                mx: 1,
                                                            }}
                                                        >
                                                            {user.firstName} {user.lastName}
                                                        </Chip>
                                                    ))}
                                            </Box>
                                        </ListItemContent>
                                    </ListItem>
                                    <ListDivider />
                                </List>
                            ))}
                        </>
                        {user.permissions.includes('roles.edit') && (
                            <Button
                                sx={{ mt: 2 }}
                                onClick={() => setEditRole({})}
                                variant="outlined"
                            >
                                New Role
                            </Button>
                        )}
                    </Box>
                ))
                .orSome(<Loading />)}
            <Modal
                open={maybeEditRole.isSome()}
                onClose={dontCloseOnBackgroundClick(() => setEditRole(undefined))}
            >
                {usersApi.maybeData
                    .map(users =>
                        maybeEditRole
                            .map(role => (
                                <EditRoleModal
                                    role={role}
                                    users={users}
                                    close={() => setEditRole(undefined)}
                                />
                            ))
                            .orSome(<></>),
                    )
                    .orSome(<Loading />)}
            </Modal>
        </Box>
    )
}

export default RolesPage
