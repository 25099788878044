import { Box, FormControl, FormLabel, Option, Select } from '@mui/joy'
import { useUserData } from 'auth'
import SectionHead from 'components/sectionHead'
import { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router'
import EditUser from './editUser'
import CoreEditUser from './editUser/coreEditUser'
import UsersTableMobile from './usersTableMobile'

export const AddUser = () => (
    <Box
        sx={{
            px: {
                xs: 2,
                sm: 4,
            },
        }}
    >
        <CoreEditUser />
    </Box>
)

const UsersList = () => {
    const { user } = useUserData()

    const [sort, setSort] = useState<'first' | 'last'>((localStorage.getItem('userSort') as any) ?? 'last')

    useEffect(() => {
        localStorage.setItem('userSort', sort)
    }, [sort])

    return (
        <>
            <SectionHead
                title="Users"
                buttons={
                    user.permissions.includes('users.view') && (
                        <FormControl>
                            <FormLabel>Sort By</FormLabel>
                            <Select
                                value={sort}
                                onChange={(e, val) => setSort(val!)}
                            >
                                <Option value="first">First Name</Option>
                                <Option value="last">Last Name</Option>
                            </Select>
                        </FormControl>
                    )
                }
            />
            <UsersTableMobile sort={sort} />
        </>
    )
}

export default () => {
    return (
        <Box
            sx={{
                px: {
                    xs: 2,
                    sm: 4,
                },
            }}
        >
            <Routes>
                <Route
                    path=":userId"
                    element={<EditUser />}
                />
                <Route
                    index
                    element={<UsersList />}
                />
            </Routes>
        </Box>
    )
}
