import z from 'zod'
import { AutomationActionSchema } from './action'
import { AutomationTriggerEquationSchema } from './condition'

export type EmailOrUser =
    | {
          type: 'email' | 'user'
          value: string
      }
    | {
          type: 'special'
          value: 'agent'
      }

export const UpdateAutomationSchema = z.object({
    automationId: z.string().optional(),
    active: z.enum(['true', 'false']).default('true'),
    automationName: z.string(),

    triggerEquation: z.array(AutomationTriggerEquationSchema),
    actions: z.array(AutomationActionSchema),
})

export type UpdateAutomationSchema = z.infer<typeof UpdateAutomationSchema>

export type InternalAutomationSchema = UpdateAutomationSchema & {
    orgId: string
    automationId: string

    allTriggerFieldNames: string[]

    createdByUserId: string
    createdDate: number
    lastUpdatedDate: number
}
