import { AddManualAgentStatementSchema } from '@fastre/core/src/schemas/agentStatement'
import { zodResolver } from '@hookform/resolvers/zod'
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Modal,
    ModalClose,
    ModalDialog,
    Option,
    Select,
    Stack,
} from '@mui/joy'
import { useApi } from 'api'
import { useAgentStatementApi } from 'apiProviders'
import { SlotInput, SlotWrapper } from 'components/input'
import { dontCloseOnBackgroundClick } from 'components/modal'
import { useShowSnack } from 'components/snackbar'
import { format } from 'date-fns'
import { prop } from 'ramda'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

interface Props {
    userId: string
    open: boolean
    onClose: () => void
}

export default function AddAgentStatementModal({ userId, open, onClose }: Props) {
    const api = useApi()
    const showSnack = useShowSnack()
    const agentStatementApi = useAgentStatementApi(userId)

    const { control, reset, handleSubmit } = useForm<AddManualAgentStatementSchema>({
        resolver: zodResolver(AddManualAgentStatementSchema),
    })

    useEffect(() => {
        reset({
            userId,
            date: format(new Date(), 'yyyy-MM-dd'),
            type: undefined,
        })
    }, [open])

    const [Loading, setLoading] = useState(false)

    const onSubmit = async data => {
        setLoading(true)
        try {
            await api.post('/createagentstatement', data).then(prop('data'))
            await agentStatementApi.refresh()
            showSnack('Statement created', 'success')
            onClose()
        } catch (e) {
            showSnack('Failed to create statement', 'danger')
        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal
            open={open}
            onClose={dontCloseOnBackgroundClick(onClose)}
        >
            <form
                noValidate
                onSubmit={handleSubmit(onSubmit)}
            >
                <ModalDialog>
                    <ModalClose />
                    <DialogTitle>Create Manual Statement Item</DialogTitle>
                    <DialogContent>
                        <Stack
                            spacing={2}
                            sx={{ mt: 1 }}
                        >
                            <Controller
                                name="date"
                                control={control}
                                render={field => (
                                    <SlotInput
                                        {...field}
                                        label="Date"
                                        type="date"
                                    />
                                )}
                            />
                            <Controller
                                name="description"
                                control={control}
                                render={field => (
                                    <SlotInput
                                        {...field}
                                        label="Description"
                                    />
                                )}
                            />
                            <Controller
                                name="type"
                                control={control}
                                render={field => (
                                    <SlotWrapper
                                        label="Type"
                                        {...field}
                                    >
                                        <Select
                                            {...field.field}
                                            onChange={(e, value) => field.field.onChange(value)}
                                        >
                                            <Option value="credit">Credit</Option>
                                            <Option value="debit">Debit</Option>
                                        </Select>
                                    </SlotWrapper>
                                )}
                            />
                            <Controller
                                name="amount"
                                control={control}
                                render={field => (
                                    <SlotInput
                                        {...field}
                                        label="Amount"
                                        type="dollar"
                                    />
                                )}
                            />
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            loading={Loading}
                            type="submit"
                        >
                            Save
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </form>
        </Modal>
    )
}
