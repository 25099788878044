import { Box, Button } from '@mui/joy'
import { useApi } from 'api'
import Input from 'components/input'
import { useState } from 'react'

const TsChat = () => {
    const api = useApi()
    const [message, setMessage] = useState('')

    return (
        <Box
            sx={{
                mx: 4,
            }}
        >
            <h1>TS Chat</h1>
            <Input
                value={message}
                onChange={setMessage}
                placeholder="Type a message"
            />
            <Button onClick={() => api.post('/tschat', { message })}>Send</Button>
        </Box>
    )
}

export default TsChat
