import { VendorSchema, VendorType } from '@fastre/core/src/schemas/vendor'
import { DeleteRounded, WarningRounded } from '@mui/icons-material'
import {
    Box,
    FormControl,
    FormLabel,
    IconButton,
    Radio,
    RadioGroup,
    Sheet,
    Stack,
    Tooltip,
    Typography,
} from '@mui/joy'
import { capitalize } from '@mui/material'
import { SlotInput } from 'components/input'
import { MobileFriendlyStack } from 'components/stack'
import { Controller, useFormContext } from 'react-hook-form'

interface VendorListProps {
    name: string
    vendors: Array<VendorSchema & { id: string }>
    removeVendor: (index: number) => void
    vendorType: VendorType
    setVendorType: (type: VendorType) => void
    extraFields?: (vendor: VendorSchema, index: number) => React.ReactNode
    showOccupantStatus: boolean
    disabled?: boolean
    showAlert?: boolean
    hideTitle?: boolean
}

export default ({
    name,
    vendors,
    removeVendor,
    vendorType,
    setVendorType,
    extraFields,
    showOccupantStatus,
    disabled,
    showAlert,
    hideTitle,
}: VendorListProps) => {
    const { control, setValue } = useFormContext()

    const fieldName = name.toLocaleLowerCase()

    return (
        <>
            <Stack
                direction="row"
                sx={{
                    alignItems: 'center',
                    gap: 2,
                }}
            >
                {!hideTitle && <Typography level="h4">{name}s</Typography>}
                {showAlert && (
                    <Tooltip title="See sensitive information">
                        <WarningRounded
                            fontSize={'lg' as any}
                            color="warning"
                        />
                    </Tooltip>
                )}
            </Stack>
            <MobileFriendlyStack>
                <FormControl sx={{ flex: '1' }}>
                    <FormLabel>{name} Type</FormLabel>
                    <RadioGroup
                        orientation="horizontal"
                        value={vendorType}
                        onChange={e => {
                            setVendorType(e.target.value as any)
                            vendors.forEach((_, i) => {
                                setValue(`${fieldName}s.${i}.vendorType`, e.target.value as any)
                            })
                        }}
                    >
                        <Radio
                            value="individual"
                            label="Individual"
                            disabled={disabled}
                        />
                        <Radio
                            value="company"
                            label="Company"
                            disabled={disabled}
                        />
                    </RadioGroup>
                </FormControl>
                {showOccupantStatus && vendorType == 'individual' && (
                    <Controller
                        name="occupantStatus"
                        control={control}
                        render={field => (
                            <FormControl sx={{ flex: '1' }}>
                                <FormLabel>Occupant Status</FormLabel>
                                <RadioGroup
                                    orientation="horizontal"
                                    {...field.field}
                                    onChange={e => field.field.onChange(e.target.value)}
                                >
                                    <Radio
                                        value="owner occupied"
                                        label="Owner Occupied"
                                        disabled={disabled}
                                    />
                                    <Radio
                                        value="investment"
                                        label="Tenanted"
                                        disabled={disabled}
                                    />
                                </RadioGroup>
                            </FormControl>
                        )}
                    />
                )}
            </MobileFriendlyStack>
            <Stack gap={2}>
                {vendors.map((vendor, i) => (
                    <Sheet
                        key={vendor.id}
                        variant="outlined"
                        sx={{
                            p: 2,
                            borderRadius: 'md',
                        }}
                    >
                        <Stack
                            direction="row"
                            sx={{ mb: 2, justifyContent: 'space-between' }}
                        >
                            <Typography>
                                {capitalize(name)} {i + 1}
                            </Typography>
                            {!disabled && (
                                <Box>
                                    <IconButton
                                        variant="outlined"
                                        onClick={() => removeVendor(i)}
                                        size="sm"
                                    >
                                        <DeleteRounded fontSize="small" />
                                    </IconButton>
                                </Box>
                            )}
                        </Stack>
                        <Stack gap={2}>
                            {vendorType == 'company' && (
                                <>
                                    <Controller
                                        name={`${fieldName}s.${i}.companyName`}
                                        disabled={disabled}
                                        control={control}
                                        render={field => (
                                            <SlotInput
                                                label="Company Name"
                                                {...field}
                                            />
                                        )}
                                    />

                                    <MobileFriendlyStack gap={2}>
                                        <Controller
                                            name={`${fieldName}s.${i}.abn`}
                                            disabled={disabled}
                                            control={control}
                                            render={field => (
                                                <SlotInput
                                                    label="ABN"
                                                    {...field}
                                                    formControlProps={{
                                                        sx: { flex: 1 },
                                                    }}
                                                />
                                            )}
                                        />
                                        <Controller
                                            name={`${fieldName}s.${i}.acn`}
                                            disabled={disabled}
                                            control={control}
                                            render={field => (
                                                <SlotInput
                                                    label="ACN"
                                                    {...field}
                                                    formControlProps={{
                                                        sx: { flex: 1 },
                                                    }}
                                                />
                                            )}
                                        />
                                    </MobileFriendlyStack>
                                </>
                            )}
                            <MobileFriendlyStack gap={2}>
                                <Controller
                                    name={`${fieldName}s.${i}.firstName`}
                                    disabled={disabled}
                                    control={control}
                                    render={field => (
                                        <SlotInput
                                            label="First Name"
                                            {...field}
                                            formControlProps={{
                                                sx: { flex: 1 },
                                            }}
                                        />
                                    )}
                                />
                                <Controller
                                    name={`${fieldName}s.${i}.lastName`}
                                    disabled={disabled}
                                    control={control}
                                    render={field => (
                                        <SlotInput
                                            label="Last Name"
                                            {...field}
                                            formControlProps={{
                                                sx: { flex: 1 },
                                            }}
                                        />
                                    )}
                                />
                            </MobileFriendlyStack>
                            <MobileFriendlyStack gap={2}>
                                <Controller
                                    name={`${fieldName}s.${i}.email`}
                                    disabled={disabled}
                                    control={control}
                                    render={field => (
                                        <SlotInput
                                            label="Email"
                                            {...field}
                                            formControlProps={{
                                                sx: { flex: 1 },
                                            }}
                                        />
                                    )}
                                />
                                <Controller
                                    name={`${fieldName}s.${i}.mobileNumber`}
                                    disabled={disabled}
                                    control={control}
                                    render={field => (
                                        <SlotInput
                                            label="Phone Number"
                                            {...field}
                                            formControlProps={{
                                                sx: { flex: 1 },
                                            }}
                                        />
                                    )}
                                />
                            </MobileFriendlyStack>
                            {extraFields && extraFields(vendor, i)}
                        </Stack>
                    </Sheet>
                ))}
            </Stack>
        </>
    )
}
