import { InternalCalendarEventSchema } from '@fastre/core/src/schemas/calendar'
import { EventImpl } from '@fullcalendar/core/internal'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import { AddRounded } from '@mui/icons-material'
import { Box, Button, useTheme } from '@mui/joy'
import { useApi } from 'api'
import { useCalendarEventsApi } from 'apiProviders'
import { useUserData } from 'auth'
import { dontCloseOnBackgroundClick } from 'components/modal'
import SectionHead from 'components/sectionHead'
import { useShowSnack } from 'components/snackbar'
import { pick } from 'ramda'
import { useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { v4 as uuid } from 'uuid'
import EditEventModal from './editEventModal'

let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today

export const INITIAL_EVENTS = [
    {
        id: uuid(),
        title: 'All-day event',
        start: todayStr,
    },
    {
        id: uuid(),
        title: 'Timed event',
        start: todayStr + 'T12:00:00',
    },
]

const toEventObj = (event: EventImpl): InternalCalendarEventSchema => ({
    //...dissoc('extendedProps', event.toPlainObject()),
    ...pick(['id', 'allDay', 'title'], event),
    ...(event.extendedProps as any),
    start: event.start!.getTime(),
    end: event.end?.getTime(),
})

export default () => {
    const theme = useTheme()
    const { user } = useUserData()
    const api = useApi()
    const eventsApi = useCalendarEventsApi()
    const showSnack = useShowSnack()

    const [editEvent, setEditEvent] = useState<InternalCalendarEventSchema | null | undefined>(null)

    const [params, setParams] = useState(eventsApi.params)
    const [debouncedParams] = useDebounce(params, 200)
    useEffect(() => {
        eventsApi.setParams(params)
    }, [debouncedParams])

    return (
        <>
            <Box
                sx={{
                    px: {
                        xs: 2,
                        sm: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    },
                }}
            >
                <SectionHead
                    title="Calendar"
                    buttons={
                        <Button
                            component="a"
                            startDecorator={<AddRounded />}
                            onClick={() => setEditEvent(undefined)}
                        >
                            Add Event
                        </Button>
                    }
                />
                <Box sx={{ flexGrow: 1 }}>
                    <FullCalendar
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        headerToolbar={{
                            left: 'prev,next today',
                            center: 'title',
                            right: 'dayGridMonth,timeGridWeek,timeGridDay',
                        }}
                        datesSet={info =>
                            setParams({
                                start: Date.parse(info.startStr),
                                end: Date.parse(info.endStr),
                            })
                        }
                        initialView="dayGridMonth"
                        events={(eventsApi.data ?? []).map(event => ({
                            ...event,
                            color: event.fromListing ? theme.palette.danger[500] : theme.palette.primary[500],
                        }))}
                        editable={true}
                        selectable={true}
                        selectMirror={true}
                        dayMaxEvents={true}
                        eventChange={e => {
                            api.post('/calendar/events', toEventObj(e.event)).catch(error => {
                                console.error(e)
                                e.revert()
                                showSnack('Something went wrong', 'danger')
                            })
                        }}
                        eventClick={e => {
                            //console.log('eventClick', e)
                            if (!e.event.extendedProps.fromListing) {
                                setEditEvent(toEventObj(e.event))
                            }
                        }}
                        height="100%"
                        locale={'en-au'}
                        /*                        eventMouseEnter={e => {
                            console.log('eventMouseEnter', e)
                            // set the position of the tooltip
                        }}
                        eventMouseLeave={() => {
                            console.log('eventMouseLeave')
                        }}
                        //weekends={weekendsVisible}
                        //initialEvents={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
                        /*eventSources={[
                        {
                            url: `${apiUrl}/${user.orgId}/calendar`,
                        },
                    ]}*/
                        /*events={(info, pass, fail): any => {
                        console.log('info', info)
                        api.post('/calendar/search', {
                            start: Date.parse(info.startStr),
                            end: Date.parse(info.endStr),
                        })
                            .then(({ data }) =>
                                pass(
                                    data.hits.map(prop('document')).evolve({
                                        start: Date.parse,
                                        end: Date.parse,
                                    }),
                                ),
                            )
                            .catch(fail)


                        
                    }}*/
                        /*select={handleDateSelect}
                    eventContent={renderEventContent} // custom render function
                    eventClick={handleEventClick}
                    eventsSet={handleEvents} // called after events are initialized/added/changed/removed*/

                        /* you can update a remote database when these fire:
                    eventAdd={function(){}}
                    eventChange={function(){}}
                    eventRemove={function(){}}
                    */
                    />
                </Box>
            </Box>
            <EditEventModal
                event={editEvent}
                onClose={dontCloseOnBackgroundClick(() => setEditEvent(null))}
            />
        </>
    )
}
