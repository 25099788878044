import { SaleListingSearchSchema, TypesenseListingSchema } from '@fastre/core/src/schemas/typesense'
import { Box } from '@mui/joy'
import { useApi } from 'api'
import { TSSearchResults } from 'apiProviders/core'
import { useUserData } from 'auth'
import Loading from 'components/Loading'
import AgentInput from 'components/agentInput'
import SectionHead from 'components/sectionHead'
import { startOfYear } from 'date-fns'
import { prop } from 'ramda'
import { useEffect, useState } from 'react'
import AgentDashboard from './agentDashboard'
import Leaderboard from './leaderboard'

const pageSize = 250
export async function getAllPaginatedTSData<T>(
    api: (page: number, limit: number) => Promise<TSSearchResults<T>>,
) {
    const initialCall = await api(1, pageSize)

    const remainingPages = initialCall.found / pageSize
    const otherPages = await Promise.all(
        Array.from({ length: remainingPages }, (_, i) => api(i + 2, pageSize)),
    )
    return [initialCall, ...otherPages].map(prop('hits')).flat().map(prop('document'))
}

export default () => {
    const api = useApi()
    const { user } = useUserData()

    const [listings, setListings] = useState<TypesenseListingSchema[]>()
    const [selectedUser, setSelectedUser] = useState(user.salesAssistantLeadAgentId ?? user.userId)

    const getListings = async () => {
        const yearStart = startOfYear(new Date()).getTime()

        const searchProps: Partial<SaleListingSearchSchema> = {
            customFilters: [`(authorityStartDate:>${yearStart} || statusLog-under contract:>${yearStart})`],
        }

        const listings = await getAllPaginatedTSData<TypesenseListingSchema>((page, limit) =>
            api.post('listings/sale/search', { ...searchProps, page, limit }).then(prop('data')),
        )

        setListings(listings)
    }

    useEffect(() => {
        getListings()
    }, [])

    if (!listings) return <Loading />

    return (
        <Box sx={{ mx: 4 }}>
            <SectionHead
                title="Dashboard"
                buttons={
                    user.superUser && (
                        <AgentInput
                            value={selectedUser}
                            onChange={setSelectedUser as any}
                            filter={x => x.salesAgent}
                        />
                    )
                }
            />
            <AgentDashboard
                allListings={listings}
                userId={selectedUser ?? user.userId}
            />
            <Leaderboard listings={listings} />
        </Box>
    )
}
