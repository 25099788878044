import { ArrowBackRounded, ArrowForwardRounded } from '@mui/icons-material'
import { Box, IconButton, Stack, Typography, TypographyProps } from '@mui/joy'
import { useLedgerMonth } from 'apiProviders'
import { addMonths, format } from 'date-fns'

export default function LedgerMonthSelector(props: TypographyProps) {
    const { month, setMonth } = useLedgerMonth()

    const addMonth = (n: number) => () => {
        setMonth(format(addMonths(month, n), 'yyyy-MM-01'))
    }

    return (
        <Stack
            direction="row"
            spacing={2}
            mt={1}
            mb={2}
        >
            <IconButton onClick={addMonth(-1)}>
                <ArrowBackRounded />
            </IconButton>
            <Box
                sx={{
                    width: '150px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography {...props}>{format(month, 'MMMM yyyy')}</Typography>
            </Box>
            <IconButton onClick={addMonth(1)}>
                <ArrowForwardRounded />
            </IconButton>
        </Stack>
    )
}
