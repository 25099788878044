import { CollectionFieldSchema } from 'typesense/lib/Typesense/Collection'
import z from 'zod'

export const CalendarEventSchema = z
    .object({
        title: z.string(),
        start: z.number(),
        calendarId: z.string(),
        userCalendar: z.boolean(),
    })
    .and(
        z.union([
            z.object({
                allDay: z.literal(false),
                end: z.number(),
            }),
            z.object({
                allDay: z.literal(true),
            }),
        ]),
    )

export type CalendarEventSchema = z.infer<typeof CalendarEventSchema>
export type InternalCalendarEventSchema = CalendarEventSchema & {
    orgId: string
    id: string
    fromListing?: boolean // calculated dates from listings
}

export const calendarEventTypesenseFields: CollectionFieldSchema[] = [
    {
        name: 'orgId',
        type: 'string',
        index: true,
    },
    {
        name: 'title',
        type: 'string',
    },
    {
        name: 'start',
        type: 'int64',
        range_index: true,
    },
    {
        name: 'end',
        type: 'int64',
        optional: true,
    },
    {
        name: 'allDay',
        type: 'bool',
    },
    {
        name: 'calendarId',
        type: 'string',
        facet: true,
    },
    {
        name: 'userCalendar',
        type: 'bool',
    },
]
