import { insertIf } from '@fastre/core/src/helperFunctions/array'
import { toCurrency } from '@fastre/core/src/helperFunctions/string'
import { formatAddress } from '@fastre/core/src/schemas/generic'
import { LedgerSchema } from '@fastre/core/src/schemas/ledger'
import { InternalSaleListingSchema } from '@fastre/core/src/schemas/saleListing'
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Modal,
    ModalDialog,
    Stack,
    Table,
    Typography,
} from '@mui/joy'
import { useApi } from 'api'
import { useBulkLedgerApi, useUsersApi } from 'apiProviders'
import DropZoneInner from 'components/dropzone'
import Loading from 'components/Loading'
import { dontCloseOnBackgroundClick } from 'components/modal'
import SectionHead from 'components/sectionHead'
import { useShowSnack } from 'components/snackbar'
import { format, parse, startOfMonth } from 'date-fns'
import { prop, uniq } from 'ramda'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { v4 as uuid } from 'uuid'
import { read, utils } from 'xlsx'

const parseDate = (date: any) => {
    console.log('date input', date)

    const parsedDate = typeof date == 'string' ? parse(date, 'dd/MM/yyyy', new Date()) : new Date(date)
    if ((parsedDate as any) == 'Invalid Date') {
        return undefined
    } else {
        return parsedDate
    }
}

export default function BulkUploadLedger() {
    const api = useApi()
    const usersApi = useUsersApi()
    const showSnack = useShowSnack()
    const bulkLedgerApi = useBulkLedgerApi()
    const navigate = useNavigate()

    const [data, setData] = useState<Array<LedgerSchema & { property?: InternalSaleListingSchema }>>()
    const [error, setError] = useState<string>()
    const [fileLoading, setFileLoading] = useState(false)
    const [uploadLoading, setUploadLoading] = useState(false)

    const handleFileUpload = async (file: File) => {
        setFileLoading(true)
        const workbook = read(await file.arrayBuffer(), {
            cellDates: true,
        })
        const data = utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]])

        const headers = utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
            header: 1,
        })[0] as string[]
        console.log('data', data)
        console.log('headers', headers)

        const requiredHeaders = [
            'Invoice Number',
            'Supplier',
            'Agent Name',
            'Debit',
            'Credit',
            'Invoice Date',
        ]
        const missingHeaders = requiredHeaders.filter(header => !headers.includes(header))
        console.log('missingHeaders', missingHeaders)

        if (missingHeaders.length > 0) {
            setError(`Missing headers: \n${missingHeaders.join('\n')}`)
        } else {
            const users = await usersApi.promiseData

            const userLedgerApprovalMap = await api
                .post(
                    'ledger/isapproved',
                    uniq(
                        data
                            .map((line: any) => {
                                const user = users.find(
                                    user =>
                                        `${user.firstName} ${user.lastName}`.toLowerCase() ==
                                        line['Agent Name'].toLowerCase(),
                                )

                                const date = parseDate(line['Invoice Date'])

                                return {
                                    userId: user?.userId,
                                    month: date ? startOfMonth(date) : undefined,
                                }
                            })
                            .map(x => {
                                console.log('step 1', x)
                                return x
                            })
                            .filter((x: any) => x.month != undefined && x.userId != undefined),
                    ).map(({ userId, month }) => ({
                        userId,
                        month: format(month!, 'yyyy-MM-01'),
                    })),
                )
                .then(prop('data'))

            console.log('userLedgerApprovalMap', userLedgerApprovalMap)

            const properties = uniq(
                data.map((line: any) => {
                    const user = users.find(
                        user =>
                            `${user.firstName} ${user.lastName}`.toLowerCase() ==
                            line['Agent Name'].toLowerCase(),
                    )

                    return {
                        propertyName: line['Property'] ?? line['Property (Optional)'],
                        userId: user?.userId,
                    }
                }),
            ).filter(x => x.userId != undefined)

            const foundProperties =
                properties.length > 0
                    ? await api
                          .post(
                              '/listings/sale/searchbyname',
                              properties.map(({ propertyName, userId }) => ({
                                  agentId: userId,
                                  search: propertyName,
                              })),
                          )
                          .then(prop('data'))
                    : []

            const lines: Array<
                | (LedgerSchema & {
                      success: true
                      property?: InternalSaleListingSchema
                  })
                | { success: false; error: string }
            > = await Promise.all(
                data.map(async (line: any, index) => {
                    const user = users.find(
                        user =>
                            `${user.firstName} ${user.lastName}`.toLowerCase().trim().replaceAll('  ', ' ') ==
                            line['Agent Name'].toLowerCase().trim().replaceAll('  ', ' '),
                    )
                    const date = parseDate(line['Invoice Date'])
                    const credit = line['Credit'] ? parseFloat(line['Credit']) : null
                    const debit = line['Debit'] ? parseFloat(line['Debit']) : null
                    const invoiceNumber = '' + line['Invoice Number']
                    const supplier = line['Supplier']
                    const description = line['Description'] ?? ''
                    const property = line['Property'] ?? line['Property (Optional)']

                    /*const foundProperty = property
                        ? await api
                              .post('/listings/sale/searchbyname', {
                                  agentId: user?.userId,
                                  search: property,
                              })
                              .then(prop('data'))
                        : undefined*/

                    const foundProperty = foundProperties?.find(
                        x => x.agentId == user?.userId && x.propertyName == property,
                    )?.result
                    //const foundProperty = undefined

                    const errors = [
                        ...insertIf(date == undefined, `Invalid Date`),
                        ...insertIf(user == undefined, `User not found: ${line['Agent Name']}`),
                        ...insertIf(invoiceNumber == undefined, `Invoice Number is required`),
                        ...insertIf(supplier == undefined, `Supplier is required`),
                        ...insertIf(credit == null && debit == null, `Credit or Debit is required`),
                        ...insertIf(
                            user != undefined &&
                                property != undefined &&
                                property != '' &&
                                foundProperty == undefined,
                            `Property not found: ${property}`,
                        ),
                        ...insertIf(
                            date != undefined &&
                                userLedgerApprovalMap.find(
                                    x => x.userId == user?.userId && x.month == format(date, 'yyyy-MM'),
                                )?.approved == true,
                            `Ledger for ${user?.firstName} ${format(date ?? Date.now(), 'MMM yyyy')} is already approved`,
                        ),
                    ]

                    console.log('date', date)

                    if (errors.length > 0) {
                        return {
                            success: false,
                            error: `Line ${index + 2}: ${errors.join(', ')}`,
                        }
                    } else {
                        return {
                            success: true,
                            id: uuid(),
                            userId: user!.userId,
                            listingId: undefined,
                            listingName: undefined,
                            debit,
                            credit,
                            description,
                            ledgerDate: format(date!, 'yyyy-MM-dd'),
                            supplier,
                            invoiceNumber,
                            approved: false,
                            property: foundProperty,
                        }
                    }
                }),
            )

            if (lines.some(line => !line.success)) {
                setError(
                    lines
                        .filter(line => !line.success)
                        .map((line: any) => line.error)
                        .join('\n'),
                )
            } else {
                setData(lines.map(line => line as LedgerSchema))
            }
        }
        setFileLoading(false)
    }

    return (
        <>
            <Box
                sx={{
                    px: {
                        xs: 2,
                        sm: 4,
                    },
                }}
            >
                <SectionHead
                    title="Bulk Upload"
                    breadcrumbs={['Ledger Manager']}
                />
                {!data && (
                    <>
                        {fileLoading && <Loading />}
                        {!fileLoading && (
                            <>
                                <DropZoneInner
                                    options={{
                                        accept: {
                                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                                                ['.xlsx'],
                                            'text/csv': ['.csv'],
                                        },
                                    }}
                                    onUpload={async files => {
                                        if (files.length > 0) {
                                            handleFileUpload(files[0])
                                        }
                                    }}
                                />
                                <Button
                                    variant="outlined"
                                    sx={{ mt: 2 }}
                                    onClick={() =>
                                        window.open('/Fastre Ledger Upload Template.xlsx', '_blank')
                                    }
                                >
                                    Download Template
                                </Button>
                            </>
                        )}
                    </>
                )}
                {data && (
                    <>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Agent Name</th>
                                    <th>Invoice Number</th>
                                    <th>Supplier</th>
                                    <th>Debit</th>
                                    <th>Credit</th>
                                    <th>Invoice Date</th>
                                    <th>Description</th>
                                    <th>Property</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map(line => (
                                    <tr key={line.id}>
                                        <td>
                                            {
                                                usersApi.data?.find(user => user.userId == line.userId)
                                                    ?.firstName
                                            }{' '}
                                            {
                                                usersApi.data?.find(user => user.userId == line.userId)
                                                    ?.lastName
                                            }
                                        </td>
                                        <td>{line.invoiceNumber}</td>
                                        <td>{line.supplier}</td>
                                        <td>{line.debit && toCurrency(line.debit)}</td>
                                        <td>{line.credit && toCurrency(line.credit)}</td>
                                        <td>{format(line.ledgerDate, 'dd/MM/yy')}</td>
                                        <td>{line.description}</td>
                                        <td>
                                            {line.property && formatAddress(line.property.listingAddress)}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <Stack
                            direction="row"
                            sx={{
                                gap: 2,
                                mt: 4,
                                float: 'right',
                            }}
                        >
                            <Button
                                variant="outlined"
                                color="danger"
                                onClick={() => {
                                    setData(undefined)
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                loading={uploadLoading}
                                onClick={async () => {
                                    console.log('submit', data)
                                    setUploadLoading(true)
                                    try {
                                        await api.post('/ledger/bulkupload', {
                                            items: data.map(({ property, ...item }) => ({
                                                ...item,
                                                ...(property && {
                                                    listingId: property.listingId,
                                                    listingName: formatAddress(property.listingAddress),
                                                }),
                                            })),
                                        })
                                        await bulkLedgerApi.refresh()
                                        navigate('..')
                                        showSnack('Ledgers uploaded', 'success')
                                    } catch (e: any) {
                                        showSnack('Error uploading ledgers', 'danger')
                                    } finally {
                                        setUploadLoading(false)
                                    }
                                }}
                            >
                                Upload
                            </Button>
                        </Stack>
                    </>
                )}
            </Box>
            <Modal
                open={error != undefined}
                onClose={dontCloseOnBackgroundClick(() => setError(undefined))}
            >
                <ModalDialog>
                    <DialogTitle>Error Uploading File</DialogTitle>
                    <DialogContent>
                        <Typography sx={{ whiteSpace: 'pre' }}>{error}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setError(undefined)}>Close</Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>
        </>
    )
}
