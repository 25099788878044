import { CloseRounded } from '@mui/icons-material'
import { Box, IconButton, Sheet, Stack, Typography, useTheme } from '@mui/joy'
import { useUserData } from 'auth'
import 'simplebar-react/dist/simplebar.min.css'

const ReplyChat = ({ replyChat, onClose }) => {
    const { user } = useUserData()
    const theme = useTheme()

    const isMe = replyChat.userId == user.userId

    return (
        <>
            <Sheet
                sx={{
                    borderRadius: 'sm',
                    ml: 2,
                    mb: 1,
                    backgroundColor: theme.palette.background.level2,
                    minHeight: 29,
                    display: 'flex',
                    alignItems: 'center',
                    overflow: 'hidden',
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 1,
                        backgroundColor: theme.palette.primary[500],
                        width: 6,
                        height: '100%',
                        borderRadius: 'sm',
                    }}
                />
                <Stack
                    sx={{
                        mx: 3,
                    }}
                >
                    <Typography
                        level="body-sm"
                        sx={{
                            //color: isMe ? 'primary.400' : 'text.primary',
                            color: 'primary.400',
                        }}
                    >
                        {isMe ? 'You' : replyChat.userName}
                    </Typography>
                    <Typography
                        level="body-sm"
                        sx={{
                            overflowWrap: 'anywhere',
                        }}
                    >
                        {replyChat.message}
                    </Typography>
                </Stack>
                {onClose && (
                    <IconButton
                        sx={{
                            position: 'absolute',
                            top: 4,
                            right: 4,
                            '--IconButton-size': '21px',
                        }}
                        size="sm"
                        onClick={onClose}
                    >
                        <CloseRounded fontSize="small" />
                    </IconButton>
                )}
            </Sheet>
        </>
    )
}

export default ReplyChat
