import z from 'zod'
import { ListingContractSchema } from './contract'
import { DocusignForm, DocusignOrManulForm } from './docusign'
import { AddressSchema } from './generic'
import { InternalListingSchema, ListingStatuses, UpdateListingSchema } from './listing'
import { ReiForm } from './rei'
import { Permission } from './roles'
import { FrontendUserSchema } from './userMembership'

export const ConjunctionalAgreementSchema = z
    .union([
        z.object({
            type: z.literal('external'),
            referrerName: z.string(),
        }),
        z.object({
            type: z.literal('internal'),
            agentId: z.string(),
        }),
    ])
    .and(
        z.union([
            z.object({
                agreementType: z.literal('fixed'),
                commissionAmount: z.number(),
            }),
            z.object({
                agreementType: z.literal('percentage'),
                commissionPercent: z.number(),
                comissionCap: z.number().optional(),
            }),
        ]),
    )

export const UpdateConjunctionalAgreementSchema = z.object({
    conjunctionalAgreements: z.array(ConjunctionalAgreementSchema),
})

export const UpdateSaleListingSchema = UpdateListingSchema.extend({
    commission: z.number().optional(), // Inclusive
    commissionIncEx: z.enum(['inc', 'ex']).optional().default('inc'),
    soldPrice: z.number().optional(),
    occupantStatus: z.enum(['owner occupied', 'investment']).optional(),
    listingType: z.enum(['sale', 'auction']).optional(),
    auctionDate: z.string().optional(),
    sensitiveInfo: z.string().optional(),
})

export const NewSaleListingSchema = UpdateSaleListingSchema.extend({
    listingStatus: z.enum(ListingStatuses).default('offline'),
    listingAddress: AddressSchema,
})

export type ConjunctionalAgreementSchema = z.infer<typeof ConjunctionalAgreementSchema>
export type UpdateConjunctionalAgreementSchema = z.infer<typeof UpdateConjunctionalAgreementSchema>
export type UpdateSaleListingSchema = z.infer<typeof UpdateSaleListingSchema>
export type InternalSaleListingSchema = InternalListingSchema &
    UpdateSaleListingSchema & {
        listingId: string
        conjunctionalAgreements?: ConjunctionalAgreementSchema[]
        contract?: ListingContractSchema
        agentAppointmentForm?: ReiForm
        contractForm?: DocusignOrManulForm
        disclosureStatementForm?: DocusignOrManulForm
        fundDisbursementForm?: DocusignForm
    }

export const listingEnabledForUser = (
    listing: InternalSaleListingSchema,
    usersData: FrontendUserSchema[] | undefined,
    user: FrontendUserSchema & { permissions: Permission[] },
) =>
    user.permissions.includes('listings.fullControl') ||
    (user.permissions.includes('listings.edit') &&
        (listing.agentUserId == user.userId ||
            listing.agentUserId == user.salesAssistantLeadAgentId ||
            (user.salesAssistantConjunctionalAgencyId != undefined &&
                usersData?.find(x => x.userId == listing.agentUserId)?.conjunctionalAgencyId ==
                    user.salesAssistantConjunctionalAgencyId)))
