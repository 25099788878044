import { SaleListingSearchSchema } from '@fastre/core/src/schemas/typesense'
import { ArrowBackRounded, ArrowForwardRounded } from '@mui/icons-material'
import { Box, IconButton, Stack, Typography } from '@mui/joy'
import { useApi } from 'api'
import Loading from 'components/Loading'
import { MobileFriendlyStack } from 'components/stack'
import { addMonths, endOfMonth, format, startOfMonth } from 'date-fns'
import { prop } from 'ramda'
import { useEffect, useState } from 'react'
import MainTable from './mainTable'
import MonthSummary from './monthSummary'
import SummarySection from './summarySection'
import { ListingsSummary } from './types'

export default function SalesBoard() {
    const api = useApi()

    const getListingSummary = async (listingsMonth: Date): Promise<ListingsSummary> => {
        const newSearchProps: Partial<SaleListingSearchSchema> = {
            limit: 250,
            page: 1,
            customFilters: [
                `statusLog-offline:>=${startOfMonth(listingsMonth).getTime()}`,
                `statusLog-offline:<=${endOfMonth(listingsMonth).getTime()}`,
            ],
            sortDirection: 'asc',
        }
        const newListings = await api
            .post('listings/sale/search', newSearchProps)
            .then(({ data }) => data.hits.map(prop('document')))

        const ucSearchProps: Partial<SaleListingSearchSchema> = {
            limit: 250,
            page: 1,
            customFilters: [
                `contract.contractDate:>=${startOfMonth(listingsMonth).getTime()}`,
                `contract.contractDate:<=${endOfMonth(listingsMonth).getTime()}`,
            ],
            sortDirection: 'asc',
        }
        const underContract = await api
            .post('listings/sale/search', ucSearchProps)
            .then(({ data }) => data.hits.map(prop('document')))

        return { newListings, underContract }
    }

    const [month, setMonth] = useState(new Date())
    const addMonth = (amount: number) => () => setMonth(addMonths(month, amount))

    const [thisMonth, setThisMonth] = useState<ListingsSummary>()
    const [lastMonth, setLastMonth] = useState<ListingsSummary>()

    useEffect(() => {
        const fetchData = async () => {
            try {
                setThisMonth(undefined)
                getListingSummary(month).then(setThisMonth)

                setLastMonth(undefined)
                getListingSummary(addMonths(month, -1)).then(setLastMonth)
            } catch (error) {
                console.error('Error fetching data:', error)
            }
        }

        fetchData()
    }, [month])

    const currentMonthContracts = thisMonth?.underContract

    const loading = !thisMonth || !lastMonth

    return (
        <Box sx={{ padding: 4 }}>
            <Stack
                direction="row"
                spacing={2}
                mt={1}
                mb={2}
            >
                <IconButton onClick={addMonth(-1)}>
                    <ArrowBackRounded />
                </IconButton>
                <Box
                    sx={{
                        width: '260px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography level="h2">{format(month, 'MMMM yyyy')}</Typography>
                </Box>
                <IconButton onClick={addMonth(1)}>
                    <ArrowForwardRounded />
                </IconButton>
            </Stack>
            {loading && <Loading />}
            {!loading && (
                <>
                    <SummarySection soldListings={currentMonthContracts!} />
                    <MainTable listings={currentMonthContracts!} />

                    <MobileFriendlyStack breakpoint="lg">
                        <MonthSummary
                            listings={lastMonth!}
                            month={addMonths(month, -1)}
                        />
                        <MonthSummary
                            listings={thisMonth}
                            month={month}
                        />
                    </MobileFriendlyStack>
                </>
            )}
        </Box>
    )
}
