import { always, cond, equals, flip, includes, T } from 'ramda'

export const condSwitch = <TCond extends any, TVal>(
    cases: [TCond, TVal][],
    defaultCase: TVal,
): ((inputVal: TCond) => TVal) => {
    return cond([
        ...(cases.map(([cond, val]) => [equals(cond), always(val)]) as [
            (val: TCond) => boolean,
            () => TVal,
        ][]),
        [T, always(defaultCase)],
    ])
}

export const includedIn = flip(includes)
