import { AddressSchema, formatAddress } from '@fastre/core/src/schemas/generic'
import { useMapsLibrary } from '@vis.gl/react-google-maps'
import { useEffect, useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { AutocompleteProps, SlotAutocomplete } from './autocomplete'
import { useShowSnack } from './snackbar'

interface LocationAutocompleteProps
    extends Omit<
        AutocompleteProps<{ description: string; placeId?: string }, false, false, false>,
        'error' | 'options' | 'value' | 'onChange'
    > {
    field
    formState
    value: AddressSchema
    onChange: (value: AddressSchema, geo: [number, number]) => void
}

const LocationAutocomplete = (props: LocationAutocompleteProps) => {
    const placesLib = useMapsLibrary('places')
    const geocodingLib = useMapsLibrary('geocoding')
    const geocoder = useMemo(() => geocodingLib && new geocodingLib.Geocoder(), [geocodingLib])
    const autoCompleteService = useMemo(() => placesLib && new placesLib.AutocompleteService(), [placesLib])
    const placesService = useMemo(
        () => placesLib && new placesLib.PlacesService(document.createElement('div')),
        [placesLib],
    )
    const showSnack = useShowSnack()

    const [internalVal, setInternalVal] = useState(props.value ? formatAddress(props.value, 'full') : null)
    const [suggestions, setSuggestions] = useState<google.maps.places.AutocompletePrediction[] | null>(null)
    const [loading, setLoading] = useState(false)

    const [debouncedInternalVal] = useDebounce(internalVal, 200)

    const getSuggestions = (input: string): Promise<google.maps.places.AutocompletePrediction[]> =>
        new Promise((resolve, reject) => {
            autoCompleteService!.getPlacePredictions(
                {
                    input,
                    types: ['address'],
                    componentRestrictions: {
                        country: 'au',
                    },
                },
                (predictions, status) => {
                    if (status === 'OK') {
                        resolve(predictions ?? [])
                    } else {
                        resolve([])
                    }
                },
            )
        })

    useEffect(() => {
        if (debouncedInternalVal) {
            //setValue(internalVal)
            if (autoCompleteService) {
                getSuggestions(debouncedInternalVal).then(setSuggestions)
            }
        } else {
            setSuggestions([])
        }
    }, [debouncedInternalVal])

    useEffect(() => {
        setInternalVal(props.value ? formatAddress(props.value, 'full') : null)
    }, [props.value])

    const getPlaceDetails = async (placeId: string): Promise<google.maps.places.PlaceResult | null> => {
        return new Promise((resolve, reject) => {
            if (!placesService) return reject(new Error('Google Maps library not loaded'))

            placesService.getDetails(
                { placeId, fields: ['address_components', 'formatted_address', 'geometry'] },
                (place, status) => {
                    if (status === google.maps.places.PlacesServiceStatus.OK && place) {
                        resolve(place)
                    } else {
                        reject(status)
                    }
                },
            )
        })
    }

    return (
        <SlotAutocomplete
            {...props}
            value={internalVal ? { description: internalVal } : null}
            options={
                suggestions?.map(({ description, place_id }) => ({
                    description,
                    placeId: place_id,
                })) ?? []
            }
            onInputChange={(e: any) => {
                if (e) {
                    setInternalVal(e.target.value)
                }
            }}
            onBlur={() => setInternalVal(props.value ? formatAddress(props.value, 'full') : null)}
            getOptionLabel={(option: any) => option.description}
            isOptionEqualToValue={(option: any, value: any) =>
                option.description.replace(', Australia', '') === value.description
            }
            onChange={async (e, v) => {
                console.log('v', v)
                const placeId = v?.placeId ?? null

                if (placeId && placesService) {
                    var details = await getPlaceDetails(placeId)
                    //const geo = await geocoder?.geocode({ placeId }).then(({ results }) => results[0])
                    var getGeoProp = (prop: string) =>
                        details?.address_components?.find(c => c.types.includes(prop))?.short_name ??
                        undefined

                    if (getGeoProp('street_number') == undefined) {
                        const suggestWithUnitWord = (await getSuggestions('unit ' + debouncedInternalVal))[0]
                        if (suggestWithUnitWord) {
                            const details = await getPlaceDetails(suggestWithUnitWord.place_id)
                            console.log('new details', details)
                            getGeoProp = (prop: string) =>
                                details?.address_components?.find(c => c.types.includes(prop))?.short_name ??
                                undefined
                        } else {
                            showSnack('Please enter a valid address', 'danger')
                            return
                        }
                    }

                    console.log('details', details)

                    if (details) {
                        const newAddress: AddressSchema = {
                            unitNumber: getGeoProp('subpremise')!,
                            streetNumber: getGeoProp('street_number')!,
                            streetName: getGeoProp('route')!,
                            suburb: getGeoProp('locality')!,
                            state: getGeoProp('administrative_area_level_1') as any,
                            postcode: getGeoProp('postal_code')!,
                        }
                        console.log('newAddress', newAddress)
                        props.onChange(newAddress, [
                            details.geometry!.location!.lat(),
                            details.geometry!.location!.lng(),
                        ])
                    }
                }
            }}
            loading={loading}
        />
    )
}

export default LocationAutocomplete
