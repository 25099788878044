/* eslint-disable jsx-a11y/anchor-is-valid */
import { formatAddress } from '@fastre/core/src/schemas/generic'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import BlockIcon from '@mui/icons-material/Block'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded'
import { Stack } from '@mui/joy'
import Box from '@mui/joy/Box'
import Chip from '@mui/joy/Chip'
import Divider from '@mui/joy/Divider'
import Dropdown from '@mui/joy/Dropdown'
import IconButton from '@mui/joy/IconButton'
import List from '@mui/joy/List'
import ListDivider from '@mui/joy/ListDivider'
import ListItem from '@mui/joy/ListItem'
import ListItemContent from '@mui/joy/ListItemContent'
import Menu from '@mui/joy/Menu'
import MenuButton from '@mui/joy/MenuButton'
import MenuItem from '@mui/joy/MenuItem'
import Typography from '@mui/joy/Typography'
import { useFindUserFromId, useListingConfigApi, useListingType, useListingsApi } from 'apiProviders'
import { Fragment, forwardRef } from 'react'
import { useNavigate } from 'react-router'
import { TableVirtuoso } from 'react-virtuoso'
import { listingStatusToColor } from '../helpers'

function RowMenu() {
    return (
        <Dropdown>
            <MenuButton
                slots={{ root: IconButton }}
                slotProps={{
                    root: { variant: 'plain', color: 'neutral', size: 'sm' },
                }}
            >
                <MoreHorizRoundedIcon />
            </MenuButton>
            <Menu
                size="sm"
                sx={{ minWidth: 140 }}
            >
                <MenuItem>Edit</MenuItem>
                <MenuItem>Rename</MenuItem>
                <MenuItem>Move</MenuItem>
                <Divider />
                <MenuItem color="danger">Delete</MenuItem>
            </Menu>
        </Dropdown>
    )
}

export default () => {
    const type = useListingType()
    const listingsApi = useListingsApi()
    const navigate = useNavigate()
    const findUserFromId = useFindUserFromId()
    const listingConfigApi = useListingConfigApi()

    return (
        <Box sx={{ display: { xs: 'initial', sm: 'none' }, height: '100%' }}>
            <TableVirtuoso
                style={{ height: '100%', width: '100%' }}
                data={listingsApi.maybeData.orSome([])}
                /*components={{
                    Scroller: React.forwardRef((props, ref) => <Sheet {...props} ref={ref} />),
                    Table: (props) => <Table {...props} />,
                    TableHead: React.forwardRef((props, ref) => <thead ref={ref} {...props} />),
                    TableRow: React.forwardRef((props, ref) => <tr ref={ref} {...props} />),
                    TableBody: React.forwardRef((props, ref) => <tbody ref={ref} {...props} />),
                }}*/
                components={{
                    Table: forwardRef((props, ref) => (
                        <Box
                            ref={ref}
                            {...props}
                            style={{
                                ...props.style,
                                width: '100%',
                            }}
                        />
                    )),
                    TableBody: forwardRef((props, ref) => (
                        <List
                            ref={ref as any}
                            {...props}
                            size="sm"
                            component="ul"
                            sx={{
                                '--ListItem-paddingX': 0,
                                width: '100%',
                            }}
                        />
                    )),
                }}
                endReached={() => {
                    console.log('end reached')
                    //listingsApi.nextPage()
                }}
                itemContent={(index, listing) => (
                    <Fragment key={listing.listingId}>
                        <ListItem
                            sx={{
                                display: 'flex',
                                cursor: 'pointer',
                                justifyContent: 'space-between',
                                alignItems: 'start',
                                px: 1,
                                pt: 1,
                                ':hover': {
                                    backgroundColor: 'var(--joy-palette-background-level2)',
                                    borderRadius: 'sm',
                                },
                            }}
                            onClick={() => navigate(listing.listingId)}
                        >
                            <ListItemContent
                                sx={{
                                    display: 'flex',
                                    gap: 2,
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div>
                                    <Typography
                                        fontWeight={600}
                                        gutterBottom
                                    >
                                        {formatAddress(listing.listingAddress!)}
                                    </Typography>
                                    <Typography level="body-xs">
                                        {findUserFromId(listing.agentUserId)
                                            .map(user => `${user.firstName} ${user.lastName}`)
                                            .orSome('loading...')}
                                    </Typography>
                                </div>
                                <Stack
                                    sx={{
                                        alignItems: 'flex-end',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Chip
                                        variant="soft"
                                        //size="sm"
                                        startDecorator={
                                            {
                                                Paid: <CheckRoundedIcon />,
                                                Refunded: <AutorenewRoundedIcon />,
                                                Cancelled: <BlockIcon />,
                                            }[listing.listingStatus]
                                        }
                                        color={listingStatusToColor(listing.listingStatus)}
                                    >
                                        {listing.listingStatus}
                                    </Chip>
                                    <Box>
                                        {listingConfigApi.maybeData
                                            .map(configs => (
                                                <Box sx={{ display: 'flex', gap: 1 }}>
                                                    {configs.map(config => {
                                                        const value = listing.additionalDetails
                                                            ? config.configValues.find(
                                                                  v =>
                                                                      v.value ===
                                                                      listing.additionalDetails[config.id],
                                                              ) ?? config.configValues[0]
                                                            : config.configValues[0]

                                                        return (
                                                            <Box
                                                                key={config.id}
                                                                sx={{
                                                                    width: '14px',
                                                                    height: '14px',
                                                                    borderRadius: '50%',
                                                                    backgroundColor: `${value.color}.softActiveBg`,
                                                                }}
                                                            />
                                                        )
                                                    })}
                                                </Box>
                                            ))
                                            .orSome(<>loading...</>)}
                                    </Box>
                                </Stack>
                            </ListItemContent>
                        </ListItem>
                        <ListDivider />
                    </Fragment>
                )}
            />
        </Box>
    )

    /*return (
        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
            {listingsApi.maybeData.map(listings =>
                listings.map(listItem =>
                    <List
                        key={listItem.listingId}
                        size="sm"
                        sx={{
                            '--ListItem-paddingX': 0,
                        }}
                    >
                        <ListItem
                            sx={{
                                display: 'flex',
                                cursor: 'pointer',
                                justifyContent: 'space-between',
                                alignItems: 'start',
                                px: 1,
                                pt: 1,
                                ':hover': {
                                    backgroundColor: 'var(--joy-palette-background-level2)',
                                    borderRadius: 'sm',
                                },
                            }}
                            onClick={() => navigate(listItem.listingId)}
                        >
                            <ListItemContent sx={{ display: 'flex', gap: 2, alignItems: 'start' }}>
                                <div>
                                    <Typography fontWeight={600} gutterBottom>
                                        {formatAddress(listItem.listingAddress!)}
                                    </Typography>
                                    <Typography level="body-xs" gutterBottom>
                                        {findUserFromId(listItem.agentUserId).map(user =>
                                            `${user.firstName} ${user.lastName}`
                                        ).orSome('loading...')}
                                    </Typography>
                                </div>
                            </ListItemContent>
                            <Chip
                                variant="soft"
                                size="sm"
                                startDecorator={
                                    {
                                        Paid: <CheckRoundedIcon />,
                                        Refunded: <AutorenewRoundedIcon />,
                                        Cancelled: <BlockIcon />,
                                    }[listItem.listingStatus]
                                }
                                color={
                                    {
                                        Paid: 'success',
                                        Refunded: 'neutral',
                                        Cancelled: 'danger',
                                    }[listItem.listingStatus] as ColorPaletteProp
                                }
                            >
                                {listItem.listingStatus}
                            </Chip>
                        </ListItem>
                        <ListDivider />
                    </List>
                )
            ).orSome([<Loading/>])}
            <Box
                className="Pagination-mobile"
                sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center', py: 2 }}
            >
                <IconButton
                    aria-label="previous page"
                    variant="outlined"
                    color="neutral"
                    size="sm"
                >
                    <KeyboardArrowLeftIcon />
                </IconButton>
                <Typography level="body-sm" mx="auto">
                    Page 1 of 10
                </Typography>
                <IconButton
                    aria-label="next page"
                    variant="outlined"
                    color="neutral"
                    size="sm"
                >
                    <KeyboardArrowRightIcon />
                </IconButton>
            </Box>
        </Box>
    );*/
}
