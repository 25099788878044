import { Box } from '@mui/joy'
import { Fragment } from 'react/jsx-runtime'

export default ({ index }) =>
    Array.from({ length: index }).map((_, i) => (
        <Fragment key={i}>
            <Box sx={{ width: 14 }} />
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    boxSizing: 'border-box',
                    width: 10 + 24 * (index - i - 1),
                    borderRight: '1px solid',
                    opacity: 0.3,
                }}
            />
        </Fragment>
    ))
