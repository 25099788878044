import { SharedTodoSchema } from '@fastre/core/src/schemas/todo'
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormLabel,
    Modal,
    ModalClose,
    ModalDialog,
    Stack,
} from '@mui/joy'
import { useApi } from 'api'
import { MultipleAgentInput } from 'components/agentInput'
import Input from 'components/input'
import { useEffect, useState } from 'react'

interface Props {
    sharedTodo?: SharedTodoSchema
    open: boolean
    onClose: () => void
}

export default ({ sharedTodo, open, onClose }: Props) => {
    const api = useApi()

    console.log('sharedTodo', sharedTodo)

    const [name, setName] = useState(sharedTodo?.name ?? '')
    const [userIds, setUserIds] = useState(sharedTodo?.userIds ?? [])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setName(sharedTodo?.name ?? '')
        setUserIds(sharedTodo?.userIds ?? [])
    }, [sharedTodo])

    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <ModalDialog>
                <ModalClose sx={{ zIndex: 3 }} />
                <DialogTitle>Create Shared Todo List</DialogTitle>
                <DialogContent>
                    <Stack spacing={1}>
                        <Input
                            label="List Name"
                            value={name}
                            onChange={setName}
                            fullWidth
                        />
                        <FormControl>
                            <FormLabel>Users</FormLabel>
                            <MultipleAgentInput
                                value={userIds}
                                onChange={setUserIds}
                            />
                        </FormControl>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        loading={loading}
                        onClick={() => {
                            setLoading(true)
                            if (sharedTodo) {
                                api.post(`/sharedtodo/${sharedTodo.id}/updateusers`, { name, userIds }).then(
                                    () => {
                                        window.location.reload()
                                    },
                                )
                            } else {
                                api.post('/sharedtodo/create', { name, userIds }).then(() => {
                                    window.location.reload()
                                })
                            }
                        }}
                    >
                        {sharedTodo ? 'Update' : 'Create'}
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    )
}
