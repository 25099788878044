import { getContractCommission } from '@fastre/core/src/schemas/contract'
import { TypesenseListingSchema } from '@fastre/core/src/schemas/typesense'
import { Box, Sheet, Table } from '@mui/joy'
import { MobileFriendlyStack } from 'components/stack'
import { sum } from 'ramda'

export default function SummarySection({ soldListings }: { soldListings: TypesenseListingSchema[] }) {
    const totalCommission = sum(soldListings.map(getContractCommission))

    return (
        <MobileFriendlyStack>
            <Box>
                <Sheet
                    variant="outlined"
                    sx={{
                        width: '100%',
                        borderRadius: 'sm',
                        mb: 4,
                    }}
                >
                    <Table>
                        <tbody>
                            {[
                                ['# of Sales', soldListings.length],
                                [
                                    'Total Sales Contract Value',
                                    `$${sum(soldListings.map(x => x.contract?.salePrice ?? 0)).toLocaleString()}`,
                                ],
                            ].map(([label, value]) => (
                                <tr key={label}>
                                    <td>{label}</td>
                                    <td align="right">{value}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Sheet>
            </Box>
            <Box>
                <Sheet
                    variant="outlined"
                    sx={{
                        width: '100%',
                        borderRadius: 'sm',
                        mb: 4,
                    }}
                >
                    <Table>
                        <tbody>
                            {[
                                [
                                    'Total Commission',
                                    '$' +
                                        totalCommission.toLocaleString('en-US', {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                        }),
                                ],
                                [
                                    'Average Commission',
                                    soldListings.length == 0
                                        ? ''
                                        : '$' +
                                          (totalCommission / soldListings.length).toLocaleString('en-US', {
                                              minimumFractionDigits: 0,
                                              maximumFractionDigits: 0,
                                          }),
                                ],
                            ].map(([label, value]) => (
                                <tr key={label}>
                                    <td>{label}</td>
                                    <td align="right">{value}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Sheet>
            </Box>
        </MobileFriendlyStack>
    )
}
