import { Box, Tab, TabList, Tabs } from '@mui/joy'
import { useSearchParamState } from 'helperFunctions/react'
import { Fragment } from 'react'
import MarketingItemsSettings from './marketingItems'
import MarketingSettings from './marketingSettings'

const tabList: [string, any][] = [
    ['Marketing Items', MarketingItemsSettings],
    ['Marketing Packages', MarketingSettings],
]

export default () => {
    const [activeTab, setActiveTab] = useSearchParamState('subTab', 'Marketing Items')

    return (
        <Tabs
            value={activeTab}
            onChange={(e, val) => setActiveTab(val as any)}
            sx={{ mx: 0 }}
        >
            <TabList>
                {tabList.map(([label]) => (
                    <Fragment key={label}>
                        <Tab value={label}>{label}</Tab>
                    </Fragment>
                ))}
            </TabList>
            <Box sx={{ py: 2 }}>
                {tabList.map(([name, Component]) => (
                    <Box
                        key={name}
                        sx={{
                            display: activeTab == name ? undefined : 'none',
                        }}
                    >
                        <Component />
                    </Box>
                ))}
            </Box>
        </Tabs>
    )
}
