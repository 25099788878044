import { AuditLog } from '@fastre/core/src/schemas/auditLog'
import { Box, Divider, Table, Typography } from '@mui/joy'
import { useApi } from 'api'
import { useListingType } from 'apiProviders'
import Loading from 'components/Loading'
import { format } from 'date-fns'
import { useMaybeState } from 'helperFunctions/react'
import { prop } from 'ramda'
import { useEffect } from 'react'
import { useParams } from 'react-router'

const AuditLogDesktop = ({ auditLog }: { auditLog: AuditLog[] }) => (
    <Table>
        <tbody>
            {auditLog.map((event, i) => (
                <tr key={i}>
                    <td style={{ width: '120px' }}>
                        {event.userName}
                        <br />
                        {format(event.timestamp, 'dd/MM/yy HH:mm')}
                    </td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{event.details}</td>
                </tr>
            ))}
        </tbody>
    </Table>
)

const AuditLogMobile = ({ auditLog }: { auditLog: AuditLog[] }) =>
    auditLog.map((event, i) => (
        <Box key={event.timestamp}>
            {i > 0 && <Divider sx={{ my: 1 }} />}
            <Typography>
                <Box
                    component="span"
                    fontWeight="bold"
                >
                    {event.userName}
                </Box>{' '}
                ({format(event.timestamp, 'dd/MM/yy HH:mm')})
            </Typography>
            <Typography sx={{ whiteSpace: 'pre-wrap' }}>{event.details}</Typography>
        </Box>
    ))

export default () => {
    const { listingId } = useParams<{ listingId: string }>()
    const type = useListingType()
    const api = useApi()
    const [maybeLog, setLog] = useMaybeState<any[]>()

    useEffect(() => {
        api.get(`/listing/${type}/${listingId}/auditlog`).then(prop('data')).then(setLog)
    }, [listingId])

    return maybeLog
        .map(auditLog => (
            <>
                <Box
                    sx={{
                        display: { xs: 'block', md: 'none' },
                    }}
                >
                    <AuditLogMobile auditLog={auditLog} />
                </Box>
                <Box
                    sx={{
                        display: { xs: 'none', md: 'block' },
                    }}
                >
                    <AuditLogDesktop auditLog={auditLog} />
                </Box>
            </>
        ))
        .orSome(<Loading />)
}
