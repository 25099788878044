import z from 'zod'

export const Permissions = {
    'roles.edit': 'Edit Roles',
    'users.view': 'View Users',
    'users.edit': 'Edit Users',
    'conjunctionalAgencies.view': 'View Conjunctional Agencies',
    'users.delete': 'Delete Users',
    'listings.view': 'View Listings',
    'listings.edit': 'Edit My Listings',
    'listings.delete': 'Delete Listings',
    'listings.fullControl': 'Full Control of Listings',
    'fit.view': 'View Funds in Trust',
    'fit.edit': 'Edit Funds in Trust',
    'ledger.view': 'View Ledger',
    'ledger.edit': 'Edit Ledger',
    'ledger.approve': 'Approve Ledgers',
    'agentStatement.view': 'View Agent Statements',
    'agentStatement.edit': 'Edit Agent Statements',
    'agentStatement.generatePayroll': 'Generate Payroll',
    'settings.view': 'View Settings',
    'settings.edit': 'Edit Settings',
    'todos.view': 'View Todos',
    'todos.manage': 'Manage Todos',
    'salesBoard.view': 'View Sales Board',
    'chat.manage': 'Manage Chat Groups',
    'monthlyCharges.view': 'View Monthly Ledger Charges',
    'monthlyCharges.edit': 'Edit Monthly Ledger Charges',
    'links.view': 'View Links',
    'links.edit': 'Edit Links',
    'calendar.view': 'View Calendar',
    'calendar.edit': 'Edit Calendar',
    'fundDisbursement.send': 'Send Fund Disbursement',
} as const

export type Permission = keyof typeof Permissions

export const PermissionsSchema = z.enum(Object.keys(Permissions) as [Permission, ...Permission[]]).array()

export const RoleSchema = z.object({
    roleName: z.string(),
    rolePermissions: PermissionsSchema,
})

export type RoleSchema = z.infer<typeof RoleSchema>
export type InternalRoleSchema = RoleSchema & {
    id: string
    orgId: string
}
