import { ColorPaletteProp } from '@mui/joy'
import Snackbar from '@mui/joy/Snackbar'
import { createContext, useContext, useState } from 'react'
import { dontCloseOnBackgroundClick } from './modal'

const SnackContext = createContext<{
    showSnack: (text: string, color: ColorPaletteProp) => void
}>(undefined as any)

export const useShowSnack = () => useContext(SnackContext).showSnack

export const SnackbarProvider = ({ children }) => {
    const [open, setOpen] = useState(false)
    const [text, setText] = useState('')
    const [color, setColor] = useState<ColorPaletteProp>('primary')

    const showSnack = (text: string, color: ColorPaletteProp) => {
        setText(text)
        setColor(color)
        setOpen(true)
    }

    return (
        <SnackContext.Provider value={{ showSnack }}>
            {children}
            <Snackbar
                autoHideDuration={3000}
                open={open}
                variant="outlined"
                color={color}
                onClose={dontCloseOnBackgroundClick(() => setOpen(false))}
            >
                {text}
            </Snackbar>
        </SnackContext.Provider>
    )
}
