import z from 'zod'

export const MarketingItemCategories = ['Advertising', 'Brochures', 'Digital Marketing', 'Internet Portals', 'Photos, Videos, Floorplans', 'Signboards', 'Other', 'Print Media'] as const
export type MarketingItemCategory = (typeof MarketingItemCategories)[number]

export const CoreMarketingItemSchema = z.object({
    itemCategory: z.enum(MarketingItemCategories),
    itemName: z.string(),
    itemPrice: z.number(),
})

export const UpdateMarketingItemSchema = CoreMarketingItemSchema.and(
    z.object({
        parentId: z.string(),
        itemId: z.string(),
    }),
)

export const MarketingItemSchema = CoreMarketingItemSchema.and(
    z.object({
        parentId: z.string().optional(),
        itemId: z.string().optional(),
    }),
)

export const CoreMarketingPackageSchema = z.object({
    packageName: z.string(),
    packageItems: z.array(MarketingItemSchema),
})

export const UpdateMarketingPackageSchema = CoreMarketingPackageSchema.and(
    z.object({
        parentId: z.string(),
        packageId: z.string(),
    }),
)

export const MarketingPackageSchema = CoreMarketingPackageSchema.and(
    z.object({
        parentId: z.string().optional(),
        packageId: z.string().optional(),
    }),
)

export type UpdateMarketingItemSchema = z.infer<typeof UpdateMarketingItemSchema>
export type UpdateMarketingPackageSchema = z.infer<typeof UpdateMarketingPackageSchema>
export type MarketingItemSchema = z.infer<typeof MarketingItemSchema>
export type MarketingPackageSchema = z.infer<typeof MarketingPackageSchema>
