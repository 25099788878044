import { InternalAgentStatement } from '@fastre/core/src/schemas/agentStatement'
import { Button, DialogActions, DialogContent, DialogTitle, Modal, ModalClose, ModalDialog } from '@mui/joy'
import { useApi } from 'api'
import Input from 'components/input'
import { useShowSnack } from 'components/snackbar'
import { prop } from 'ramda'
import { useEffect, useState } from 'react'

interface Props {
    item: InternalAgentStatement | undefined
    onClose: (newVal?: InternalAgentStatement) => void
}

export default function EditAgentStatementModal({ item, onClose }: Props) {
    const api = useApi()
    const showSnack = useShowSnack()

    const [Loading, setLoading] = useState(false)
    const [amount, setAmount] = useState(item?.amount ?? 0)

    useEffect(() => {
        if (item) {
            setAmount(item.amount)
        }
    }, [item])

    return (
        <Modal
            open={item !== undefined}
            onClose={() => onClose()}
        >
            <ModalDialog>
                <ModalClose />
                <DialogTitle>Edit Statement</DialogTitle>
                <DialogContent>
                    <Input
                        type="dollar"
                        label="Amount"
                        value={amount}
                        onChange={setAmount as any}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        loading={Loading}
                        onClick={async () => {
                            setLoading(true)
                            try {
                                const newVal = await api
                                    .post('/updateagentstatement', {
                                        id: item!.id,
                                        userId: item!.userId,
                                        amount,
                                    })
                                    .then(prop('data'))
                                showSnack('Statement updated', 'success')
                                onClose(newVal)
                            } catch (e) {
                                showSnack('Failed to update statement', 'danger')
                            } finally {
                                setLoading(false)
                            }
                        }}
                    >
                        Save
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() => onClose()}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    )
}
