import { EmailTemplateSchema } from '@fastre/core/src/schemas/emailTemplate'
import { Box, Button, DialogActions, DialogContent, DialogTitle, Modal, ModalDialog, Stack } from '@mui/joy'
import { useApi } from 'api'
import ButtonSheet from 'components/buttonSheet'
import Input from 'components/input'
import { dontCloseOnBackgroundClick } from 'components/modal'
import { prop } from 'ramda'
import { useEffect, useRef, useState } from 'react'
import EmailEditor, { EditorRef } from 'react-email-editor'
import { v4 as uuid } from 'uuid'

const EditTemplateModal = ({ onClose }: { onClose }) => {
    const api = useApi()
    const emailEditorRef = useRef<EditorRef>(null)

    const [templateName, setTemplateName] = useState('')
    const [subject, setSubject] = useState('')
    const [body, setBody] = useState({})
    const [loading, setLoading] = useState(false)

    return (
        <ModalDialog sx={{ width: '100%' }}>
            <DialogTitle>New Email Template</DialogTitle>
            <DialogContent>
                <Input
                    label="Template Name"
                    placeholder="Template Name"
                    value={templateName}
                    onChange={setTemplateName}
                />
                <EmailEditor
                    ref={emailEditorRef}
                    //onReady={onReady}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    loading={loading}
                    onClick={async () => {
                        setLoading(true)
                        await api.post('/emailtemplates', {
                            id: uuid(),
                            templateName,
                        })
                        setLoading(false)
                        onClose()
                    }}
                >
                    Save
                </Button>
                <Button
                    variant="outlined"
                    onClick={onClose}
                >
                    Cancel
                </Button>
            </DialogActions>
        </ModalDialog>
    )
}

export default () => {
    const api = useApi()

    const [templates, setTemplates] = useState<EmailTemplateSchema[]>([])
    const [newTemplate, setNewTemplate] = useState(false)

    useEffect(() => {
        api.get('/emailtemplates').then(prop('data')).then(setTemplates)
    }, [])

    return (
        <>
            <Stack
                direction="row"
                spacing={2}
            >
                <Box>
                    {templates.map(template => (
                        <ButtonSheet key={template.id}>{template.templateName}</ButtonSheet>
                    ))}
                    <Button
                        variant="soft"
                        onClick={() => setNewTemplate(true)}
                    >
                        Add Template
                    </Button>
                </Box>
            </Stack>
            <Modal
                open={newTemplate}
                onClose={dontCloseOnBackgroundClick(() => setNewTemplate(false))}
            >
                <EditTemplateModal onClose={() => setNewTemplate(false)} />
            </Modal>
        </>
    )
}
