import { Box, Button, FormControl, FormLabel, Stack, Textarea, Typography } from '@mui/joy'
import { useApi } from 'api'
import { useOrgIntegrationsApi } from 'apiProviders'
import { useUserData } from 'auth'
import DropZoneInner from 'components/dropzone'
import Loading from 'components/Loading'
import { convertFileToBase64 } from 'helperFunctions/file'
import IframeModal from 'listings/docusignFormStepper.tsx/iframeModal'
import { prop } from 'ramda'
import { useRef, useState } from 'react'
import FundDisbersementEmailInput from './fundDisbersmentEmailInput'

export default () => {
    const api = useApi()
    const orgIntegrationsApi = useOrgIntegrationsApi()
    const { user } = useUserData()
    const subjectEditorRef = useRef<any>()
    const contentEditorRef = useRef<any>()

    const [file, setFile] = useState<File>()
    const [docusignUrl, setDocusignUrl] = useState<string>()
    const [loading, setLoading] = useState(false)
    const [uploadLoading, setUploadLoading] = useState(false)

    if (!user.docusign) {
        return <Typography>Please connect to Docusign first</Typography>
    }

    if (orgIntegrationsApi.loading) {
        return <Loading />
    }

    return (
        <>
            {orgIntegrationsApi.data?.fundDisbursement ? (
                <Stack gap={2}>
                    <Box>
                        <Button
                            loading={loading}
                            onClick={async () => {
                                setLoading(true)
                                const { url } = await api
                                    .post('/funddisbursementtemplate/edit', {
                                        returnUrl: `${window.location.origin}/docusign/close`,
                                    })
                                    .then(prop('data'))
                                setDocusignUrl(url)
                                setLoading(false)
                            }}
                        >
                            Edit
                        </Button>
                    </Box>
                    <Box>
                        <Button
                            loading={loading}
                            color="danger"
                            onClick={async () => {
                                setLoading(true)
                                await api.post('/funddisbursementtemplate/remove')
                                await orgIntegrationsApi.refresh()
                                setLoading(false)
                            }}
                        >
                            Remove
                        </Button>
                    </Box>
                </Stack>
            ) : (
                <>
                    <DropZoneInner
                        options={{
                            accept: {
                                'application/pdf': ['.pdf'],
                            },
                            maxFiles: 1,
                        }}
                        onUpload={async files => {
                            setFile(files[0])
                            /*if (files.length > 0) {
                                try {
                                    setUploadLoading(true)
                                    const { url } = await api
                                        .post('/funddisbursementtemplate/create', {
                                            file: (await convertFileToBase64(files[0])).replace(
                                                'data:application/pdf;base64,',
                                                '',
                                            ),
                                            returnUrl: `${window.location.origin}/docusign/close`,
                                        })
                                        .then(prop('data'))
                                    orgIntegrationsApi.refresh()
                                    setDocusignUrl(url)
                                } catch (e) {
                                    console.error(e)
                                } finally {
                                    setUploadLoading(false)
                                }
                            }*/
                        }}
                    />
                    {file && <Typography>{file.name}</Typography>}
                </>
            )}
            <FormControl sx={{ my: 2 }}>
                <FormLabel>Email Subject</FormLabel>
                <Textarea
                    slots={{
                        textarea: props => (
                            <FundDisbersementEmailInput
                                ref={subjectEditorRef}
                                initialContents={orgIntegrationsApi.data?.fundDisbursement?.subject}
                                {...props}
                            />
                        ),
                    }}
                    sx={{
                        '& p': {
                            margin: 0,
                        },
                        flexGrow: 1,
                    }}
                />
            </FormControl>

            <FormControl sx={{ my: 2 }}>
                <FormLabel>Email Content</FormLabel>
                <Textarea
                    slots={{
                        textarea: FundDisbersementEmailInput,
                    }}
                    slotProps={{
                        textarea: {
                            ref: contentEditorRef,
                            initialContents: orgIntegrationsApi.data?.fundDisbursement?.content,
                        },
                    }}
                    sx={{
                        '& p': {
                            margin: 0,
                        },
                        flexGrow: 1,
                    }}
                />
            </FormControl>

            <Button
                sx={{ mt: 2 }}
                loading={loading}
                disabled={user.fundDisbursementFormConfigured ? false : file == undefined}
                onClick={async () => {
                    setLoading(true)
                    if (file) {
                        try {
                            setUploadLoading(true)
                            const { url } = await api
                                .post('/funddisbursementtemplate/create', {
                                    file: (await convertFileToBase64(file)).replace(
                                        'data:application/pdf;base64,',
                                        '',
                                    ),
                                    subject: subjectEditorRef.current.getContents(),
                                    content: contentEditorRef.current.getContents(),
                                    returnUrl: `${window.location.origin}/docusign/close`,
                                })
                                .then(prop('data'))
                            orgIntegrationsApi.refresh()
                            setDocusignUrl(url)
                        } catch (e) {
                            console.error(e)
                        } finally {
                            setUploadLoading(false)
                        }
                    } else {
                        await api.post('/funddisbursementtemplate/update', {
                            subject: subjectEditorRef.current.getContents(),
                            content: contentEditorRef.current.getContents(),
                        })
                    }
                    setLoading(false)
                }}
            >
                Save
            </Button>

            <IframeModal
                title="Docusign Fund Disbursement Template"
                url={docusignUrl}
                onClose={() => {
                    setDocusignUrl(undefined)
                }}
            />
        </>
    )
}
